.mod_most_discussed {
    background-color: $c-background-grey;
    padding: rem(28) $d-box-padding $d-box-padding;
}

.mod_most_discussed .list li {
    padding: rem(7) rem(10) rem(10) rem(10);
    position: relative;
}

.mod_most_discussed .list li:first-child {
    border-top: none;
}

.mod_most_discussed .txt {
    margin-top: 0;
}

.mod_most_discussed .sub {
    color: $c-text-grey;
    font-size: rem(12);
    margin-top: rem(3);
}

.aside .mod_most_discussed .bd {
    padding: 0;
}

.aside .mod_most_discussed .list li {
    padding: rem(7) 0;
}
