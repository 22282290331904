.sitemap {
    a {
        color: $c-text-default;
        font-weight: bold;
    }

    ul li {
        padding: rem(4) 0 rem(4) rem(20);
        position: relative;
    }

    ul li.sub:before {
        @include icon-styles();
        content: icon-char("angle-down");
        color: $c-text-brand;
        font-size: rem(7);
        position: absolute;
        left: 0;
    }

    ul li.open:before {
        content: icon-char("angle-up");
    }

    ul ul {
        display: none;
    }

    ul ul ul a {
        font-weight: normal;
    }
}
