.mod_basal acronym {
    border: none;
}

.mod_basal .infoImg {
    margin: 0 0 rem(10) 0;
}

.mod_basal form {
    background: transparent;
    margin-bottom: rem(20);
}

.mod_basal .basaltable-wrapper {
    overflow-x: auto;
}

.mod_basal form legend {
    margin: 0 0 rem(10) 0;
}

.mod_basal .line {
    margin: 0;
    padding: rem(15) 0;
}

.mod_basal .line form {
    margin: 0;
}

.mod_basal .line .left {
    @media (min-width: $break-medium) {
        margin-right: rem(10);
        width: rem(230);
    }
}

.mod_basal .line .right {
    @media (min-width: $break-medium) {
        width: rem(230);
    }
}

.mod_basal .line .right input.hasDatepicker {
    width: rem(72);
}

.mod_basal .line .right label,
.mod_basal .line .right input[type="checkbox"],
.mod_basal .line .right input[type="radio"] {
    margin-top: 0;
    vertical-align: middle;
}

.mod_basal .error_message {
    margin: rem(15) 0;
}

.mod_basal .success_message {
    margin: rem(15) 0;
}

.mod_basal .actionbar {
    border-bottom: rem(1) solid $c-divider-default;
    padding: rem(15) 0;
    margin-bottom: $d-space-after-element;
}

.mod_basal .actionbar .submit {
    float: right;
}

.mod_basal .actionbar .backBtn {
    float: none;
}

.mod_basal #basalList .hl {
    border-bottom: rem(1) solid $c-divider-default;
    display: flex;
    font-size: rem(14);
    margin: 0;
    padding: 0 0 rem(5) 0;
}

.mod_basal #basalList .firstDay {
    padding: 0 rem(5) 0 0;
    text-align: left;
    flex-grow: 1;
}

.mod_basal #basalList .line {
    border-bottom: rem(1) solid $c-divider-default;
    display: flex;
}

.mod_basal #basalList input[type=submit] {
    border: none;
    margin: 0;
    padding: 0;
    width: rem(25);
}

.mod_basal #basalList .view {
    float: left;
    padding: 0 rem(5) 0 rem(5);
    text-align: center;
    width: rem(70);
}

.mod_basal .view [type=submit],
.mod_basal .edit [type=submit],
.mod_basal .delete [type=submit] {
    appearance: none;
    background: none;
    border: none;
    text-indent: 0;

    &:before {
        @include icon-styles();
        color: $c-text-brand;
        font-size: rem(17);
    }

    &:hover {
        color: $c-text-brand-darker;
    }
}

.mod_basal .view [type=submit]:before {
    content: icon-char("graph");
}

.mod_basal .edit [type=submit]:before {
    content: icon-char("pencil-square");
}

.mod_basal .delete [type=submit]:before {
    content: icon-char("times-square");
}

.mod_basal #basalList .edit {
    float: left;
    padding: 0 rem(5) 0 rem(5);
    text-align: center;
    width: rem(78);
}

.mod_basal #basalList .edit [type=submit] {
    &:before {
        @include icon-styles();
        content: icon-char("pencil-square");
        color: $c-text-brand;
    }

    &:hover {
        color: $c-text-brand-darker;
    }
}

.mod_basal #basalList .delete {
    float: left;
    padding: 0 0 0 rem(5);
    text-align: center;
    width: rem(62);
}

.mod_basal .legend {
    margin: rem(10) 0;
}

.mod_basal .legend h3 {
    margin: rem(20) 0 rem(10);
}

.mod_basal .tabBox {
    position: relative;
}

.mod_basal #printBtn {
    background: $c-background-brand;
    border: none;
    color: $c-text-white;
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
    height: rem(32);
    width: rem(32);

    &:before {
        @include icon-styles();
        content: icon-char("print");
        line-height: rem(30);
    }
}

.mod_basal .mod_tabs .tabs {
    margin-bottom: rem(15);
}

.mod_basal .mod_tabs .chart {
    margin-bottom: rem(15);
}

.mod_basal .mod_tabs .chart .img {
    margin-bottom: rem(10);
    position: relative;
}

.mod_basal .mod_tabs .chart .img img {
    display: block;
}

.mod_basal .mod_tabs .chart .img .magnifier {
    top: rem(16);
    position: absolute;
    right: rem(16);

    &:before {
        @include icon-styles();
        content: icon-char("enlarge");
        color: $c-text-default;
        font-size: rem(20);
    }
}

.mod_basal .mod_tabs .table .hl {
    border-bottom: rem(1) solid $c-divider-default;
    display: flex;
    margin: 0 0 rem(10) 0;
    padding: 0 0 rem(5) 0;
}

.mod_basal .mod_tabs .table .line {
    border-bottom: rem(1) solid $c-divider-default;
    display: flex;
    margin: 0 0 rem(10) 0;
    padding: 0 0 rem(10) 0;
}

.mod_basal .mod_tabs .table .line.hascomment {
    border-bottom: none;
    margin-bottom: 0;
}

.mod_basal .mod_tabs .table .day {
    padding: 0 rem(5) 0 0;
    text-align: center;
    min-width: rem(25);
}

.mod_basal .mod_tabs .table .date {
    padding: 0 rem(5) 0 rem(5);
    text-align: center;
    min-width: rem(85);
}

.mod_basal .mod_tabs .table .temp {
    padding: 0 rem(5) 0 rem(5);
    text-align: center;
    min-width: rem(100);
}

.mod_basal .mod_tabs .table .blutung {
    padding: 0 rem(5) 0 rem(5);
    text-align: center;
    min-width: rem(30);
}

.mod_basal .mod_tabs .table .zervix {
    padding: 0 rem(5) 0 rem(5);
    text-align: center;
    min-width: rem(30);
}

.mod_basal .mod_tabs .table .sex {
    padding: 0 rem(5) 0 rem(5);
    text-align: center;
    min-width: rem(30);
}

.mod_basal .mod_tabs .table .medikamente {
    padding: 0 rem(5) 0 rem(5);
    text-align: center;
    min-width: rem(35);
}

.mod_basal .mod_tabs .table .mittelschmerz {
    padding: 0 rem(5) 0 rem(5);
    text-align: center;
    min-width: rem(35);
}

.mod_basal .mod_tabs .table .lhtest {
    padding: 0 rem(5) 0 rem(5);
    text-align: center;
    min-width: rem(30);
}

.mod_basal .mod_tabs .table .schwanger {
    padding: 0 rem(5) 0 rem(5);
    text-align: center;
    min-width: rem(35);
}

.mod_basal .mod_tabs .table .action {
    flex-grow: 1;
    padding: 0 0 0 rem(10);
    text-align: right;
    min-width: rem(25);
}

.mod_basal .mod_tabs .table .action .edit {
    bottom: 0;
    display: block;
    height: rem(25);
    margin-left: rem(3);

    &:before {
        @include icon-styles();
        content: icon-char("pencil-square");
        color: $c-text-brand;
        font-size: rem(17);
    }
}

.mod_basal .mod_tabs .table .comment {
    width: 100%;
}

.mod_basal .comments {
    margin-bottom: rem(10);
}

.mod_basal h3 {
    margin: rem(15) 0 rem(5) 0;
}

.mod_basal .publishForm {
    margin-bottom: rem(20);
}

.mod_basal .publishForm .forumBtn {
    float: left;
}

.mod_basal .new-basal-form  {
    margin-top: rem(32);
}
