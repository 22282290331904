.socialbar {
    background: $c-brand-default;
    border-top: 1px solid #fff;
    bottom: 0;
    left: 0;
    display: flex;
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: fixed;
    width: 100%;
    // z-index footer app + 1
    z-index: 10001;

    @media (min-width: $break-medium) {
        background: transparent;
        border-top: none;
        bottom: auto;
        left: auto;
        margin: 0 0 rem(32) 0;
        padding: 0 3rem 0 .5rem;
        position: static;
        z-index: auto;
        justify-content: space-between;
    }

    &__button {
        border-right: 1px solid $c-divider-white;
        cursor: pointer;
        display: flex;
        height: rem(35);
        line-height: rem(35);
        justify-content: center;
        align-items: center;
        width: 100%;

        @media (min-width: $break-medium) {
            border-right: none;
            display: block;
            height: rem(40);
            line-height: rem(40);
            width: auto;
        }

        a,
        a:hover {
            color: $c-text-white;
        }

        &:before {
            @include icon-styles();
            color: $c-text-white;
            font-size: rem(24);

            @media (min-width: $break-medium) {
                font-size: rem(40);
            }
        }

        &:last-child {
            margin-right: 0;
            border-right: none;
        }
    }

    // only mobile
    &__button--whatsapp {
        @media (min-width: $break-medium) {
            display: none;
        }
    }

    // only desktop
    &__button--flipboard {
        display: none;

        @media (min-width: $break-medium) {
            display: block;
        }
    }

    .icon-email:before {
        content: icon-char("envelope");

        @media (min-width: $break-medium) {
            color: #999999;
            font-size: rem(32);
        }
    }

    .icon-facebook:before {
        content: icon-char("facebook-circle");

        @media (min-width: $break-medium) {
            color: #3b5898;
        }
    }

    .icon-whatsapp:before {
        content: icon-char("whatsapp");

        @media (min-width: $break-medium) {
            color: #25D366;
        }
    }

    .icon-twitter:before {
        content: icon-char("twitter-circle");

        @media (min-width: $break-medium) {
            color: #54abee;
        }
    }

    .icon-pinterest:before {
        content: icon-char("pinterest-circle");

        @media (min-width: $break-medium) {
            color: #e60023;
        }
    }

    .icon-flipboard:before {
        content: icon-char("flipboard-square");

        @media (min-width: $break-medium) {
            color: #f52828;
        }
    }
}
