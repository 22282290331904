.mod_profile_family .description {
    margin: 0 0 rem(10) 0;
}

.mod_profile_family .gallery {
    margin: 0 0 rem(15);
    padding: 0 0 rem(15) 0;
}

.mod_profile_family .gallery .hl {
    margin: 0 0 rem(5);
}

.mod_profile_family .gallery .line {
    border-bottom: rem(1) solid $c-divider-default;
    border-top: rem(1) solid $c-divider-default;
    padding: rem(10) 0;
}

.mod_profile_family .gallery .line + .line {
    border-bottom: rem(1) solid $c-divider-default;
    border-top: none;
}

.mod_profile_family .gallery .image {
    margin: 0;
}

.mod_profile_family .gallery .colImage {
    text-align: left;
    padding: 0 rem(5) 0 0;
    vertical-align: top;
    width: rem(60);
}

.mod_profile_family .gallery .colLabel1 {
    text-align: left;
    padding: 0 rem(5) 0 rem(5);
    vertical-align: top;
    width: rem(75);
}

.mod_profile_family .gallery .colValue1 {
    text-align: left;
    padding: 0 rem(5) 0 rem(5);
    vertical-align: top;
    width: rem(120);
}

.mod_profile_family .gallery .colSep {
    display: none;
    text-align: center;
    padding: 0 0 0 0;
    vertical-align: top;
    width: rem(5);
}

.mod_profile_family .gallery .colLabel2 {
    text-align: left;
    padding: 0 rem(5) 0 rem(5);
    vertical-align: top;
    width: rem(95);
}

.mod_profile_family .gallery .colValue2 {
    text-align: left;
    padding: 0 rem(5) 0 rem(5);
    vertical-align: top;
    width: rem(60);
}

.mod_profile_family .gallery .colImage .img {
    border: rem(1) solid $c-divider-default;
    height: rem(53);
    text-align: center;
    width: rem(53);
}

.mod_profile_family .gallery .colImage .img img {
    max-height: 100%;
    max-width: 100%;
}

.mod_profile_family .gallery .colSep .sep {
    background: #9a9a9a;
    height: rem(55);
    width: rem(1);
}
