.mod_mailbox .actionbar {
    background: #fff;
    border-bottom: rem(1) solid $c-divider-default;
    margin: 0 0 rem(20) 0;
    padding: rem(8) 0 rem(14);
}

.mod_mailbox .actionbar .submit {
    float: left;
    margin-bottom: rem(16);
}

.mod_mailbox .actionbar .order {
    float: right;
}

.mod_mailbox .actionbar .order form {
    background: transparent;
}

.mod_mailbox .actionbar .order input {
    margin: 0 0 0 rem(10);
    vertical-align: middle;
}

.mod_mailbox .actionbar .order label {
    vertical-align: middle;
}

.mod_mailbox .message {
    border-bottom: rem(1) solid $c-divider-default;
    padding: rem(10) rem(10);
}

.mod_mailbox .message.first {
    border-top: rem(1) solid $c-divider-default;
}

.mod_mailbox .message.odd {
    background: #fff;
}

.mod_mailbox .uinfo {
    float: left;
    margin: 0 rem(15) 0 0;
    overflow: hidden;
    width: rem(60);
}

.mod_mailbox .uicon {
    height: rem(53);
    margin-bottom: rem(10);
    width: rem(53);
}

.mod_mailbox .uinfo .line {
    margin: 0 0 rem(5) 0;
    text-align: center;
}

.mod_mailbox .msg_content {
    margin: 0;
    overflow: hidden;
}

.mod_mailbox .msg_content .icons {
    float: right;
}

.mod_mailbox .msg_content .icons > * {
    display: block;
    float: left;
    margin-left: rem(10);
}

.mod_mailbox .msg_content .icons .unread,
.mod_mailbox .msg_content .icons .read {
    border: rem(1) solid $c-divider-dark;
    border-radius: 50%;
    bottom: rem(-1);
    display: block;
    height: rem(16);
    position: relative;
    width: rem(16);
}

.mod_mailbox .msg_content .icons .unread:before {
    background-color: $c-divider-dark;
    content: "";
    display: block;
    border-radius: 50%;
    height: rem(8);
    left: rem(3);
    top: rem(3);
    position: absolute;
    width: rem(8);
}

.mod_mailbox .msg_content .icons .reply {
    display: block;

    &:before {
        @include icon-styles();
        content: icon-char("envelope");
        font-size: rem(18);
    }
}

.mod_mailbox .msg_content .meta {
    margin: 0 0 rem(4) 0;
}

.mod_mailbox .msg_content .hl {
    margin: rem(5) 0 rem(10) 0;
}

.mod_mailbox .paging {
    margin: rem(10) 0;
}
