@function rem($size) {
    $remSize: $size / 16;
    @return #{$remSize}rem;
}

@mixin clearfix() {
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}

@mixin fullWidthBackground($color: $c-background-grey) {
    &:before {
        background-color: $color;
        bottom: 0;
        content: "";
        display: block;
        left: calc((-1 * var(--viewport-inner-width) / 2) + 50%);
        position: absolute;
        top: 0;
        width: var(--viewport-inner-width);
    }
}

@mixin ad($color: $c-text-grey) {
    &:before {
        content: "Anzeige";
        color: $color;
        float: right;
        font-size: rem(10.4);
        font-weight: normal;
        margin: 0 0 0 rem(16);
        text-shadow: 0 0 rem(1) $c-text-white;
    }
}

@mixin stretchToMobileViewport() {
    .page_inside & {
        @media (max-width: $break-medium-minus-one) {
            margin-left: $d-content-padding-negative;
            width: 100vw;
        }
    }
}

@mixin headline() {
    color: $c-text-brand;
    display: block;
    font-size: rem(20);
    font-weight: bold;
    line-height: rem(24);
    letter-spacing: rem(.6);
    margin-bottom: rem(38);
    text-transform: uppercase;

    @media (min-width: $break-medium) {
        font-size: rem(22);
    }

    &:after {
        content: "";
        display: block;
        border-top: rem(1) solid $c-background-brand;
        position: relative;
        width: rem(30);
        top: rem(12);
    }
}

@mixin blur() {
    filter: blur(10px);
}
