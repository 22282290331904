.page_outside {
    position: relative;
}

.page {
    margin: 0 auto;
    position: relative;
    max-width: $d-content-width;
}

.page_inside {
    background-color: $c-background-white;
    padding: $d-mobile-header-height $d-content-padding 0;
    position: relative;

    @media (min-width: $break-medium) {
        padding: rem(32) $d-content-padding 0;
    }
}

.main {
    padding: 0 0 rem(50) 0;

    @media (min-width: $break-medium) {
        padding: rem(27) 0 rem(50) 0;
    }

    .main-menu-flyout-open & {
        @media (min-width: $break-medium) {
            @include blur();
        }
    }
}

.section {
    clear: both;
}

.content_wide {
    float: none;
    padding: 0;
    width: auto;
}

.content .content_wide {
    margin-left: 0;
    margin-right: 0;
}

.section--col2 {
    @media (min-width: $break-medium) {
        display: flex;
    }

    .content {
        @media (min-width: $break-medium) {
            flex-grow: 1;
            padding-right: rem(30);
            max-width: calc(100% - 18.75rem);
        }
    }

    .aside {
        @media (min-width: $break-medium) {
            max-width: rem(300);
            min-width: rem(300);
        }
    }
}

.content-triple {
    margin-bottom: $d-space-after-element;

    @media (min-width: $break-medium) {
        display: flex;
    }

    &__left,
    &__center,
    &__right {
        margin-bottom: rem(20);
    }

    &__left {
        @media (min-width: $break-medium) {
            margin-bottom: 0;
            padding-right: rem(20);
            width: 33.333%;
        }
    }

    &__center {
        @media (min-width: $break-medium) {
            margin-bottom: 0;
            padding-left: rem(10);
            padding-right: rem(10);
            width: 33.334%;
        }
    }

    &__right {
        @media (min-width: $break-medium) {
            margin-bottom: 0;
            padding-left: rem(20);
            width: 33.333%;
        }
    }
}
