.mod_top_topics {
    @include stretchToMobileViewport();
    position: relative;

    &--format-16-9 {
        padding-bottom: 56.25%;
    }

    &--format-4-3 {
        padding-bottom: 75%;
    }

    .slider_img {
        background: $c-background-grey;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .slider_img .slide_cont .img {
        display: block;
        float: left;
    }

    .slider_img .slide_cont .img_ad {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .slider_img .teaserImageOverlay {
        display: none;
    }

    .slider_img img {
        display: block;
        height: auto;
        width: 100%;
    }

    .slide_cont {
        position: relative;
    }

    .control {
        align-items: center;
        display: flex;
        background-color: $c-background-brand;
        bottom: rem(10);
        padding: rem(10) 0;
        position: absolute;
        left: 0;
        right: $d-content-padding;

        @media (min-width: $break-medium) {
            bottom: rem(26);
            left: 17%;
            padding: rem(24) 0;
            right: 17%;
            text-align: center;
        }
    }

    .slider_txt a {
        color: $c-text-white;
    }

    .slider_txt .hl .ad {
        padding-right: rem(50);
        position: relative;

        &:before {
            color: $c-text-white;
            position: absolute;
            right: 0;
        }
    }

    .slider_txt {
        flex-grow: 1;
        padding: 0 rem(16);
    }

    .txt {
        @media (min-width: $break-medium) {
            font-size: rem(16);
            line-height: rem(20);
        }
    }

    .prev,
    .unit,
    .txt {
        color: $c-text-white;
    }

    .prev,
    .next {
        cursor: pointer;
        // DO not hide navigation buttons to prevent content reflow
        // display: none;
        width: rem(64);

        &:before {
            color: $c-text-white;
            font-size: rem(30);
            text-align: center;
        }
    }

    .prev:before {
        @include icon-styles();
        content: icon-char("angle-left");
    }

    .next:before {
        @include icon-styles();
        content: icon-char("angle-right");
    }

    .prev a,
    .next a {
        display: none;
    }

    .unit {
        display: none;
    }

    .active {
        display: block;
    }

    .hl a {
        display: block;
        font-weight: bold;
        font-size: rem(20);
        line-height: rem(30);
        margin-bottom: rem(8);

        @media (min-width: $break-medium) {
            font-size: rem(24);
        }
    }
}

.content .mod .more {
    cursor: pointer;
    padding: 0 rem(14) 0 0;
}

.mod .signature {
    font-style: italic;
    max-height: rem(280);
    overflow: hidden;
}
