.mod_question {
    overflow: hidden;
}

.mod_question .list {
    display: none;
}

.mod_question .active {
    display: block;
}

.mod_question .ft .more {
    cursor: pointer;
    display: none;
}

.mod_question .ft .prev {
    background-position: rem(-822) rem(-456);
    float: left;
    padding: 0 0 0 rem(14);
}

.mod_question .ft .next {
    background-position: right rem(-596);
    float: right;
}
