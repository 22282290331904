.qq-uploader {
    position: relative;
    width: 100%;
    padding: 0 !important;
    margin: 0;
}

.qq-upload-button {
    display: block; /* or inline-block */
    width: auto;
    text-align: center;
    border-bottom: rem(1) solid #ddd;
    color: #fff;
    font-weight: bold;
}

.qq-uploader button.submit {
    margin: 0 0 rem(3) 0;
}

.qq-upload-button-focus {
    outline: rem(1) dotted black;
}

.qq-upload-drop-area {
    border: rem(1) dashed $c-divider-dark;
    width: 100%;
    height: 100%;
    min-height: rem(70);
    z-index: 2;
    background-color: $c-background-grey;
    text-align: center;
}

.qq-upload-drop-area span {
    display: block;
    font-size: rem(16);
    line-height: rem(40);
}

.qq-upload-drop-area-active {
    background: #409BB9;
}

.qq-upload-list {
    margin: rem(15) rem(35);
    padding: 0;
    list-style: none;
}

.qq-upload-list li {
    margin: 0;
    padding: 0;
    line-height: rem(15);
    font-size: rem(12);
}

.qq-upload-file,
.qq-upload-size,
.qq-upload-cancel,
.qq-upload-failed-text {
    margin-right: rem(7);
}

.qq-upload-file {
    float: left;
    margin-right: rem(7);
    max-width: rem(207);
    overflow: hidden;
}

.qq-upload-spinner {
    display: inline-block;
    background: transparent url("../img/fileuploader/loading.gif") no-repeat scroll center center;
    width: rem(18);
    height: rem(15);
    vertical-align: text-bottom;
}

.qq-upload-size,
.qq-upload-cancel {
    font-size: rem(11);
}

.qq-upload-failed-text {
    display: none;
}

.qq-upload-fail .qq-upload-failed-text {
    display: inline;
}

.imageContainer {
    position: relative;
    margin: 0 auto;
    max-width: rem(500);
}