.mod_listbox {
    @include stretchToMobileViewport();
    background: $c-background-grey;
    padding: rem(28) $d-box-padding $d-box-padding;

    .hl {
        border-bottom: none;
        margin-top: rem(10);
    }

    li a {
        color: $c-text-default;
        display: block;
        font-weight: bold;
        line-height: rem(20);
    }

    .bd .ilist li {
        border-bottom: rem(1) solid $c-divider-default;
        font-size: rem(16);
        line-height: rem(20);
        padding: rem(10) 0;
    }
}
