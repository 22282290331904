.mod_poll {
    @include stretchToMobileViewport();
    background-color: $c-background-grey;
    padding: rem(3);

    .inner {
        padding: rem(14) $d-box-padding rem(10);
    }

    .kicker {
        font-weight: normal;
        margin-bottom: rem(3);
    }

    .hl,
    legend {
        margin-bottom: rem(10);
        white-space: normal;
    }

    .teaser,
    .text,
    p {
        margin-bottom: rem(10);
        margin-top: rem(10);
    }

    input[type=radio],
    input[type=checkbox] {
        float: left;
        margin-top: rem(2);
    }

    label {
        display: block;
        margin-left: rem(20);
    }

    label:hover {
        text-decoration: underline;
    }

    .barLine {
        margin-top: rem(11);
    }

    .bar {
        float: left;
        height: rem(14);
    }

    .barLegend {
        float: left;
        height: rem(14);
        margin-bottom: rem(5);
        margin-right: rem(10);
        width: rem(14);
    }

    .barColor1 {
        background-color: $c-background-brand-dark;
    }

    .barColor2 {
        background-color: $c-background-brand;
    }

    .barColor3 {
        background-color: lighten($c-background-brand, 15%);
    }

    .barColor4 {
        background-color: lighten($c-background-brand, 30%);
    }

    .line {
        margin-bottom: rem(5);
        overflow: hidden;
    }

    .result {
        border-bottom: rem(1) solid $c-divider-default;
        margin-bottom: rem(10);
        padding-bottom: rem(10);
    }

    .percentage {
        float: left;
        margin-left: rem(10);
    }

    .buttons {
        clear: both;
    }

    .backBtn,
    .voteBtn,
    .resultBtn {
        display: block;
        float: right;
        margin-left: rem(10);
        margin-top: rem(20);

        .aside & {
            font-size: rem(12);
            padding: rem(4) rem(11);
        }
    }

    .aside & {
        .percentage {
            margin-left: rem(8);
        }
    }
}
