.special_wrapper {
    margin: 0 rem(-20) 0;
    padding: 0 rem(10) rem(15);
}

.special_wrapper .left {
    @media (min-width: $break-medium) {
        float: left;
        width: rem(480);
    }
}

.special_wrapper .right {
    @media (min-width: $break-medium) {
        float: right;
        width: rem(300);
    }
}

.special_wrapper .right .mod {
    margin-bottom: 0;
}

.special_wrapper .mod_special {
    margin-bottom: 0;
}
