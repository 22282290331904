.ilist {
    a {
        color: $c-text-default;
    }

    li a.ad {
        padding-right: rem(50);
        position: relative;

        &:before {
            position: absolute;
            right: 0;
        }

        * + html & {
            background-position-y: rem(8);
            display: block;
        }
    }
}
