.mod_profile_galleries .hd,
.content .mod_profile_galleries .hd {
    position: relative;
}

.mod_profile_galleries .edit {
    bottom: rem(5);
    font-size: rem(12);
    text-transform: none;
    letter-spacing: 0;
    position: absolute;
    right: 0;

    &:after {
        @include icon-styles();
        content: icon-char("cog");
        color: $c-text-brand;
        padding-left: rem(5);
    }
}

.mod_profile_galleries .gallery {
    border-bottom: rem(1) solid $c-divider-default;
    margin: 0 0 rem(15);
    overflow-x: auto;
    padding: 0 0 rem(15) 0;
}

.mod_profile_galleries .gallery table {
    width: 100%;
}

.mod_profile_galleries .gallery .colImage {
    text-align: left;
    padding: 0 rem(5) 0 rem(5);
    vertical-align: top;
    width: rem(55);
}

.mod_profile_galleries .gallery .colCount {
    text-align: center;
    padding: 0 rem(5) 0 rem(5);
    vertical-align: top;
    width: rem(90);
}

.mod_profile_galleries .gallery .colMain {
    text-align: left;
    padding: 0 rem(5) 0 rem(5);
    vertical-align: top;
    width: rem(190);
}

.mod_profile_galleries .gallery .colButton {
    text-align: right;
    padding: 0 0 0 rem(15);
    vertical-align: top;
    width: rem(61);
}

.mod_profile_galleries .gallery .colImage .img {
    border: rem(1) solid $c-divider-default;
    height: rem(53);
    text-align: center;
    width: rem(53);
}

.mod_profile_galleries .gallery .colImage .img img {
    max-height: 100%;
    max-width: 100%;
}

.mod_profile_galleries .gallery .colMain .hl {
    margin-bottom: rem(5);
    width: 100%;
}

.mod_profile_galleries .gallery .imgCount {
    margin-top: rem(5);
}

.mod_profile_galleries .gallery .lastDate {
    width: 100%;
}

.mod_profile_galleries .gallery .lastDate .label {
    text-align: left;
    white-space: nowrap;
}

.mod_profile_galleries .gallery .lastDate .value {
    text-align: left;
}

.mod_profile_galleries .gallery .lastDate tr td:first-child {
    padding-right: rem(16);
    min-width: rem(80);
}

.mod_profile_galleries .gallery button {
    margin: rem(17) auto 0 auto;
}
