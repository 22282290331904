
.register .txt {
    margin-bottom: rem(20);
}

.register p.error {
    margin: rem(29) 0;
}

.register p.success {
    margin: rem(29) 0;
}

.register .line {
    margin-bottom: rem(10);
}

.register .line .left {
    @media (min-width: $break-medium) {
        float: left;
        padding-right: rem(16);
        text-align: right;
        width: 25%;
    }
}

.register .line .right {
    @media (min-width: $break-medium) {
        float: right;
        width: 75%;
    }
}

.register .line .right input[type=text],
.register .line .right input[type=password],
.register .line .right input[type=date],
.register .line .right input[type=email] {
    margin-bottom: rem(7);
}

.register .line .right p.error {
    margin: 0 0 rem(5) 0;
}

.register .line .right p.success {
    margin: 0 0 rem(5) 0;
}

.register .line .right input[type=checkbox] {
    clear: left;
    float: left;
}

.register .line .right label {
    margin-left: rem(3);
    margin-right: rem(6);
    vertical-align: middle;
}

.register .line .right label.checkboxRow {
    display: block;
    margin: 0 0 rem(20) rem(38);

    @media (min-width: $break-medium) {
        margin: 0 0 rem(9) rem(26);
    }
}

.register .external_account_info {
    background-color: $c-background-grey;
    margin-bottom: rem(20);
    padding: rem(3);
}

.register .external_account_info .inner {
    background: none repeat scroll 0 0 $c-background-grey;
    padding: rem(10) rem(15);
}

.register .external_account_info .account_name {
    border-bottom: rem(1) solid $c-divider-default;
    margin: rem(5) 0 rem(10) 0;
    padding-bottom: rem(15);
    text-align: center;
}

.register .bb {
    padding-bottom: rem(10);
}

.register .captchaImage {
    height: rem(52);
    margin: rem(10) 0;
}
