.aside .mod .hl {
    color: $c-text-default;
    display: block;
    font-weight: bold;
    font-size: rem(16);
    line-height: rem(20);
}

.aside .mod .img img {
    display: block;
}

.aside .mod .ft {
    padding-top: rem(26);
    text-align: right;
}

.aside .mod .ft .more {
    color: $c-text-brand;
    font-weight: normal;
    font-size: rem(14);
}

.aside .mod .ft .less {
    display: none;
}

.aside .mod .list li {
    padding: rem(5) 0;
}

.aside .mod .ilist li {
    padding: rem(10) 0;
}

.aside .mod .list.expert_list .uicon {
    margin-top: rem(13);
}

.aside .mod .list.expert_list .ft {
    clear: both;
    padding-bottom: rem(6);
}

.aside .mod_sticky:is(:last-child) {
    @media (min-width: $break-medium) {
        position: sticky;
        top: 0;
    }
}
