.slick {
    float: left;
    position: relative;
    display: block;
    box-sizing: border-box;
    margin-bottom: rem(10);
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    width: 100%;

    @media (min-width: $break-medium) {
        margin-bottom: 0;
        padding-right: rem(30);
        width: 63.8%;
    }
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.slick-track:before,
.slick-track:after {
    content: '';
    display: table;
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: rem(1);
    position: relative;
}

[dir='rtl'] .slick-slide {
    float: right;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick:not(.slick-initialized) {
    img {
        display: block;
        height: auto;
        max-width: 100%;
    }

    .inner:not(:first-of-type),
    .teaser-wrapper {
        display: none;
    }
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    border: rem(1) solid transparent;
    display: block;
    height: auto;
}

.slick-arrow {
    cursor: pointer;
}

.slick .prev,
.slick .next {
    background-color: $c-background-brand;
    border: none;
    color: $c-text-white;
    cursor: pointer;
    display: block;
    height: rem(40);
    line-height: rem(40);
    position: absolute;
    padding: 0;
    top: 50%;
    text-align: center;
    transform: translate(0, -50%);
    transition: opacity .3s ease-in-out;
    opacity: 1;
    outline: none;
    width: rem(40);
    z-index: 2;

    @media (min-width: $break-medium) {
        opacity: 0;
    }
}

.slick:hover .prev,
.slick:hover .next {
    opacity: 1;
}

.slick .next {
    right: 0;

    @media (min-width: $break-medium) {
        right: rem(30);
    }
}

.last-slide-active .slick .prev,
.last-slide-active .slick .next {
    top: rem(20);
}

.last-slide-active .teaser-wrapper .hd {
    margin-top: rem(50);

    @media (min-width: $break-medium) {
        margin-top: 0;
    }
}

.slick-slide {
    margin: 0;
}

.slick-slide img {
    display: block;
    height: auto;
    margin: 0 auto;
    width: 100%;
}

.slick .wrapper .img-original,
.slick-slide .img-original {
    // Static height for CLS. Use max-height to limit it
    height: rem(390);
    margin: 0 auto;
    max-height: 56vw;
    width: auto;

    @media (min-width: $break-medium) {
        max-height: rem(390);
    }
}

.slick-slide {
    position: relative;
    transition: all ease-in-out .3s;
    opacity: .2;
}

.slick-active {
    opacity: .5;
}

.slick-current {
    opacity: 1;
}

.slick .inner .hl {
    color: #d9416d;
    margin-bottom: 0.625rem;
    position: static;
    font-size: 1.25rem;
    line-height: 1.75rem;
    padding: .5rem .625rem 0;

    // Content is copied to the sidebar via JS
    @media (min-width: $break-medium) {
        display: none;
    }
}

.slick .inner .txt {
    padding-left: .625rem;
    padding-right: .625rem;

    // Content is copied to the sidebar via JS
    @media (min-width: $break-medium) {
        display: none;
    }
}

.slick .inner:after {
    background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, .3));
    bottom: 0;
    content: "";
    height: 20%;
    left: 0;
    pointer-events: none;
    position: absolute;
    width: 100%;
}

.slick .prev-wrap {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: rem(60);
    z-index: 2;
}

.slick .next-wrap {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: rem(60);
    z-index: 2;
}

.last-slide-active .next-wrap,
.last-slide-active .prev-wrap {
    @media (min-width: $break-medium) {
        // Overwrite inline style
        display: none !important;
    }
}

.slick .image-number {
    background-color: $c-background-brand;
    color: $c-text-white;
    display: block;
	height: 1.75em;
	line-height: 1.75em;
    margin: 0;
	padding: 0 0.5em;
    position: absolute;
	right: 0;
	text-align: center;
	top: 0;
    z-index: 1;

    @media (min-width: $break-medium) {
        display: none;
    }
}
