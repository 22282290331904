.mod_user_tools {
    margin-bottom: rem(5);
}

.mod_user_tools form {
    background: none;
    margin-bottom: $d-space-after-element;
}

.mod_user_tools h2 {
    position: relative;
}

.mod_user_tools .edit {
    background: none;
    border: none;
    bottom: rem(5);
    position: absolute;
    right: 0;
    color: $c-text-brand;
    font-size: rem(12);
    text-transform: none;

    &:after {
        @include icon-styles();
        content: icon-char("cog");
        padding-left: rem(6);
    }
}

.mod_user_tools .img {
    display: none;
    float: left;
    margin-right: rem(15);

    @media (min-width: $break-medium) {
        display: block;
    }
}

.mod_user_tools .bd {
    display: table-cell;
}

.mod_user_tools .bd p {
    margin: 0 0 rem(10) 0;
}

.mod_user_tools .fields {
    margin: 0 0 rem(5) rem(5);
}

.mod_user_tools .line {
    margin: rem(2) 0;
}

.mod_user_tools .line input {
    vertical-align: middle;
}

.mod_user_tools .line label {
    margin-left: rem(2);
    vertical-align: middle;
}

.mod_user_tools .actionbar {
    margin-bottom: rem(15);
    padding: 0 rem(5);
}

.mod_user_tools .actionbar .submit {
    float: right;
}

#user_tools_stoerer {
    margin: 0 0 rem(30) 0;
}

#user_tools_stoerer .user_tool_stoerer .mod {
    margin-bottom: rem(15);
}
