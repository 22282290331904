.mod_topteaser {
    @include stretchToMobileViewport();
    background: $c-background-grey;

    .ad:before {
        display: block;
    }

    .content-triple & {
        @media (min-width: $break-medium) {
            margin-bottom: 0;
            height: 100%;
        }
    }

    .txt {
        margin-bottom: rem(10);
    }

    .bd {
        padding: rem(20) $d-box-padding;
    }

    .bd .media {
        padding: 0;
    }

    .bd .media .img {
        margin-right: rem(20);
    }

    .bd .kicker {
        margin-bottom: rem(2);
    }

    .bd .hl {
        margin-bottom: rem(5);
    }

    .moreBtn {
        text-align: center;

        @media (min-width: $break-medium) {
            text-align: right;
        }
    }
}
