.mod_questions .tabs {
    margin-bottom: rem(25);
}

.mod_questions .tabs li a {
    padding: 0 rem(10);
}

.mod_questions .uicon {
    left: rem(12);
    height: rem(53);
    padding: rem(5);
    position: absolute;
    top: rem(43);
    width: rem(53);
}

.mod_questions .list {
    border-top: rem(1) solid $c-divider-default;
}

.mod_questions .list li {
    position: relative;
}

.mod_questions .list .odd,
.mod_questions .list .even {
    border-top: none;
}

.mod_questions .list .odd .item,
.mod_questions .list .even .item {
    border-bottom: none;
}

.mod_questions .list .badge {
    left: rem(14);
    position: absolute;
    top: 0;
}

.mod_questions .inner {
    padding: rem(18) rem(10) rem(21) rem(10);
    position: relative;
}

.mod_questions .kicker {
    margin-bottom: rem(6);
}

.mod_questions .date {
    float: right;
}

.mod_questions .hl {
    margin-bottom: rem(7);
}

.mod_questions .sub {
    margin-bottom: rem(7);
}

.mod_questions .paging {
    margin-top: rem(11);
}
