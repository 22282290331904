.mod_gallery {
    clear: both;
    margin-bottom: rem(18);
    position: relative;

    .u-slide.isAd .wrapper {
        align-items: center;
        display: flex;
        justify-content: center;
        min-height: rem(350);
    }

    .slick .inner:after {
        content: none;
    }

    .slick .prev,
    .slick .next {
        top: 1.25rem;

        @media (min-width: $break-medium) {
            top: 50%;
        }
    }

    .slick .image-number {
        bottom: 0;
        top: auto;

        @media (min-width: $break-medium) {
            bottom: auto;
            top: 0;
        }
    }
}

.mod_gallery .navibar {
    padding: rem(5) rem(10);

    @media (min-width: $break-medium) {
        float: left;
        padding: 0;
        width: 36.2%;
    }
}

.mod_gallery .navibar .ad:before {
    display: block;
}

.mod_gallery .backLink {
    color: $c-text-white;
    line-height: rem(26);
}

.mod_gallery .navibar .allLink,
.mod_gallery .naviLinks .allLink {
    color: $c-text-white;
    float: right;
}

.mod_gallery .naviLinks .allLink:before {
    @include icon-styles();
    content: icon-char("camera");
}

.mod_gallery .control {
    clear: both;
    display: none;
    height: rem(50);
    margin: 0 auto;
    text-align: center;

    @media (min-width: $break-medium) {
        display: block;
    }
}

.mod_gallery .control .counter {
    line-height: rem(45);
    margin: 0 rem(9);
    min-width: rem(51);
    display: inline-block;
    vertical-align: top;
}

.mod_gallery .control .prev,
.mod_gallery .control .next {
    background-color: $c-background-brand;
    color: $c-text-white;
    cursor: pointer;
    display: inline-block;
    font-weight: normal;
    font-size: rem(30);
    font-style: normal;
    line-height: rem(34);
    height: rem(40);
    text-align: center;
    text-decoration: none;
    text-indent: 0;
    vertical-align: top;
    width: rem(40);
}

.mod_gallery .prev:before {
    @include icon-styles();
    content: icon-char("angle-left");
    font-size: rem(23);
    line-height: rem(40);
    padding-right: rem(5);
}

.mod_gallery .next:before {
    @include icon-styles();
    content: icon-char("angle-right");
    font-size: rem(23);
    line-height: rem(40);
    padding-left: rem(5);
}

.mod_gallery .naviLinks {
    clear: both;

    @media (min-width: $break-medium) {
        padding-right: rem(30);
        width: 63.8%;
    }
}

.mod_gallery .naviLinks-inner {
    background-color: $c-background-brand;
    padding: rem(5) rem(10);
}

.mod_gallery .current-headline {
    color: $c-text-brand;
    display: none;
    margin-bottom: rem(10);
    position: static;

    @media (min-width: $break-medium) {
        display: block;
    }
}

.mod_gallery .current-caption {
    display: none;

    @media (min-width: $break-medium) {
        display: block;
    }
}

.mod_gallery .control .prev:active,
.mod_gallery .control .next:active {
    position: relative;
    top: rem(1);
}

.mod_gallery .wrapper {
	position: relative;

    &:after {
        background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.3)));
        background-image: -o-linear-gradient(top, transparent, rgba(0, 0, 0, 0.3));
        background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.3));
        bottom: 0;
        content: "";
        height: 20%;
        left: 0;
        pointer-events: none;
        position: absolute;
        width: 100%;
    }
}

.mod_gallery .wrapper .prev,
.mod_gallery .wrapper .next {
    bottom: 0;
    display: block;
    margin: auto;
    position: absolute;
    text-indent: rem(-9999);
    top: 0;
    z-index: 2;
}

.mod_gallery .wrapper .prev {
    left: rem(10);
    width: 30%;
}

.mod_gallery .wrapper .next {
    right: rem(10);
    width: 50%;
}

.mod_gallery .wrapper .prev:hover {
    background-position: center left;
}

.mod_gallery .wrapper .next:hover {
    background-position: center right;
}

.mod_gallery .inner.ads {
    margin-top: rem(-14);
    padding-top: rem(14);
}

.mod_gallery .inner.ad {
    position: relative;

    &:before {
        position: absolute;
    }
}

.mod_gallery .hl {
    margin: rem(5) 0 rem(5) 0;
}

.mod_gallery .pflichttext {
    bottom: rem(30);
    font-style: italic;
    margin: 0 rem(150) 0 0;
    position: absolute;
    padding: rem(16);
    z-index: 1;
}

.mod_gallery .rights {
    bottom: rem(5);
    color: $c-text-white;
    left: rem(16);
    margin: 0;
    position: absolute;
    right: rem(16);
    z-index: 1;
}

.mod_gallery .teaser-wrapper .line {
    margin: 0 0 rem(20) 0;
}

.mod_gallery .teaser-wrapper .media {
    margin: 0 auto $d-space-after-element;
    overflow: hidden;

    @media (min-width: $break-medium) {
        margin-bottom: rem(16);
        float: left;
        width: 47%;
    }

    &:nth-child(2n) {
        @media (min-width: $break-medium) {
            margin-left: 6%;
        }
    }

    &:nth-child(2n+1) {
        @media (min-width: $break-medium) {
            clear: left;
        }
    }
}

.mod_gallery .teaser-wrapper .media .img {
    display: block;
    float: none;
    margin-bottom: rem(7);
    width: auto;
}

.mod_gallery .teaser-wrapper .bd {
    display: block;
}

.mod_gallery .teaser-wrapper .hl {
    margin: rem(3) 0;
}

.mod_gallery .teaser-wrapper .hl a.ad {
    padding-right: rem(50);
    position: relative;

    &:before {
        position: absolute;
        right: 0;
    }
}

.mod_gallery .teaser-wrapper .txt {
    margin: 0;
}

.mod_gallery .ad-wrapper {
    clear: both;
}

.mod_galleries {
    .teaser-wrapper .line {
        margin-bottom: rem(20);

        @media (min-width: $break-medium) {
            display: flex;
        }
    }

    .teaser-wrapper .media {
        position: relative;
        margin-bottom: $d-space-after-element;

        @media (min-width: $break-medium) {
            margin: 0 rem(15);
            width: 33.33%;
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .teaser-wrapper .media .img {
        display: block;
        float: none;
        margin-bottom: rem(7);
        width: auto;
    }

    .teaser-wrapper .hl {
        margin: rem(3) 0;
    }

    .teaser-wrapper .hl a.ad {
        padding-right: rem(50);

        &:before {
            position: absolute;
            left: rem(10);
            top: rem(5);
        }
    }

    .teaser-wrapper .txt {
        margin: 0;
    }

    .teaser-wrapper .bd {
        display: block;
    }
}

.mod_remedies .ilist li {
    border-bottom: rem(1) solid $c-divider-default;
    padding: rem(6) 0 rem(6) rem(15);
}
