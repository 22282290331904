.mod_expert_open_questions {
    .uicon {
        left: rem(12);
        height: rem(53);
        padding: rem(5);
        position: absolute;
        top: rem(43);
        width: rem(53);
    }

    .kicker {
        margin-bottom: rem(2);
    }

    .date {
        float: right;
    }

    .hl {
        margin-bottom: rem(7);
    }

    .bd {
        margin-top: rem(7);
        word-break: break-word;
    }

    .paging {
        margin-top: rem(11);
    }

    .list li {
        padding: rem(10) 0;
    }

    .list .odd,
    .list .even {
        border-top: none;
    }

    .list .odd .item,
    .list .even .item {
        border-bottom: none;
    }

    .list .badge {
        left: rem(14);
        position: absolute;
        top: 0;
    }
}
