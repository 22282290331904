.highlightbox {
	background: #eeeeee;
	color: #000000;
	padding: 0 rem(10);
	margin-bottom: rem(20);
	display: table;

	h2, h3 {
		margin-top: rem(10);
	}
}
