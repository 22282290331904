.footer {
    clear: both;
    padding-top: rem(38);
    position: relative;

    @include fullWidthBackground($c-background-brand);

    .inner {
        position: relative;
        z-index: 1;
    }

    a {
        color: $c-text-white;
    }

    .footer__list {
        margin-bottom: rem(25);
    }

    &__list a {
        font-size: rem(14);
    }

    &__list li {
        margin-bottom: rem(8);
        position: relative;
        padding-left: rem(15);

        &:before {
            @include icon-styles();
            content: icon-char("angle-right");
            color: $c-text-white;
            font-size: rem(10);
            left: rem(-1);
            position: absolute;
            top: 0;
        }
    }

    &__social-icons {
        align-items: center;
        justify-content: center;
        display: flex;
        gap: .75rem;
        list-style: none;
        margin: 0 0 2rem;
        padding: 0;
        position: relative;

        @media (min-width: $break-medium) {
            gap: 1.25rem;
        }
    }

    .hd {
        margin-bottom: rem(50);
    }

    .hl {
        color: $c-text-white;
        font-size: rem(16);
        font-weight: bold;
        line-height: rem(20);
        margin-bottom: rem(12);
    }

    .col {
        border-left: none;
        padding: 0 rem(7);

        @media (min-width: $break-medium) {
            float: left;
            padding: 0 rem(20);
            width: 25%;
        }
    }

    .col_1 {
        @media (min-width: $break-medium) {
            padding-left: 0;
        }
    }

    .col_4 {
        @media (min-width: $break-medium) {
            padding-right: 0;
        }
    }

    .service .inner {
        padding: rem(16) 0;
    }

    .links {
        padding: rem(20) 0;
        position: relative;
        z-index: 1;

        @media (min-width: $break-medium) {
            padding: rem(20) rem(10);
        }

        @include fullWidthBackground($c-background-brand-dark);
    }

    .links-inner {
        text-transform: uppercase;
        margin: 0 auto;
        max-width: $d-content-width;
        position: relative;
        z-index: 1;

        @media (min-width: $break-medium) {
            text-align: center;
        }
    }

    .links a,
    &__copyright {
        display: block;
        font-size: rem(11);
        letter-spacing: rem(1.1);
        padding: rem(4) rem(7);
        text-transform: uppercase;
        white-space: nowrap;

        @media (min-width: $break-medium) {
            display: inline-block;
            padding: 0 rem(7);
        }
    }

    &__copyright {
        color: $c-text-white;
        font-weight: bold;
        padding-top: rem(20);

        @media (min-width: $break-medium) {
            padding-top: 0;
        }
    }
}
