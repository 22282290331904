.article h2,
.article h3,
.article h4,
.article h5,
.article h6 {
	position: relative;
}

.article .hd {
	text-transform: none;
}

.speakable {
	border-bottom: rem(1) solid $c-background-brand;
	margin-bottom: rem(38);

	.hd::after {
		width: 100%;
	}
}

.article .dictionary_link {
	border-bottom: 1px dotted;
	text-decoration: none;
}

.article .video {
	@include stretchToMobileViewport();
	background-color: $c-background-grey;
	clear: both;
	margin-bottom: $d-space-after-element;
}

.article .video .img_container {
	float: left;
	margin: rem(8) rem(10) rem(5) rem(10);
	position: relative;
}

.article .video .img {
	background: $c-background-grey;
	border: rem(1) solid $c-divider-default;
	display: block;
	height: rem(122);
	width: rem(215);
}

.article .video .img:hover {
	border-color: red;
}

.article .video .overlay {
	background: rgba(0, 0, 0, .5);
	border-radius: 50%;
	display: block;
	font-style: normal;
	height: 3.125rem;
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 3.125rem;
	z-index: 10;

	&:before {
		@include icon-styles();
		content: icon-char("play");
		color: #fff;
		font-size: 1.25rem;
		position: absolute;
		transform: translate(-35%, -50%);
		left: 50%;
		font-style: normal;
		top: 50%;
	}

	&:after {
		content: "Video abspielen";
		color: rgba(255, 255, 255, .75);
		position: absolute;
		text-align: center;
		top: 100%;
		left: -52%;
		right: 0;
		white-space: nowrap;
	}
}

.article .video .img_container:hover .overlay:after {
	display: none;
}

.article .video .inner {
	padding: rem(10) $d-box-padding;
}

.article .video .adMarker {
	float: right;
	font-size: rem(10);
	line-height: rem(17);
	margin-left: rem(15);
	text-transform: uppercase;
}

.article .video .hl {
	margin: 0 0 rem(5) 0;
}

.article .video .txt {
	margin-bottom: rem(5);
}

.article .video .rights {
	margin: 0;
	color: $c-text-grey;
	font-size: rem(11);
	font-style: italic;
}

.article .video iframe {
	margin-bottom: 0;
}

.article .kicker {
	display: block;
	font-weight: normal;
	margin-bottom: rem(5);
}

.article .hl {
	margin-bottom: rem(18);
}

.article .hl_ad {
	@include ad();
	position: relative;
	padding: 0 rem(50) 0 0;

	&:before {
		position: absolute;
		right: 0;
	}
}

.article .hl_sub {
	margin: rem(-15) 0 rem(20);
}

.article p,
.article ol,
.article ul,
.article dl,
.article table {
	font-size: rem(16);
	line-height: rem(24);
	margin-bottom: rem(16);
}

.article > .txt > p a {
	font-weight: bold;
}

.article a.inlineLink.ad {
	padding-right: rem(48);
	position: relative;

	&:before {
		position: absolute;
		right: 0;
	}
}

.article .txt {
	width: 100%;
}

.article .txt h1 {
	@include headline();
}

.article > .txt img {
	height: auto;
	width: 100%;
}

.article > .txt > ul {
	list-style: none;
	margin-left: 0;

	@media (min-width: $break-medium) {
		margin-left: rem(12);
	}

	> li {
		padding-bottom: rem(8);
		padding-left: rem(22);
		position: relative;

		&:before {
			background: $c-background-brand none;
			content: "";
			display: block;
			height: rem(9);
			left: 0;
			position: absolute;
			top: rem(4);
			width: rem(9);
		}

		&:last-child {
			padding-bottom: 0;
		}
	}

	ul {
		list-style: none;
		margin: rem(10) 0 rem(1) rem(10);
	}
}

.article > .txt > ol {
	li {
		padding-bottom: rem(8);

		&:last-child {
			padding-bottom: 0;
		}
	}
}

.article table th,
.article table td {
	border: rem(1) solid $c-divider-default;
	border-left: 0;
	border-right: 0;
	padding: rem(13) rem(13) rem(13) 0;
}

.article table th {
	border: none;
}

.article .toggleBlockLink {
	padding-left: rem(24);
	position: relative;

	&:before {
		@include icon-styles();
		content: icon-char("angle-down");
		font-size: rem(10);
		position: absolute;
		left: 0;
	}
}

.article .toggleBlockLink.open:before {
	content: icon-char("angle-up");
}

.article .original {
	display: none;
}

.article .paging_group {
	display: none;
}

.article-abstract {
	margin-bottom: rem(11);
}

.line .left {
	@media (min-width: $break-medium) {
		float: left;
		width: 50%;
	}
}

.line .right {
	@media (min-width: $break-medium) {
		float: right;
		width: 50%;
	}
}

.article .inline {
	clear: both;
	display: block;
	margin-bottom: rem(20);
	width: 100%;
}

.article .inline:not(.inline_original) {
	@include stretchToMobileViewport();
}

.article .inline_left {
	width: auto;

	@media (min-width: $break-medium) {
		clear: none;
		float: left;
		margin: rem(2) rem(16) rem(20) 0;
		max-width: 50%;
		width: rem(236);
	}
}

.article .inline_right {
	width: auto;

	@media (min-width: $break-medium) {
		clear: none;
		float: right;
		margin: rem(2) 0 rem(20) rem(16);
		max-width: 50%;
		width: rem(236);
	}
}

.article .ssk-number {
	color: $c-text-default;
	display: block;
	background-color: $c-background-grey;
	font-size: rem(22);
	padding: rem(15) 0;
	position: absolute;
	left: 0;
	top: rem(-15);
	text-align: center;
	width: rem(76);

	@media (min-width: $break-medium) {
		top: 0;
	}

	&:before {
		background-color: $c-text-brand;
		content: "SSW";
		color: $c-text-white;
		display: block;
		font-size: rem(14);
		font-weight: normal;
		line-height: 1;
		padding: rem(4) 0;
		position: absolute;
		text-align: center;
		top: rem(-22);
		width: 100%;
	}
}

.article .colored {
	color: $c-text-brand;
	font-size: rem(16);
	line-height: rem(20);

	@media (min-width: $break-medium) {
		font-size: rem(22);
		line-height: rem(28);
	}
}

.article .ssk-number + .colored {
	display: block;
	padding-left: rem(90);
	position: relative;
	margin: rem(60) 0 rem(10);
	min-height: rem(32);

	@media (min-width: $break-medium) {
		padding-left: rem(100);
		margin: rem(50) 0 rem(16);
		min-height: rem(50);
	}
}

.article .ssk-tags a {
	background-color: $c-background-grey;
	border-radius: rem(5);
	color: $c-text-default;
	display: inline-block;
	font-size: rem(16);
	font-weight: bold;
	padding: rem(9) rem(8);
	margin-right: rem(10);
	margin-bottom: rem(10);
}
