/* Die Linkleiste direkt unter dem TinyMCE (und über der Actionbar). */
.editortoolbar {
	.btnToggleSmileys {
		display: block;
		float: right;
		padding-left: 0.75rem;

		&:before {
			@include icon-styles();
			content: icon-char("angle-down");
			font-size: rem(8);
		}
		&.opened:before {
			content: icon-char("angle-up");
		}

		img {
			display: block;
			float: right;
			margin: -0.375rem 0.1875rem 0 0.3125rem;
			width: auto;
		}
	}
}

/* TinyMCE: Die Flyouts / Aufklapplisten in der Lightbox (".layer") sichtbar machen: */
.mce-floatpanel,
.mce-fullscreen,
.mce-window {
	z-index: $z-tinymce-layer !important;
}

/* Overwrite MCE-Editor inline-CSS-styles to make floating panels responsive and usable */
.mce-floatpanel {
	@media (max-width: $break-medium-minus-one) {
		left: 5vw !important;
		height: auto !important;
		width: 90vw !important;
	}

	.mce-floatpanel * {
		@media (max-width: $break-medium-minus-one) {
			box-sizing: border-box;
		}
	}

	.mce-container:not(.mce-floatpanel),
	.mce-container-body {
		@media (max-width: $break-medium-minus-one) {
			display: block !important;
			overflow: visible !important;
			position: static !important;
			height: auto !important;
			width: auto !important;
		}
	}

	.mce-window-body {
		@media (max-width: $break-medium-minus-one) {
			padding: 1rem;
			max-height: 50vh;
			overflow: auto !important;
		}
	}

	.mce-abs-layout-item {
		@media (max-width: $break-medium-minus-one) {
			display: block !important;
			position: static !important;
			margin-bottom: .25rem;
			width: 100% !important;
		}
	}

	.mce-last {
		@media (max-width: $break-medium-minus-one) {
			margin-bottom: .5rem;
		}
	}

	.mce-label {
		white-space: normal;
	}

	.mce-textbox {
		@media (max-width: $break-medium-minus-one) {
			box-sizing: border-box;
			width: 100% !important;
		}
	}

	.mce-foot .mce-container-body {
		@media (max-width: $break-medium-minus-one) {
			text-align: right;
		}
	}

	.mce-foot .mce-btn {
		@media (max-width: $break-medium-minus-one) {
			display: inline-block !important;
			margin: .5rem;
			width: auto !important;
		}
	}
}
