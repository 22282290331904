.menu-teaser {
    background-color: $c-background-white;
    margin-bottom: rem(20);
    position: relative;

    @media (min-width: $break-content) {
        display: none;
    }

    img {
        display: block;
        height: auto;
        width: 100%;
    }

    .headline {
        color: $c-text-default;
        display: block;
        font-size: rem(18);
        font-weight: bold;
        padding: rem(20) $d-box-padding;
    }

    .headline.ad:before {
        position: absolute;
        left: $d-box-padding;
        top: rem(10);
    }

    &--desktop {
        display: none;
        position: absolute;
        margin-top: rem(25);
        left: 33%;
        width: 30%;
        z-index: $z-header-menu-desktop + 1;

        .open-desktop & {
            @media (min-width: $break-content) {
                display: block;
            }
        }

        &:last-of-type {
            @media (min-width: $break-content) {
                left: 66%;
            }
        }
    }

    &--desktop .headline {
        font-size: rem(16);
    }
}
