@mixin icon-font-face {
@font-face {
	font-family: "iconfont";
    font-display: swap;
    font-weight: normal;
    font-style: normal;
	src: url('../fonts/iconfont/iconfont.woff2') format('woff2'),
		 url('../fonts/iconfont/iconfont.woff') format('woff');
}
}

@mixin icon-styles {
	font-family: "iconfont";
    //-webkit-font-smoothing: antialiased;
    //-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	//font-variant: normal;
	font-weight: normal;
	//text-decoration: none;
	//text-transform: none;
}

%icon {
	@include icon-styles;
}

@function icon-char($filename) {
	$char: "";

	@if $filename == angle-down {
		$char: "\E001";
	}
	@if $filename == angle-left {
		$char: "\E002";
	}
	@if $filename == angle-right {
		$char: "\E003";
	}
	@if $filename == angle-square-down {
		$char: "\E004";
	}
	@if $filename == angle-square-up {
		$char: "\E005";
	}
	@if $filename == angle-up {
		$char: "\E006";
	}
	@if $filename == arrow-left {
		$char: "\E007";
	}
	@if $filename == arrow-right {
		$char: "\E008";
	}
	@if $filename == bars {
		$char: "\E009";
	}
	@if $filename == camera {
		$char: "\E00A";
	}
	@if $filename == check {
		$char: "\E00B";
	}
	@if $filename == clock {
		$char: "\E00C";
	}
	@if $filename == cog {
		$char: "\E00D";
	}
	@if $filename == diagram {
		$char: "\E00E";
	}
	@if $filename == enlarge {
		$char: "\E00F";
	}
	@if $filename == envelope {
		$char: "\E010";
	}
	@if $filename == experts {
		$char: "\E011";
	}
	@if $filename == facebook-circle {
		$char: "\E012";
	}
	@if $filename == facebook {
		$char: "\E013";
	}
	@if $filename == first {
		$char: "\E014";
	}
	@if $filename == flask {
		$char: "\E015";
	}
	@if $filename == flipboard-square {
		$char: "\E016";
	}
	@if $filename == graph {
		$char: "\E017";
	}
	@if $filename == heart {
		$char: "\E018";
	}
	@if $filename == home {
		$char: "\E019";
	}
	@if $filename == info-circle {
		$char: "\E01A";
	}
	@if $filename == instagram {
		$char: "\E01B";
	}
	@if $filename == last {
		$char: "\E01C";
	}
	@if $filename == lock {
		$char: "\E01D";
	}
	@if $filename == logout {
		$char: "\E01E";
	}
	@if $filename == mars {
		$char: "\E01F";
	}
	@if $filename == minus {
		$char: "\E020";
	}
	@if $filename == pencil-square {
		$char: "\E021";
	}
	@if $filename == pinterest-circle {
		$char: "\E022";
	}
	@if $filename == pinterest {
		$char: "\E023";
	}
	@if $filename == play {
		$char: "\E024";
	}
	@if $filename == plus {
		$char: "\E025";
	}
	@if $filename == print {
		$char: "\E026";
	}
	@if $filename == search {
		$char: "\E027";
	}
	@if $filename == selftest {
		$char: "\E028";
	}
	@if $filename == star-half-alt {
		$char: "\E029";
	}
	@if $filename == star-o {
		$char: "\E02A";
	}
	@if $filename == star {
		$char: "\E02B";
	}
	@if $filename == syringe {
		$char: "\E02C";
	}
	@if $filename == times-square {
		$char: "\E02D";
	}
	@if $filename == times {
		$char: "\E02E";
	}
	@if $filename == tint {
		$char: "\E02F";
	}
	@if $filename == twitter-circle {
		$char: "\E030";
	}
	@if $filename == twitter {
		$char: "\E031";
	}
	@if $filename == user {
		$char: "\E032";
	}
	@if $filename == venus {
		$char: "\E033";
	}
	@if $filename == whatsapp {
		$char: "\E034";
	}

	@return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
	&:#{$insert} {
		@if $extend {
			@extend %icon;
		} @else {
			@include icon-styles;
		}
		content: icon-char($filename);
	}
}
