.mod_weeks {
    li {
        float: left;
        padding: 0 rem(10) rem(10) 0;

        .aside & {
            width: 33.33%;
        }
    }

    a {
        background-color: $c-background-grey;
        border-radius: rem(5);
        color: $c-text-default;
        display: block;
        font-size: rem(16);
        font-weight: bold;
        padding: rem(9) rem(8);
        text-align: center;
    }
}
