.breadcrumb {
    float: none;
    margin-bottom: rem(25);
    padding-top: rem(16);

    @media (min-width: $break-medium) {
        padding-top: 0;
    }

    a,
    span {
        color: $c-text-grey;
        font-size: rem(10);

        @media (min-width: $break-medium) {
            font-size: rem(11);
        }
    }

    span:not(:last-child):after {
        @include icon-styles();
        content: icon-char("angle-right");
        color: $c-text-grey;
        font-size: rem(9);
        padding: 0 rem(3) 0 rem(9);
        text-align: center;
    }

    .breadcrumb__label {
        padding-right: rem(3);
    }

    span.breadcrumb__label:after {
        content: none;
    }

    p {
        margin: 0;
        float: left;
        width: auto;
    }

    h1 {
        float: left;
        font-weight: normal;
        margin-left: rem(2);
    }
}
