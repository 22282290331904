.contact .txt {
    margin-bottom: rem(29);
}

.contact p.error {
    margin: rem(29) 0;
}

.contact p.error label {
    display: inline-block;
    width: rem(133);
    text-align: right;
    margin-right: rem(10);
}

.contact p.success {
    margin: rem(29) 0;
}

.contact legend {
    border-bottom: rem(1) solid $c-divider-default;
    margin-bottom: rem(12);
    padding-bottom: rem(2);
    width: 100%;
}

.contact .line {
    margin-bottom: rem(10);
}

.contact .line .left {
    @media (min-width: $break-medium) {
        float: left;
        padding-right: rem(16);
        text-align: right;
        width: 30%;
    }
}

.contact .line .right {
    @media (min-width: $break-medium) {
        float: right;
        padding-top: rem(3);
        width: 70%;
    }
}

.contact .line .right input[type=text],
.contact .line .right input[type=password],
.contact .line .right input[type=date],
.contact .line .right input[type=email] {
    margin-bottom: rem(7);
}

.contact .line .right .info {
    margin-bottom: rem(6);
}

.contact .line .right input[type=checkbox] {
    float: left;
}

.contact .line .right select,
.contact .line .right textarea {
    margin-bottom: rem(7);
}

.contact .line .right.zip.location input[type=text]#zip {
    width: rem(60);
}

.contact .line .right.zip.location input[type=text]#location {
    width: rem(151);
}

.contact .line .right label {
    display: block;
    margin: 0 0 rem(9) rem(42);

    @media (min-width: $break-medium) {
        margin: 0 0 rem(9) rem(26);
    }
}

.contact .captchaImage {
    height: rem(52);
    margin: rem(10) 0;
}

.captchaImage img {
    width: auto;
}

.contact .text-label {
    display: block;
    margin-bottom: rem(10);
}
