// Add this attribute to the element that needs a tooltip
[data-tooltip] {
    position: relative;
    z-index: 2;
    cursor: pointer;
}

// Hide the tooltip content by default
[data-tooltip]:before, [data-tooltip]:after {
    visibility: hidden;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    pointer-events: none;
}

// Position tooltip above the element
[data-tooltip]:before {
    background-color: #000;
    background-color: hsla(0, 0%, 20%, 0.9);
    border-radius: rem(3);
	bottom: 150%;
    color: $c-text-white;
    content: attr(data-tooltip);
    font-size: rem(13);
    left: 50%;
    line-height: 1.5;
    margin-bottom: rem(5);
    margin-left: rem(-80);
    padding: rem(7);
    position: absolute;
    text-align: left;
    width: rem(280);
}

// Triangle hack to make tooltip look like a speech bubble
[data-tooltip]:after {
    border-top: rem(5) solid $c-divider-default;
    border-top: rem(5) solid hsla(0, 0%, 20%, 0.9);
    border-right: rem(5) solid transparent;
    border-left: rem(5) solid transparent;
    bottom: 150%;
    content: " ";
    font-size: 0;
    left: 50%;
    line-height: 0;
    margin-left: rem(-5);
    position: absolute;
    width: 0;
}

// Show tooltip content on hover
[data-tooltip]:hover:before, [data-tooltip]:hover:after {
    visibility: visible;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity: 1;
}
