.mod_post .navbar {
    border-top: rem(1) solid $c-divider-default;
    font-weight: normal;
    padding: rem(10) 0;
}

.mod_post .navbar .buttons {
    margin: 0 0 rem(7) 0;
}

.mod_post .navbar .button {
    padding-left: rem(8);
    padding-right: rem(8);
}

.mod_post .navbar .btnBack {
    float: left;
    margin: 0 rem(11) rem(10) 0;
}

.mod_post .navbar .btnNew {
    float: left;
}

.mod_post .navbar .btnPrev,
.mod_post .navbar .btnNext {
    background: none;
    color: $c-text-brand;
    float: right;
    margin-left: rem(16);
    padding: 0;
    text-align: center;

    &:before {
        @include icon-styles();
        font-size: rem(18);
        line-height: rem(24);
    }

    &.disabled {
        color: $c-text-grey;
    }
}

.mod_post .navbar .btnPrev:before {
    content: icon-char("angle-left");
}

.mod_post .navbar .btnNext:before {
    content: icon-char("angle-right");
}

.mod_post .inner {
    overflow: hidden;
}

.mod_post .uinfo {
    background: $c-background-grey;
    margin-bottom: rem(20);
    overflow: hidden;
    padding: rem(5);

    @media (max-width: $break-medium) {
        font-size: rem(13);
    }
}

.mod_post.owner .uinfo {
    background: $c-background-owner;
}

.mod_post .uinfo .col {
    margin: 0;
    overflow: hidden;
}

.mod_post .uinfo .uicon {
    float: left;
    height: rem(53);
    margin: 0 rem(10) 0 0;
    width: rem(53);
}

.mod_post .uinfo .newMsg:before {
    @include icon-styles();
    content: icon-char("envelope");
    bottom: rem(-2);
    margin-left: rem(5);
    position: relative;
}

.mod_post .uinfo .loginStatus.online {
    border: rem(1) solid $c-brand-default;
    border-radius: 50%;
    bottom: rem(-3);
    display: inline-block;
    height: rem(16);
    margin-left: rem(5);
    position: relative;
    width: rem(16);
}

.mod_post .uinfo .loginStatus.online:before {
    background-color: $c-brand-default;
    content: "";
    display: block;
    border-radius: 50%;
    height: rem(8);
    left: rem(3);
    top: rem(3);
    position: absolute;
    width: rem(8);
}

.mod_post .uinfo .row {
    margin: rem(3) 0 rem(3) 0;
}

.mod_post .uinfo .sep {
    margin: 0 rem(5) 0 rem(5);
}

.mod_post .uinfo .uinfo-meta {
    font-size: rem(12);
    margin-top: rem(6);
}

.mod_post .bd {
    margin: 0;
}

.mod_post .signature {
    border-top: rem(1) solid $c-divider-default;
    margin-top: rem(10);
    padding-top: rem(5);
}

.mod_post .actionbar {
    border-bottom: rem(1) solid $c-divider-default;
    margin-top: rem(15);
    padding: rem(10) 0;
    position: relative;
}

.mod_post .actionbar .btnReply {
    float: right;
}

.mod_post .actionbar .btnComment {
    float: right;
    font-size: rem(15);
    font-weight: bold;
}

.mod_post .actionbar .btnReport,
.mod_post .actionbar .btnHtmlMode,
.mod_post .actionbar .btnCancel {
    font-size: rem(13);
}

.mod_post.answer_content {
	margin-left: rem(50);
}

.mod_post.answer_content .mod_reply {
	margin-left: rem(-50);
}

.mod_post.answer_content.level2,
.mod_post.answer_content.level2 .mod_reply {
	margin-left: 0;
}

.post_content .cite,
.post_content address,
.post_content blockquote,
.post_content p,
.layer .layer_ask .post_content p,
.post_content pre,
.post_content ol,
.post_content ul,
.post_content table {
    margin: rem(3) 0 rem(9) 0;
    // für border-bottom der Links
    padding-bottom: rem(1);
}

.post_content .dictionary_link {
    border-bottom: 1px dotted;
    text-decoration: none;
}

.mod_post.answer_content .post_content {
    background: $c-background-grey;
    padding: rem(5) rem(10);
}

.mod_post.answer_content.level2 .post_content {
    background: none;
    padding: 0;
}

.post_content {
	img {
        height: auto;
        max-width: 100%;
        width: auto;
    }

    > * {
        word-break: break-word;
    }

    ol,
    ul {
        padding-left: rem(32);
    }

    address {
        font-style: italic;
    }

    pre {
        padding: rem(2) rem(5) rem(2) rem(15);
    }

    *:first-child {
        margin-top: rem(3);
    }

    blockquote,
    .cite {
        background: #F0F6FA;
        border: rem(1) solid $c-divider-default;
        padding: rem(5) rem(10);
    }

    .cite .cite {
        background: #F6FAF0;
    }

    .cite .cite .cite {
        background: #F0F6FA;
    }

    .cite .cite .cite .cite {
        background: #F6FAF0;
    }

    .post_edit_info {
        font-style: italic;
        margin: rem(10) 0 rem(5) 0;
    }
}

#blogPostPreviewContent .post_content {
    background: #FFFFFF;
    padding: rem(5);
}
