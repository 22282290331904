select {
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512' %3E%3Cpath fill='rgb(217,65,109)' d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z' class=''%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat, repeat;
    background-position: right rem(11) top 50%, 0 0;
    background-size: rem(10) auto, 100%;
    padding-right: rem(24);
    position: relative;

    &::-ms-expand {
        display: none;
    }
}
