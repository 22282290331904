.media {
    .bd {
        display: table-cell;
        margin: 0;
    }

    .hl a {
        color: $c-text-default;
        font-weight: bold;
        font-size: rem(16);
        line-height: rem(20);
    }

    .hl .hd {
        color: $c-text-brand;
    }

    .img {
        float: left;
        width: auto;

        .aside & {
            float: none;
            margin: 0 0 rem(10) 0;
        }
    }

    .img img {
        display: block;
        height: auto;
        width: 100%;
    }
}

.mod_teaser {
    .sep {
        @media (min-width: $break-medium) {
            border-bottom: rem(1) solid $c-divider-default;
        }
    }

    .bd {
        padding: rem(10) 0;

        @media (min-width: $break-medium) {
            padding: rem(28) 0;
        }
    }

    .topic {
        padding-top: rem(13);
        text-align: right;
    }

    .first {
        padding-top: 0;
    }

    .media {
        @include stretchToMobileViewport();
        position: relative;

        .page_inside & {
            @media (max-width: $break-medium-minus-one) {
                margin-left: $d-content-padding-negative;
                width: 100vw;
            }
        }
    }

    .media .img {
        float: none;
        width: 100%;

        @media (min-width: $break-medium) {
            float: left;
            margin-right: rem(30);
            width: rem(140);
        }
    }

    .media .teaserImageOverlay {
        @media (min-width: $break-medium) {
            height: rem(36);
            width: rem(36);
        }
    }

    .media .teaserImageOverlay_block--text,
    .media .teaserImageOverlay_block--arrow {
        @media (min-width: $break-medium) {
            display: none;
        }
    }

    .media .bd {
        background-color: $c-background-brand;
        bottom: $d-content-padding;
        left: 0;
        padding: $d-box-padding;
        position: absolute;
        right: $d-content-padding;

        @media (min-width: $break-medium) {
            background-color: transparent;
            bottom: auto;
            left: auto;
            padding: 0;
            position: static;
            right: auto;
        }
    }

    .media .txt {
        display: none;

        @media (min-width: $break-medium) {
            display: block;
        }
    }

    .media .kicker {
        color: $c-text-white;

        @media (min-width: $break-medium) {
            color: $c-text-grey;
        }
    }

    .media .hl {
        margin-bottom: 0;

        @media (min-width: $break-medium) {
            margin-bottom: rem(14);

        }
    }

    .media .hl a {
        color: $c-text-white;
        font-size: rem(20);
        line-height: rem(24);

        @media (min-width: $break-medium) {
            color: $c-text-default;
            font-size: rem(16);
            line-height: rem(20);
        }
    }

    .media .ad:before {
        color: $c-text-white;

        @media (min-width: $break-medium) {
            color: $c-text-grey;
        }
    }

    .ft {
        padding: rem(3) 0 rem(7);
    }

    .ilist li {
        margin-bottom: rem(8);
    }

    .ilist li a.ad {
        padding: 0;
    }

    .paging {
        margin-top: rem(11);
    }

    .ad + .hl .ad:before {
        content: none;
    }
}

.content_small .mod_teaser .media .img {
    float: none;
    margin-right: 0;
}

.content_small .mod_teaser .media .img img {
	height: auto;
	margin-bottom: rem(5);
	width: 100%;
}
