$healthonnet-img-height: 72;

.bottom {
    align-items: flex-end;
    display: flex;
    min-height: rem($healthonnet-img-height);
    margin-top: rem(15);

    .bottom__menu {
        display: none;

        @media (min-width: $break-medium) {
            display: block;
            flex-grow: 1;
        }
    }

    .bottom__link {
        color: $c-text-default;
        padding-left: rem(10);
        vertical-align: bottom;
    }

    .bottom__to-top {
        color: $c-text-default;
        vertical-align: bottom;

        &:before {
            @include icon-styles();
            content: icon-char("angle-up");
            background-color: $c-background-brand;
            border-radius: rem(5);
            color: $c-text-white;
            display: inline-block;
            font-size: rem(13);
            height: rem(36);
            line-height: rem(32);
            padding-left: 0;
            margin-right: rem(12);
            text-align: center;
            text-indent: rem(1);
            vertical-align: bottom;
            width: rem(36);
        }
    }

    &__healthonnet {
        padding-left: rem(60);
        position: relative;

        @media (min-width: $break-medium) {
            padding: 0 rem(70) 0 0;
        }
    }

    &__healthonnet img {
        bottom: 0;
        position: absolute;
        left: 0;
        width: auto;

        @media (min-width: $break-medium) {
            float: none;
            height: rem($healthonnet-img-height);
            left: auto;
            right: 0;
        }
    }
}

.btnReport,
.btnHtmlMode,
.btnCancel,
.btnComment {
    line-height: rem(36);
}
