.mod_tag_cloud .bd {
    border-bottom: rem(1) solid $c-divider-default;
    border-top: rem(1) solid $c-divider-default;
    padding: 0 rem(10);
}

.mod_tabs .mod_tag_cloud .hd {
    display: block;
    padding-top: rem(15);
}
