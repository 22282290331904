.mod_eisprung .infoImg {
	margin: 0 0 rem(10) 0;
}

.mod_eisprung .navbar {
	background: $c-background-grey;
	padding: rem(10) rem(15);
}

.mod_eisprung .navbar .buttons {
	margin: 0 0 rem(8) 0;
}

.mod_eisprung .navbar .btnBack {
	float: left;
	margin-left: rem(-3);
	margin-right: rem(11);
}

.mod_eisprung .navbar .btnNew {
	float: left;
}

.mod_eisprung .navbar .btnPrev {
	float: right;
	height: rem(18);
	margin-left: rem(5);
	width: rem(18);

	&:before {
		@include icon-styles();
		content: icon-char("angle-left");
		color: $c-text-brand;
	}
}

.mod_eisprung .navbar .btnNext {
	float: right;
	height: rem(18);
	margin-left: rem(5);
	width: rem(18);

	&:before {
		@include icon-styles();
		content: icon-char("angle-right");
		color: $c-text-brand;
	}
}

.mod_eisprung .navbar .disabled:before {
	color: $c-text-grey;
}

.mod_eisprung .inner {
	overflow: hidden;
}

.mod_eisprung .hl {
	margin: rem(15) 0 rem(15) 0;
}

.mod_eisprung .uinfo {
	float: left;
	margin: 0 rem(10) rem(15) 0;
	overflow: hidden;
	width: rem(100);
}

.mod_eisprung .uicon {
	height: rem(53);
	margin-bottom: rem(10);
	width: rem(53);
}

.mod_eisprung .uinfo .line {
	margin: 0 0 rem(5) 0;
}

.mod_eisprung .bd {
	margin: 0 0 rem(15) rem(110);
}

.mod_eisprung .no_uinfo .bd {
	margin-left: 0;
}

.mod_eisprung .signature {
	border-top: rem(1) solid $c-divider-default;
	margin-top: rem(10);
	padding-top: rem(5);
}

.mod_eisprung .actionbar {
	background: transparent;
	border-bottom: rem(1) solid $c-divider-default;
	margin: rem(20) 0;
	padding: rem(10) 0;
}

.mod_eisprung .actionbar .btnReply {
	@media (min-width: $break-medium) {
		margin-left: rem(20);
		float: right;
	}
}

.mod_eisprung table {
	width: 100%;
}

.mod_eisprung table td {
	padding: rem(3) 0;
}

.mod_eisprung table td #createProfileLink {
	vertical-align: middle;
}

.mod_eisprung table td #createProfileLinkLabel {
	margin-left: rem(3);
	vertical-align: middle;
}

.mod_eisprung table table {
	width: auto;
}

.mod_eisprung table .calendar {
	border-collapse: collapse;
	border: 0;
	width: auto;
	text-align: center;
}

.mod_eisprung table .calendar input, table.calendar select {
	font-size: rem(10);
}

.mod_eisprung table #eisprung_calendar_basic_mainheading {
	align-items: center;
	display: flex;
	padding: rem(12) 0;
	border: rem(1) solid $c-divider-default;
	border-right: 0;
	border-left: 0;
	justify-content: space-between;
}

.mod_eisprung table .mainheading #prevMonth .icon,
.mod_eisprung table .mainheading #nextMonth .icon {
	cursor: pointer;
	background: none;
	width: rem(25);

	&:before {
		@include icon-styles();
		color: $c-text-brand;
	}
}

.mod_eisprung table .mainheading #prevMonth .icon {
	float: left;
	margin-right: rem(5);

	&:before {
		content: icon-char("angle-left");
	}
}

.mod_eisprung table .mainheading #nextMonth .icon {
	float: right;
	margin-left: rem(5);

	&:before {
		content: icon-char("angle-right");
	}
}

.mod_eisprung table .mainheading #prevMonth:hover, .mod_eisprung table .mainheading #nextMonth:hover {
	text-decoration: underline;
}

.mod_eisprung .mainheading #month,
.mod_eisprung .mainheading #year {
	margin: 0 rem(4);
	width: auto;
}

.mod_eisprung table .calendar td {
	border: 0;
	text-align: center;
}

.mod_eisprung table .caldayheading {
	border-collapse: collapse;
	cursor: pointer;
	empty-cells: show;
}

.mod_eisprung table .caldayheading th {
	border: rem(1) solid $c-divider-default;
	cursor: default;
	padding: rem(5) rem(12) rem(5);
	text-align: left;
	font-weight: 700;
	width: rem(64);
}

.mod_eisprung table .calcells {
	border-collapse: collapse;
	text-align: left;
}

.mod_eisprung table table {
	width: 100%;
}

.mod_eisprung table table td {
	border: rem(1) solid $c-divider-default;
	height: rem(37);
	padding: rem(1);
	text-align: left;
	vertical-align: middle;
	width: rem(64);
}

.mod_eisprung table table td div {
	padding: rem(10);
}

.mod_eisprung table td div {
	padding: rem(1);
	margin: 0;
}

.mod_eisprung table td.wkhead {
	text-align: center;
	border-right: rem(3) double #ccc;
	color: #0054e3;
}

.mod_eisprung table td.wkday {
	background-color: #fff;
}

.mod_eisprung table td.wkend {
	background-color: #fff;
}

.mod_eisprung table td div .icon {
	display: block;

	@media (min-width: $break-medium) {
		float: right;
	}
}

.mod_eisprung table td.curdate div {
	background-color: #e5e5e5;
}

.mod_eisprung table td.curdate div .icon {
	width: rem(15);
	height: rem(16);
}

.mod_eisprung table td.mensdate div .icon {
	width: rem(12);
	height: rem(16);
}

.mod_eisprung table td.eisprungdate div .icon {
	width: rem(14);
	height: rem(16);
}

.mod_eisprung table td.urintestdate div .icon {
	width: rem(20);
	height: rem(20);
}

.mod_eisprung table td.fruchtbardate div .icon {
	width: rem(14);
	height: rem(12);
	margin-top: rem(3);
}

.mod_eisprung table td.fruchtbardateFemale div .icon {
	width: rem(17);
	height: rem(12);
	margin-top: rem(3);
}

.mod_eisprung table td.einistungdate div .icon {
	width: rem(17);
	height: rem(16);
}

.mod_eisprung table td.bluttestdate div .icon {
	width: rem(17);
	height: rem(17);
}

.mod_eisprung td .icon:before {
	@include icon-styles();
}

.mod_eisprung td.curdate .icon:before {
	content: icon-char("clock");
	color: #999;
}

.mod_eisprung td.mensdate .icon:before {
	content: icon-char("tint");
	color: red;
}

.mod_eisprung td.eisprungdate .icon:before {
	content: icon-char("mars");
	color: blue;
}

.mod_eisprung td.urintestdate .icon:before {
	content: icon-char("flask");
	color: #f0e138;
}

.mod_eisprung td.fruchtbardate .icon:before {
	content: icon-char("heart");
	color: red;
}

.mod_eisprung td.fruchtbardateFemale .icon:before {
	content: icon-char("venus");
	color: #ce6ace;
}

.mod_eisprung td.einistungdate .icon:before {
	content: icon-char("star");
	color: green;
}

.mod_eisprung td.bluttestdate .icon:before {
	content: icon-char("syringe");
	color: darkred;
}

.mod_eisprung table.calcResult {
	margin: rem(20) 0;
}

.mod_eisprung table.calcResult td.col1 {
	font-weight: bold;
	max-width: rem(225);
}

.mod_eisprung table.calcResult td.col2 {
	font-weight: bold;
	width: auto;
}

.mod_eisprung table.legend {
	margin-bottom: rem(25);
}

.mod_eisprung table.legend td {
	display: block;
	font-size: rem(11);
	padding: rem(5) rem(10);

	@media (min-width: $break-medium) {
		display: table-cell;
	}
}

.mod_eisprung .legend .icon {
	float: left;
	display: block;
	width: rem(22);
}

.mod_eisprung .requiredHint {
	color: $c-text-grey;
	display: inline-block;
	font-style: italic;
	font-size: rem(11);
	margin-bottom: rem(16);
}

.mod_eisprung table.legend td.curdate .icon {
	height: rem(14);
}

.mod_eisprung table.legend td.mensdate .icon {
	height: rem(13);
}

.mod_eisprung table.legend td.eisprungdate .icon {
	height: rem(14);
}

.mod_eisprung table.legend td.urintestdate .icon {
	height: rem(16);
}

.mod_eisprung table.legend td.fruchtbardateFemale .icon {
	height: rem(12);
}

.mod_eisprung table.legend td.fruchtbardate .icon {
	height: rem(12);
}

.mod_eisprung table.legend td.einistungdate .icon {
	height: rem(14);
}

.mod_eisprung table.legend td.bluttestdate .icon {
	height: rem(14);
}
