// Ad marker

.ad {
    @include ad();
}

.inverse .ad {
    @include ad($c-text-white);
}

.ad .ad:before {
    display: none;
}

.mod_ad {
    @include ad();
}

.mod_ad_bottom {
    @include ad();
    padding-bottom: rem(14);
    position: relative;

    &:before {
        position: absolute;
        bottom: 0;
    }
}

.mod_ad_left {
    @include ad();
    position: relative;

    @media (min-width: $break-medium) {
        margin-top: rem(27);
    }

    &:before {
        float: none;
        left: 0;
        margin: 0;
        position: absolute;
        top: rem(-16);
    }
}

.mod_ad .ad,
.mod_ad .hl_ad,
.mod_ad_bottom .ad,
.mod_ad_bottom .hl_ad,
.mod_ad_left .ad,
.mod_ad_left .hl_ad {
    padding: 0;

    &:after {
        content: none;
    }
}

.mod_ad.mod_rectangle {
    background: #F9F9F7;
    border: 1px solid #EEEDE8;
    text-align: center;
    width: 100%;
}

// Ad slots

.banner_top {
    display: none;
    text-align: center;

    @media (min-width: rem(1490)) {
        display: block;
    }

    .main-menu-flyout-open & {
        @media (min-width: $break-medium) {
            @include blur();
        }
    }
}

.banner_right {
    display: none;
    left: 50%;
    margin-left: rem(425);
    position: absolute;
    top: 0;

    @media (min-width: $break-medium) {
        display: block;
    }
}

.banner_right--sticky {
    position: fixed;
}

.banner_left {
    display: none;
    right: 50%;
    position: absolute;
    top: 0;
    margin-right: rem(425);

    @media (min-width: $break-medium) {
        display: block;
    }
}

.banner_left--sticky {
    position: fixed;
}

.wallpaper_top {
    margin-left: rem(98);
    margin-right: rem(-10);
    position: relative;
    text-align: right;

    @media (min-width: $break-medium) {
        width: rem(728);
    }
}

.wallpaper_right {
    left: 50%;
    position: absolute;
    top: 0;

    @media (min-width: $break-medium) {
        margin-left: rem(425);
    }
}

.hockey_top {
    margin-bottom: rem(10);
    margin-left: rem(98);
    margin-right: rem(-10);
    text-align: right;

    @media (min-width: $break-medium) {
        width: rem(728);
    }
}

.hockey_right {
    left: 50%;
    position: absolute;
    top: rem(152);

    @media (min-width: $break-medium) {
        margin-left: rem(425);
    }
}

.billboard {
    margin-bottom: rem(30);
}

.ad_asmi.inactive {
	background: none !important;
	padding: 0 !important;

    &:after {
        content: none;
    }
	&:before {
		content: none;
	}
}

.ad_mobile.mod_ad_bottom_sticky.inactive {
    display: none;
}

// Teads-Werbung enthält nur Code, nichts Sichtbares:
div.ad_asmi_richmedia,
div.ad_asmi_teads {
    margin: 0;
    padding: 0;

    &:after {
        content: none;
    }
}

// Anzeige-Markierung des Werbemittels ausblenden:
.mod_ad .dc_headline {
    display: none;
}

// Damit "ANZEIGE"-Markierung und Abstand entfallen, wenn AdServer nichts ausliefert:
.content .mod_adcode,
.aside .mod_adcode {
    margin: 0;
}

.content div.mod_adcode,
.content table.mod_adcode td {
    padding-bottom: rem(20);
}

.aside div.mod_adcode,
.aside table.mod_adcode td {
    padding-bottom: rem(50);
}

.content .mod_tabs .mod_ad {
    margin-top: rem(15);
}

.ad_mobile {
    margin: rem(20) 0 rem(30) 0;
    text-align: center;
}

.ad_mobile.ad_asmi_content_ad,
.ad_mobile.ad_asmi_footer_ad,
.ad_mobile.ad_asmi_reminder {
    margin: rem(10) 0 rem(15) 0;
}

.ad_mobile.mod_ad_bottom_sticky {
    left: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: $z-header-mobile - 2;
    margin: 0;
    padding: rem(25) rem(1) 0;
    background-color: #fff;

    .page-has-socialbar &,
    &.social_padding {
        padding-bottom: rem(34);
    }

    &:before {
        content: "Anzeige";
        display: block;
        float: left;
        font-size: rem(10);
        font-variant: normal;
        position: absolute;
        left: rem(4);
        line-height: 1rem;
        padding: 0;
        margin: 0;
        top: 0;
    }
}

.page-has-socialbar #mob_anchor_ad {
    bottom: 2.1875rem !important;
}

.ad_bottom_close_button {
    background-color: $c-brand-dark;
    cursor: pointer;
    display: block;
    height: rem(25);
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: rem(25);
    z-index: 1;

    &:before {
        @include icon-styles();
        color: #fff;
        content: icon-char("times");
        font-size: rem(18);
        line-height: rem(25);
    }
}

ins.adsbygoogle {
    text-decoration: inherit;
}

.mod_textimage_ad li {
    border-bottom: rem(1) solid $c-divider-default;
    padding: rem(5) 0 rem(12) 0;
}

.mod_textimage_ad .img {
    width: 100%;
}

.mod_textimage_ad .floating .img {
    float: left;
    margin-right: rem(20);
    width: auto;
}

.mod_textimage_ad .floating .txt {
    margin-left: rem(127);
}

.mod_textimage_ad .txt a,
.mod_textimage_ad .txt a:hover {
    display: block;
    text-decoration: none;
}

#sky .sitebar {
    margin-top: rem(15);
}
