.raty {
	white-space: nowrap;

    i:before {
        @include icon-styles();
        color: $c-text-brand;
        font-size: rem(17);
        height: rem(16);
        margin-right: rem(1);
        width: rem(16);
    }

    .star-on-png:before {
        content: icon-char("star");
    }

    .star-off-png:before {
        content: icon-char("star-o");
    }

    .star-half-png:before {
        content: icon-char("star-half-alt");
    }

    &-wrapper {
        display: flex;
        margin: rem(16) 0;
        // For CLS
        min-height: rem(21);
    }

    &-message {
        color: $c-text-grey;
        font-size: rem(12);
        padding-left: rem(8);
    }
}
