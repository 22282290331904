.cancel_account {
    border-bottom: none;
    border-top: none;
    margin-bottom: rem(20);
    padding: 0;
}

.cancel_account .hl {
    border-bottom: rem(1) solid $c-divider-default;
    margin-bottom: rem(14);
    padding-bottom: rem(3);
}

.cancel_account p {
    margin-bottom: rem(13);
}

.cancel_account .submit {
    float: right;
}
