
.pregnancy-calendar {

    p {
        margin-bottom: rem(16);
    }

    &__box {
        background-color: $c-background-grey;
        padding: rem(16);
    }

    .infoImg {
        margin: 0 0 rem(10) 0;
    }

    label[for="cycleLength"] {
        @media (min-width: $break-medium) {
            display: inline-block;
            margin-top: rem(16);
        }
    }

    select#cycleLength {
        margin-top: rem(9);

        @media (min-width: $break-medium) {
            float: right;
            max-width: rem(100);
        }
    }

    .line {
        margin: rem(5) 0 rem(5);
    }

    .line.radio {
        margin-bottom: rem(16);
    }

    .line.radio label {
        margin: rem(16) 0 rem(5) 0;
    }

    .mainForm {
        margin-bottom: rem(20);
    }

    #calcDueDay_content {
        margin: 0 0 rem(10) 0;
    }

    .checkboxLine {
        margin: rem(5) 0 rem(16);
    }

    .checkboxLine label {
        margin-left: rem(5);
    }

    .highlight {
        margin: rem(15) 0;
    }

    .navbar .hl {
        margin: 0 0 rem(10);
    }

    .navbar .inner {
        padding: 0;
    }

    .navbar .calc_result {
        margin: 0 0 rem(15) 0;
    }

    .navbar .buttons {
        margin: 0 0 rem(8) 0;
    }

    .navbar .btnBack {
        float: left;
        margin-left: rem(-3);
        margin-right: rem(11);
    }

    .navbar .btnNew {
        float: left;
    }

    .navbar .btnPrev {
        float: right;
        height: rem(18);
        margin-left: rem(5);
        width: rem(18);

        &:before {
            @include icon-styles();
            content: icon-char("arrow-left");
        }
    }

    .navbar .btnNext {
        float: right;
        height: rem(18);
        margin-left: rem(5);
        width: rem(18);

        &:after {
            @include icon-styles();
            content: icon-char("arrow-right");
        }
    }

    .navbar .left {
        margin: 0;
        width: 100%;
    }

    .navbar .right {
        margin: 0;
        width: 100%;
    }

    .sswBtn {
        padding-top: 0;
    }

    .inner {
        overflow: hidden;
    }

    .image {
        margin: 0 0 rem(5) 0;
    }

    .uinfo {
        float: left;
        margin: 0 rem(10) rem(15) 0;
        overflow: hidden;
        width: rem(100);
    }

    .uicon {
        height: rem(53);
        margin-bottom: rem(10);
        width: rem(53);
    }

    .uinfo .line {
        margin: 0 0 rem(5) 0;
    }

    .bd {
        margin: 0 0 rem(15) rem(110);
    }

    .no_uinfo .bd {
        margin-left: 0;
    }

    .signature {
        border-top: rem(1) solid $c-divider-default;
        margin-top: rem(10);
        padding-top: rem(5);
    }

    .actionbar {
        background: $c-background-grey;
        padding: rem(10) rem(15);
    }

    .actionbar .btnReply {
        @media (min-width: $break-medium) {
            float: right;
            margin-left: rem(20);
        }
    }

    .fl {
        float: left;
    }

    .fr {
        float: right;
    }

    input[type=checkbox],
    input[type=radio] {
        vertical-align: middle;
    }

    label {
        margin-left: rem(2);
        vertical-align: middle;
    }

    .inner.navbutton {
        border-top: rem(1) solid $c-divider-default;
        border-bottom: rem(1) solid $c-divider-default;
        padding: rem(10) 0;
    }

    .inner.navbutton .navNew {
        margin-bottom: rem(10);

        @media (min-width: $break-medium) {
            float: left;
            margin-bottom: 0;
        }
    }

    .inner.navbutton .navNew a {
        float: none;
    }

    .inner.navbutton .navPrev {
        color: $c-text-default;
        float: left;

        @media (min-width: $break-medium) {
            margin-left: rem(20);
        }
    }

    .inner.navbutton .navNext {
        float: right;
    }

    .inner.navbutton .submitAction {
        display: block;
        float: left;
    }

    .inner.navbutton .btnPrev {
        float: left;
        margin-right: rem(6);
        text-indent: 0;
        width: rem(18);

        &:before {
            @include icon-styles();
            content: icon-char("arrow-left");
        }
    }

    .inner.navbutton .btnNext {
        float: left;
        margin-right: rem(6);
        text-indent: 0;
        width: rem(18);

        &:before {
            @include icon-styles();
            content: icon-char("arrow-right");
        }
    }

    .calc_result .left {
        color: $c-text-grey;
        padding-right: rem(10);

        @media (min-width: $break-medium) {
            color: inherit;
            width: 45%;
        }
    }

    .calc_result .right {
        font-weight: bold;

        @media (min-width: $break-medium) {
            width: 55%;
        }
    }

    .calcDueDay_form {
        margin-bottom: rem(24);
    }

    .ovulationDay_form,
    .calcDueDay_form {
        @media (min-width: $break-medium) {
            float: left;
            padding-right: rem(8);
        }
    }

    .firstCycleDate_form {
        @media (min-width: $break-medium) {
            float: left;
            padding-left: rem(8);
        }
    }

    .calcDueDay {
        font-weight: bold;
    }

    .edit-due-day {
        padding-top: rem(10);
        display: block;
    }
}
