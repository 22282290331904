.nm-header {
    background-color: $c-background-white;
    border-bottom: rem(2) solid $c-divider-brand;
    height: $d-mobile-header-height;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0 auto;
    right: 0;
    display: block;
    min-width: $d-content-min-width;
    z-index: $z-header-mobile;

    @media (min-width: $break-content) {
        border: none;
        height: auto;
        position: relative;
        top: auto;
        left: auto;
        z-index: $z-header-desktop;
    }

    .nm-date {
        display: none;
    }

    .nm-logo {
        text-align: center;
        padding: rem(8) 0;
        height: auto;

        @media (min-width: $break-content) {
            padding: 0;
            height: rem(95);
        }
    }

    .nm-logo img {
        display: inline-block;
        height: rem(40);
        width: auto;

        @media (min-width: $break-content) {
            height: rem(58.5);
        }
    }

    .nm-logo-anbieter {
        background-color: $c-background-brand;
        display: block;
        padding: rem(2) $d-content-padding;

        @media (min-width: $break-content) {
            background-color: transparent;
        }
    }

    .nm-logo-anbieter__image {
        display: none;

        @media (min-width: $break-content) {
            display: block;
            height: rem(15);
            left: 0;
            position: absolute;
            top: 0;
        }
    }

    .nm-logo-anbieter__image-white {
        display: inline-block;
        height: rem(14);
        vertical-align: middle;
        width: auto;

        @media (min-width: $break-content) {
            display: none;
        }
    }

    .nm-search {
        background-color: $c-background-white;
        bottom: 0;
        display: none;
        height: rem(48);
        left: 0;
        padding: rem(7) $d-content-padding 0;
        position: absolute;
        top: rem(23);
        width: 100%;
        z-index: 2;

        @media (min-width: $break-content) {
            border-top: rem(1) solid $c-divider-dark;
            border-bottom: rem(1) solid $c-divider-dark;
            padding: 0;
            top: auto;
        }
    }

    .search-bar-content {
        display: flex;
    }

    .search input {
        border: rem(1) solid $c-divider-brand;
        flex-grow: 1;
        outline: none;
        width: auto;

        @media (min-width: $break-content) {
            background: none;
            border: none;
            font-size: rem(22);
            font-weight: bold;
            line-height: rem(43);
            padding: 0 rem(17);
        }
    }

    .search button {
        background: $c-background-brand;
        border-top-right-radius: rem(5);
        border-bottom-right-radius: rem(5);
        border: none;
        cursor: pointer;
        padding: 0;

        @media (min-width: $break-content) {
            border-radius: 0;
        }

        &:before {
            @include icon-styles();
            content: icon-char("search");
            color: $c-text-white;
            font-size: rem(20);
            line-height: rem(26);
            padding: 0 rem(11);

            @media (min-width: $break-content) {
                font-size: rem(24);
                line-height: rem(46);
            }
        }
    }

    .search-close {
        cursor: pointer;

        &:before {
            @include icon-styles();
            content: icon-char("times");
            color: $c-text-brand;
            font-size: rem(20);
            line-height: rem(42);
            padding: 0 rem(10) 0 rem(20);

            @media (min-width: $break-content) {
                font-size: rem(24);
                padding: 0 rem(11);
                line-height: rem(46);
            }
        }
    }

    .suggest {
        background: $c-background-grey;
        border-bottom: rem(1) solid $c-divider-white;
        display: none;
        left: $d-content-padding;
        padding: rem(17);
        position: absolute;
        top: rem(49);
        z-index: $z-layer;

        @media (min-width: $break-content) {
            left: 0;
            top: rem(47);
            width: 100%;
        }
    }

    .suggest a {
        color: $c-text-default;
        display: block;
        font-size: rem(16);
        font-weight: bold;
        line-height: rem(20);
        padding: rem(5) 0;
    }

    .toggleMenu {
        color: $c-text-grey-dark;
        font-size: rem(7);
        letter-spacing: rem(1.4);
        text-transform: uppercase;
        position: absolute;
        left: $d-content-padding;
        top: rem(36);
        z-index: 1;

        &:hover {
            text-decoration: none;
        }

        &:before {
            @include icon-styles();
            content: icon-char("bars");
            color: $c-text-brand;
            display: block;
            font-size: rem(14);
            padding-bottom: rem(3);
        }

        @media (min-width: $break-content) {
            display: none;
        }
    }

    .toggleMenu.active:before {
        content: icon-char("times");
        font-size: rem(20);
    }

    .toggle-usermenu {
        color: $c-text-white;
        float: right;
        font-size: rem(12);
        letter-spacing: rem(.6);
        line-height: rem(20);
        text-transform: uppercase;

        @media (min-width: $break-content) {
            display: none;
        }

        &:before {
            @include icon-styles();
            content: icon-char("lock");
            padding-right: rem(5);
            vertical-align: top;
        }
    }

    .toggle-usermenu--is-logged-in:before {
        content: icon-char("user");
    }

    .toggle-usermenu--is-logged-in:after {
        @include icon-styles();
        content: icon-char("angle-down");
    }

    .toggle-usermenu--is-logged-in.opened:after {
        content: icon-char("angle-up");
    }

    .toggle-searchbar {
        color: $c-text-grey-dark;
        font-size: rem(7);
        letter-spacing: rem(1.4);
        text-transform: uppercase;
        position: absolute;
        right: $d-content-padding;
        text-align: center;
        top: rem(36);
        z-index: 1;

        &:hover {
            text-decoration: none;
        }

        &:before {
            @include icon-styles();
            content: icon-char("search");
            color: $c-text-brand;
            display: block;
            font-size: rem(20);
            padding-bottom: rem(3);
        }

        @media (min-width: $break-content) {
            display: none;
        }
    }
}
