@include icon-font-face();

* {
    box-sizing: border-box;
}

// Always force a scrollbar in non-IE
html {
    scroll-padding-top: $d-mobile-header-height + 1rem;

    @media (min-width: $break-content) {
	    scroll-padding-top: 1rem;
    }
}

body {
    background: $c-background-white none;
    color: $c-text-default;
    font-family: $f-regular-default;
    font-size: rem(14);
    line-height: rem(18);
    min-width: $d-content-min-width;
}

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: "";
    content: none;
}

ins {
    text-decoration: none;
}

mark {
    font-style: italic;
}

del {
    text-decoration: line-through;
}

abbr[title], acronym[title], dfn[title] {
    border: none;
    cursor: help;
}

table {
    font-family: $f-regular-default;
    border-collapse: collapse;
    border-spacing: 0;
}

hr {
    display: block;
    height: rem(1);
    border: 0;
    border-top: rem(1) solid $c-divider-default;
    margin: rem(16) 0;
    padding: 0;
}

input, select {
    vertical-align: middle;
}

// Normalize monospace sizing:
// en.wikipedia.org/wiki/MediaWiki_talk:Common.css/Archive_11#Teletype_style_fix_for_Chrome
pre, code, kbd, samp {
    font-family: monospace, sans-serif;
}

// Accessible focus treatment: people.opera.com/patrickl/experiments/keyboard/test
a:hover, a:active {
    outline: none;
}

ul, ol {
    margin-left: rem(32);
}

ol {
    list-style-type: decimal;
}

// Remove margins for navigation lists
nav ul,
nav li {
    margin: 0;
    list-style: none;
    list-style-image: none;
}

small {
    font-size: 85%;
}

td {
    vertical-align: top;
    font-size: rem(14);
    line-height: rem(18);
}

th {
    font-weight: bold;
    line-height: rem(18);
}

/* Set sub, sup without affecting line-height: gist.github.com/413930 */
sub, sup {
    font-size: 75%;
    position: relative;
}

sup {
    top: rem(-8);
}

sub {
    bottom: rem(-4);
}

p {
    margin-bottom: rem(12);
}

pre {
    /* www.pathf.com/blogs/2008/05/formatting-quoted-code-in-blog-posts-css21-white-space-pre-wrap/ */
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
    padding: rem(15);
}

/* Align checkboxes, radios, text inputs with their label by: Thierry Koblentz tjkdesign.com/ez-css/css/base.css  */
input[type="radio"] {
    vertical-align: text-bottom;
}

input[type="checkbox"] {
    vertical-align: bottom;
}

/* Hand cursor on clickable input elements */
label,
input[type="button"],
input[type="submit"],
input[type="image"],
button {
    cursor: pointer;
}

/* Webkit browsers add a rem(2) margin outside the chrome of form elements */
button, input, select, textarea {
    margin: 0;
    font-family: $f-regular-default;
}

input[type=text],
input[type=password],
input[type=date],
input[type=email],
select,
textarea {
    appearance: none;
    background-color: $c-background-white;
    border: rem(1) solid $c-divider-default;
    color: $c-text-default;
    font-size: rem(14);
    line-height: rem(18);
    padding: rem(11) rem(14);
    width: 100%;

    @media (min-width: $break-medium) {
        padding: rem(7) rem(14);
    }
}

input:invalid, textarea:invalid {
    box-shadow: 0 0 rem(5) red;
}

input[type=radio] {
    appearance: none;
}

input::placeholder {
    color: $c-placeholder;
    opacity: 1;
}

/* j.mp/webkit-tap-highlight-color */
a:link {
    -webkit-tap-highlight-color: transparent;
}

/* Make buttons play nice in IE:
   www.viget.com/inspire/styling-the-button-element-in-internet-explorer/ */
button {
    width: auto;
    overflow: visible;
}

i, em {
    font-style: italic;
}

/* legend bugfix chrome */
fieldset {
    padding-top: rem(1);
}

a,
a:active,
a:hover,
a:visited {
    color: $c-text-brand;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
    margin-bottom: rem(16);
}

h1 {
    font-size: rem(22);
    line-height: rem(28);
}

h2 {
    font-size: rem(20);
    line-height: rem(28);
}

h3 {
    font-size: rem(18);
    line-height: rem(25.6);
}

legend {
    white-space: normal;
}

textarea {
    height: rem(200);
    resize: none;
    overflow: auto;

    @media (min-width: $break-medium) {
        height: rem(400);
    }
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

iframe {
    display: block;
    margin: 0 auto $d-space-after-element;
}

// width must not affect the ad iframes outside .main
.main iframe {
    width: 100%;
}

.versteckt {
	display: none !important;
}

.nowrap {
	white-space: nowrap
}
