.inline_img {
    background-color: $c-background-grey;

    .article & {
        background-color: $c-background-grey;
    }

    dl {
        margin-bottom: 0;
    }

    dl .img {
        position: relative;
        margin: 0 0 rem(5);
    }

    img {
        display: block;
    }

    &.inline_original img {
        max-width: 100%;
        width: auto;
    }

    &.inline_original.inline_no-caption {
        background-color: transparent;
    }

    .magnifier {
        background-color: rgba(0, 0, 0, .5);
        border-radius: rem(5);
        display: block;
        height: rem(36);
        position: absolute;
        right: rem(12);
        top: rem(12);
        text-align: center;
        width: rem(36);

        &:before {
            @include icon-styles();
            content: icon-char("enlarge");
            color: $c-text-white;
            line-height: rem(36);
            font-size: rem(18);
        }
    }

    .caption {
        font-size: rem(12);
        padding: rem(8) $d-box-padding;
    }

    .rights {
        color: $c-text-grey;
        font-size: rem(12);
        font-style: italic;
        padding: rem(10) $d-box-padding;
    }

    .caption + .rights {
        padding-top: 0;
    }
}
