.mod_my_intro .h1 {
    margin-bottom: rem(20);
}

.mod_my_latest {
    overflow: hidden;
}

.mod_my_latest .list {
    margin-bottom: rem(12);
}

.mod_my_latest .list li {
    padding: rem(10) 0;
    position: relative;
}

.mod_my_latest .txt {
    margin-top: rem(3);
}

.mod_my_latest .sub {
    margin-top: rem(3);
}

.mod_my_latest .info {
    margin: rem(15);
}

.mod_my_latest .icon {
    display: none;
}

.mod_my_latest .simple.list li {
    min-height: 0;
}

.mod_my_latest_list .kicker {
    margin-bottom: rem(5);
}

.mod_my_latest_list .kicker,
.mod_my_latest_list .hl,
.mod_my_latest_list .sub,
.mod_my_latest_list .bd {
    margin-left: rem(74);
}

.mod_my_latest_list .uicon {
    float: left;
    height: rem(53);
    width: rem(53);
}

.mod_my_latest_answer .sub {
    border-top: rem(1) solid $c-divider-default;
    margin-top: rem(10);
    padding-top: rem(10);
}

.my_latest_sort {
    height: rem(34);
    text-align: center;
}

.my_latest_sort fieldset {
    padding: rem(9) 0 rem(8);
}

.my_latest_sort input[type=radio] {
    margin-left: rem(18);
}
