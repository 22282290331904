.mod_hibbel acronym {
    border: none;
    text-decoration: none;
}

.mod_hibbel .infoImg {
    margin: 0 0 rem(10) 0;
}

.mod_hibbel .mod_richtext {
    margin-bottom: rem(20);
}

.mod_hibbel .actionbar {
    background-color: $c-background-grey;
    margin: 0 $d-content-padding-negative rem(30);
    padding: rem(18);
}

.mod_hibbel .actionbar .button {
    margin: rem(20) 0 0 0;
    white-space: nowrap;

    @media (min-width: $break-medium) {
        margin: 0 0 0 rem(8);
    }
}

.mod_hibbel .filter-wrapper {
    padding-top: rem(4);

    @media (min-width: $break-medium) {
        display: flex;
    }
}

.mod_hibbel .table {
    margin: 0 0 rem(20) 0;
}

.mod_hibbel .table .hl {
    border-bottom: rem(1) solid $c-divider-default;
    display: flex;
    padding-bottom: rem(8);
}

.mod_hibbel .table .hl .note {
    font-style: italic;
    font-weight: normal;
}

.mod_hibbel .table .line {
    border-bottom: rem(1) solid $c-divider-default;
    padding: rem(15) 0;
}

.mod_hibbel .line-inner {
    display: flex;

    @media (min-width: $break-medium) {
        align-items: center;
    }
}

.mod_hibbel .table .userinfo {
    flex-grow: 1;
    padding-right: rem(5);
    text-align: left;
}

.mod_hibbel .table .userinfo-text {
    font-weight: bold;
    font-size: rem(16);
    line-height: rem(20);
    padding: rem(4) 0;

    @media (min-width: $break-medium) {
        font-size: rem(14);
        line-height: rem(28);
        padding: 0;
    }
}

.mobile-nmt,
.mobile-status {
    font-size: rem(11);
}

.mod_hibbel .table .action {
    order: 5;
    padding: 0 0 0 0;
    text-align: center;
    width: rem(58);

    @media (min-width: $break-medium) {
        order: unset;
    }
}

.mod_hibbel .table .cycle {
    padding: 0 0 0 0;
    text-align: center;
    width: rem(65);
}

.mod_hibbel .table .nmt {
    padding: 0 0 0 rem(3);
    text-align: center;
    width: rem(83);
}

.mod_hibbel .table .status {
    padding: 0 0 0 rem(3);
    text-align: right;
    width: rem(85);
}

.mod_hibbel .table .basalBtn {
    display: block;
    margin-bottom: rem(10);

    @media (min-width: $break-medium) {
        margin-bottom: 0;
    }
}

.mod_hibbel .table .basalBtn:before {
    @include icon-styles();
    content: icon-char("graph");
    color: $c-text-brand;
    font-size: rem(30);

    @media (min-width: $break-medium) {
        float: left;
        font-size: rem(17);
        margin-right: rem(4);
    }
}

.mod_hibbel .table .basalBtn.inactive:before {
    color: $c-text-grey;
}

.mod_hibbel .table .commentBtn:before {
    @include icon-styles();
    content: icon-char("angle-square-down");
    color: $c-text-brand;
    font-size: rem(30);

    @media (min-width: $break-medium) {
        float: left;
        font-size: rem(17);
        margin-right: rem(4);
    }
}

.mod_hibbel .table .commentBtn.opened:before {
    content: icon-char("angle-square-up");
}

.mod_hibbel .table .commentBtn.inactive:before {
    color: $c-text-grey;
}

.mod_hibbel .table .comment {
    padding: rem(5) 0 0 0;
    width: 100%;
}

.mod_hibbel .notes {
    margin: 0 0 rem(20) 0;
}

.mod_hibbel .notes p {
    color: $c-text-grey;
    font-style: italic;
    font-size: rem(12);
    margin-bottom: rem(3);
}
