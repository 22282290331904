.mod_user_friends_overview .hl {
    border-bottom: rem(1) solid $c-divider-default;
    padding-bottom: rem(3);
}

.mod_user_friends_overview .big {
    background: $c-background-grey;
    padding: $d-box-padding;
}

.mod_user_friends_overview .total {
    padding: 0 rem(15);
    text-align: center;

    @media (min-width: $break-medium) {
        float: left;
        width: 33%;
    }
}

.mod_user_friends_overview .online {
    border-top: rem(1) solid $c-divider-default;
    border-bottom: rem(1) solid $c-divider-default;
    padding: rem(15);
    margin: rem(15) 0;
    text-align: center;

    @media (min-width: $break-medium) {
        border: none;
        border-left: rem(1) solid $c-divider-default;
        float: left;
        margin: 0;
        padding: 0 rem(15);
        width: 34%;
    }
}

.mod_user_friends_overview .received {
    padding: 0 rem(15);
    text-align: center;

    @media (min-width: $break-medium) {
        float: left;
        border-left: rem(1) solid $c-divider-default;
        width: 33%;
    }
}

.mod_user_friends_overview .sent {
    border-bottom: rem(1) solid $c-divider-default;
    padding: rem(10);
    text-align: center;
}

.mod_user_friends_overview .sent .count {
    margin-left: rem(5);
}

.mod_user_friends_search .hl {
    border-bottom: rem(1) solid $c-divider-default;
    margin: 0 0 rem(10) 0;
    padding-bottom: rem(3);
    width: 100%;
}

.mod_user_friends_search h3 {
    margin: 0 0 rem(10) 0;
}

.mod_user_friends_search h4 {
    margin: 0 0 rem(10) 0;
}

.mod_user_friends_search .intro {
    margin: 0 0 rem(15) 0;
}

.mod_user_friends_search form {
    background: transparent;

    @media (min-width: $break-medium) {
        display: flex;
        align-items: center;
    }
}

.mod_user_friends_search form input[type=text] {
    margin-bottom: rem(10);

    @media (min-width: $break-medium) {
        flex-grow: 1;
        margin: 0 rem(10);
    }
}

.mod_user_friends_search .error_message {
    margin: 0 0 rem(10) 0;
}

.mod_user_friends_search .hint {
    background: $c-background-grey;
    margin: 0 0 rem(15) 0;
    padding: $d-box-padding;
}

.mod_user_friends_search .user {
    display: flex;
    padding: rem(10) 0;
}

.mod_user_friends_search .user.first {
    border-top: rem(1) solid $c-divider-default;
}

.mod_user_friends_search .actionbar {
    border-bottom: rem(1) solid $c-divider-default;
    margin-bottom: rem(10);
    padding: rem(10) 0;
    text-align: right;
}

.mod_user_friends_list .hl {
    border-bottom: rem(1) solid $c-divider-default;
    margin: 0;
    padding-bottom: rem(3);
    width: 100%;
}

.mod_user_friends_list form {
    background: transparent;
}

.mod_user_friends_list .actionbar {
    background: $c-background-grey;
    margin: rem(10) 0;
    padding: $d-box-padding;
}

.mod_user_friends_list input[type=checkbox] {
    float: left;
    vertical-align: middle;
}

.mod_user_friends_list .checkboxLabel {
    display: block;
    margin-left: rem(38);
    min-height: rem(32);
    vertical-align: middle;

    @media (min-width: $break-medium) {
        min-height: auto;
    }
}

.mod_user_friends_list .line {
    border-bottom: rem(1) solid $c-divider-default;
    display: flex;
    align-items: center;
    padding: rem(10) 0;
}

.mod_user_friends_list .line.first {
    border-top: rem(1) solid $c-divider-default;
}

.mod_user_friends_search .uinfo,
.mod_user_friends_sent .uinfo,
.mod_user_friends_received .uinfo,
.mod_user_friends_list .uinfo {
    flex-grow: 1;
    overflow: hidden;
    min-width: rem(120);
    width: rem(120);

    @media (min-width: $break-medium) {
        flex-grow: unset;
    }
}

.mod_user_friends_search .uinfo2,
.mod_user_friends_list .uinfo2 {
    display: none;

    @media (min-width: $break-medium) {
        flex-grow: 1;
        display: block;
        padding-left: rem(10);
        overflow: hidden;
    }
}

.mod_user_friends_list .action {
    padding-left: rem(10);
    display: table-cell;
}

.mod_user_friends_list .loginStatus,
.mod_user_friends_received .loginStatus {
    float: left;
    border: rem(1) solid $c-divider-dark;
    border-radius: 50%;
    bottom: rem(-1);
    display: block;
    height: rem(16);
    position: relative;
    width: rem(16);
}

.mod_user_friends_list .loginStatus.online:before,
.mod_user_friends_received .loginStatus.online:before {
    background-color: $c-divider-dark;
    content: "";
    display: block;
    border-radius: 50%;
    height: rem(8);
    left: rem(3);
    top: rem(3);
    position: absolute;
    width: rem(8);
}

.mod_user_friends_received .newMsg,
.mod_user_friends_list .action .newMsg {
    float: left;
    margin-left: rem(10);
    text-indent: 0;

    &:hover {
        text-decoration: none;
    }

    &:before {
        @include icon-styles();
        content: icon-char("envelope");
        display: block;
        font-size: rem(17);
    }
}

.mod_user_friends_received .delBtn,
.mod_user_friends_list .delBtn {
    float: left;
    margin-left: rem(10);
    text-indent: 0;

    &:before {
        @include icon-styles();
        content: icon-char("times-square");
        color: $c-text-brand;
        font-size: rem(17);
    }
}

.mod_user_friends_received .hl {
    border-bottom: rem(1) solid $c-divider-default;
    margin: 0 0 rem(10) 0;
    padding-bottom: rem(3);
    width: 100%;
}

.mod_user_friends_received form {
    flex-grow: 1;
}

.mod_user_friends_received .message {
    display: flex;
    padding: rem(10) 0 rem(10) 0;
    vertical-align: middle;
}

.mod_user_friends_search .uicon,
.mod_user_friends_list .uicon,
.mod_user_friends_sent .uicon,
.mod_user_friends_received .uicon {
    display: none;

    @media (min-width: $break-medium) {
        float: left;
        padding: 0;
        height: rem(53);
        margin: 0;
        min-width: rem(53);
        width: rem(53);
    }
}

.mod_user_friends_received .msg_content {
    flex-grow: 1;
    margin-left: rem(10);
}

.mod_user_friends_received .actionbar {
    border-bottom: rem(1) solid $c-divider-default;
    margin-bottom: rem(10);
    padding-bottom: rem(10);

    @media (min-width: $break-medium) {
        align-items: center;
        display: flex;
        flex-direction: row-reverse;
    }
}

.mod_user_friends_received .actionbar .icons {
    float: right;
    display: flex;
    margin-bottom: rem(10);

    @media (min-width: $break-medium) {
        float: none;
        margin-bottom: 0;
    }
}

.mod_user_friends_sent .hl {
    border-bottom: rem(1) solid $c-divider-default;
    margin-bottom: rem(10);
    padding-bottom: rem(3);
    width: 100%;
}

.mod_user_friends_sent form {
    background: transparent;
}

.mod_user_friends_sent .message {
    align-items: center;
    border-bottom: rem(1) solid $c-divider-default;
    display: flex;
    padding: rem(10) 0;
}

.mod_user_friends_sent .msg_content {
    flex-grow: 1;
    margin-left: rem(10);
    overflow: hidden;
}

.mod_user_friends_sent .action .delBtn {
    margin-left: rem(10);
    text-indent: 0;

    &:before {
        @include icon-styles();
        content: icon-char("times-square");
        font-size: rem(17);
    }
}
