.nm-topnav ul {
    list-style-type: none;
}

.nm-topnav > ul > li {
    float: left;
    padding: 0 rem(5);
    position: relative;
    width: auto;
    border-right: rem(1) solid $c-divider-default;
}

.nm-topnav > ul > li:last-child {
    padding-right: 0;
    border: none;
}
