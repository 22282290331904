.odd {
    border-bottom: rem(1) solid $c-divider-default;
}

.even {
    border-bottom: rem(1) solid $c-divider-default;
    border-top: rem(1) solid #ffffff;
}

.item {
    border-bottom: rem(1) solid #ffffff;
}
