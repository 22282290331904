.mod_tabs {
    .mod_tab {
        display: none;
    }

    .active {
        display: block;
    }

    .hd {
        display: none;
    }

    .tabbingTitle {
        display: block;
        margin-bottom: rem(10);
    }

    .mod_tab .mod_teaser .first {
        padding-top: rem(10);
    }
}

.tabs {
    border-bottom: rem(1) solid $c-divider-default;
    display: flex;
    padding: 0 rem(10);

    &-wrapper {
        overflow-x: auto;
        margin: rem(20) 0;
    }

    li {
        background: $c-background-grey;
        border: rem(1) solid $c-divider-default;
        bottom: rem(-1);
        display: block;
        margin-left: rem(-1);
        position: relative;
        white-space: nowrap;
        z-index: 1;
    }

    li.active {
        background-color: $c-background-white;
        border-bottom: rem(1) solid $c-text-white;
    }

    li a,
    li a:hover {
        color: $c-text-default;
        display: block;
        line-height: rem(36);
        padding: 0 rem(14);
        outline: none;
        text-decoration: none;
    }
}
