.mod_privacy p {
    margin-bottom: rem(15);
}

.mod_privacy form {
    margin-bottom: rem(40);
}

.mod_privacy form fieldset {
    margin-bottom: 0;
}

.mod_privacy form legend {
    border-bottom: rem(1) solid $c-divider-default;
    font-size: rem(16);
    font-weight: bold;
    padding-bottom: rem(3);
    margin-bottom: rem(8);
    width: 100%;
}

.mod_privacy input[type=checkbox],
.mod_privacy input[type=radio] {
    margin-bottom: rem(5);
    vertical-align: middle;
}

.mod_privacy form .line {
    border: none;
    margin-bottom: 0;
    padding: rem(8) 0;
}

.mod_privacy form .line_nb,
.mod_privacy form .line_nb + .line_nb {
    border: none;
}

.mod_privacy .left {
    @media (min-width: $break-medium) {
        padding-right: rem(10);
        text-align: left;
        width: 40%;
    }
}

.mod_privacy .right {
    @media (min-width: $break-medium) {
        padding-left: rem(10);
        text-align: left;
        width: 60%;
    }
}

.mod_privacy form .left label {
	font-weight: bold;
    margin: 0;
}

.mod_privacy form .right label {
    margin: 0 0 0 rem(6);
    vertical-align: middle;
}

.mod_privacy form .right p {
    margin: 0;
}

.mod_privacy .notifySettings .left {
    text-align: left;
}

.mod_privacy form.notifySettings .right {
    text-align: left;
}

.mod_privacy form .actionbar .left {
    font-style: italic;
    margin: 0 rem(10) 0 rem(15);
    text-align: left;
}

.mod_privacy form .actionbar .right {
    text-align: left;
}

.mod_privacy form input[type=submit] {
    float: right;
}

.privacy {
    color: $c-text-grey;
    font-size: rem(10);
}

.privacy.expert:after {
    @include icon-styles();
    content: icon-char("experts");
    padding-left: rem(6);
}

.privacy.private:after {
    @include icon-styles();
    content: icon-char("lock");
    padding-left: rem(6);
}
