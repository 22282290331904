.mod_answers .answerActionbar .button {
	margin-bottom: rem(10);
}

.mod_answers_overview {
    background-color: $c-background-grey;
    padding: rem(28) rem(15);
}

.mod_answers_overview .owner .user {
    font-weight: bold;
}

.mod_answers_overview ul {
    list-style: none;
    margin-left: 0;
}

.mod_answers_overview ul ul {
    margin-left: 0;
}

.mod_answers_overview li {
    background: transparent url(../img/tree-list-icon.gif) no-repeat scroll 0 0;
    padding-top: rem(3);
    padding-left: rem(5);
}

.mod_answers_overview li li {
    background: transparent url(../img/tree-list-subicon.gif) no-repeat scroll 0 0;
    padding-left: rem(18);
}

.mod_answers_overview li .inner {
    margin-left: rem(10);
}

.mod_answers_overview .title a {
    color: $c-text-default;
    font-weight: bold;

    &:visited {
        color: $c-text-visited;
    }
}
