.mod_nav .list.list li {
    border-bottom: rem(1) solid $c-divider-default;
    padding: rem(7) 0;
}

.mod_nav .list li a {
    display: block;
}

.mod_nav .list li a span {
    float: left;
}

.mod_nav .list li a span.adMarker {
    color: $c-text-grey;
    float: none;
    font-size: rem(12);
    font-weight: normal;
    margin-left: rem(8);
    text-transform: uppercase;
}

.mod_nav .list li a img {
    float: right;
}

.mod_nav .list li.first {
    border-top: rem(1) solid $c-divider-default;
}