.mod_eyecatcher_wide {
    @include fullWidthBackground($c-background-grey-dark);
    padding-bottom: rem(40);
    position: relative;

    @media (min-width: $break-medium) {
        padding: rem(60) 0 rem(65);
    }

    .bgimage-wrapper {
        @include stretchToMobileViewport();
        margin-bottom: rem(20);
        position: relative;
        z-index: 1;

        @media (min-width: $break-medium) {
            position: absolute;
            top: 0;
            bottom: 0;
            height: 100%;
            margin-bottom: 0;
            width: auto;
            z-index: 0;
        }
    }

    .bgimage {
        @media (min-width: $break-medium) {
            position: relative;
            top: 0;
            bottom: 0;
            height: 100%;
            width: auto;
            z-index: 0;
        }
    }

    .bd {
        padding: 0 rem(24);
        position: relative;
        text-align: center;
        z-index: 1;
    }

    .logo {
        width: rem(190);

        @media (min-width: $break-medium) {
            width: rem(240);
        }
    }

    .headline {
        color: $c-text-grey;
        letter-spacing: rem(1);
        margin: rem(-21) 0 rem(25);
        text-transform: uppercase;

        @media (min-width: $break-medium) {
            margin: rem(-21) 0 rem(40);
        }
    }

    .txt {
        margin-bottom: rem(25);
    }

    .button {
        margin: 0 rem(7) rem(10);

        @media (min-width: $break-medium) {
            margin: 0 rem(7);
        }
    }

    &--left .bgimage-wrapper:before {
        @media (min-width: $break-medium) {
            background: linear-gradient(to right, rgba(239, 235, 234, 1) 0%, rgba(255, 255, 255, 0) 100%);
            bottom: 0;
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: rem(200);
            z-index: 1;
        }
    }

    &--right .bgimage-wrapper:after {
        @media (min-width: $break-medium) {
            background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(239, 235, 234, 1) 100%);
            bottom: 0;
            content: "";
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            width: rem(200);
            z-index: 1;
        }
    }

    &--left .bd {
        @media (min-width: $break-medium) {
            padding-right: 45%;
        }
    }

    &--right .bd {
        @media (min-width: $break-medium) {
            padding-left: 45%;
        }
    }

    .apple-app-store-image {
        max-height: rem(55);
        width: auto;

        @media (min-width: $break-medium) {
            margin-right: rem(10);
            max-height: rem(38);
        }
    }

    .google-play-store-image {
        max-height: rem(55);
        width: auto;

        @media (min-width: $break-medium) {
            max-height: rem(38);
        }
    }
}
