.hl {
    font-weight: bold;
    font-size: rem(16);
    line-height: rem(20);

    a,
    a:hover {
        color: $c-text-default;
    }
}

.hd {
    @include headline();

    &--centered {
        text-align: center;

        &:after {
            left: 50%;
            margin-left: rem(-15);
        }
    }

    &--centered-mobile {
        @media (max-width: $break-medium-minus-one) {
            text-align: center;

            &:after {
                left: 50%;
                margin-left: rem(-15);
            }
        }
    }

    &--inverted {
        color: $c-text-white;

        &:after {
            border-top: rem(1) solid $c-background-white;
        }
    }

    &--small {
        font-size: rem(16);
        line-height: rem(20);
        margin-bottom: rem(24);
    }
}
