.mod_hcg_calculator {
    .calculator-box {
        background-color: $c-background-grey;
        padding: $d-box-padding;
    }

    .infoImg {
        margin: 0 0 rem(10) 0;
    }

    .day-selector-column {
        padding-right: rem(10);
        margin-bottom: rem(16);

        @media (min-width: $break-medium) {
            margin-bottom: 0;
        }
    }

    .calculator-box {
        @media (min-width: $break-medium) {
            display: flex;
        }
    }

    #calculateField {
        margin: 0 0 rem(15) 0;
    }

    #calculateField td {
        vertical-align: middle;
    }

    #resultPageText {
        margin: 0;
    }

    #resultPageText p {
        margin: 0 0 rem(10) 0;
    }
}
