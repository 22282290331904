.mod_profile .hd {
    position: relative;
}

.priv_change {
    bottom: rem(3);
    position: absolute;
    right: 0;
    color: $c-text-brand;
    font-size: rem(12);
    text-transform: none;

    &:after {
        @include icon-styles();
        content: icon-char("cog");
        padding-left: rem(6);
    }
}

.mod_profile .common {
    background: $c-background-grey;
    padding: rem(15);
}

.mod_profile .uinfo {
    float: left;
    width: rem(140);
}

.mod_profile .uicon {
    height: rem(130);
    padding: 0;
    margin-bottom: rem(10);
    width: rem(130);
}

.mod_profile .messageIcon {
    margin-bottom: rem(10);
}

.mod_profile .messageIcon .newMsg {
    float: left;
    margin-right: rem(7);

    &:before {
        @include icon-styles();
        content: icon-char("envelope");
        font-size: rem(23);
    }
}

.mod_profile .friendship {
    margin-bottom: rem(10);
}

.mod_profile .friendship a {
    background-color: $c-text-brand;
    border-radius: rem(3);
    display: block;
    float: left;
    height: rem(23);
    text-align: center;
    margin: rem(2) rem(7) 0 0;
    width: rem(23);

    &:before {
        content: "+";
        color: $c-text-white;
        font-size: rem(20);
        line-height: rem(23);
    }
}

.mod_profile .friendship .label {
    display: block;
}

.mod_profile .online_status {
    float: right;
    margin: 0;
    text-transform: uppercase;
}

.mod_profile .h3 {
    border-bottom: rem(1) solid $c-divider-default;
    padding-bottom: rem(3);
}

.mod_profile .info {
    font-size: inherit;
    min-height: rem(140);
    margin-left: rem(160);
}

.mod_profile .info ul {
    list-style: none;
    margin-left: 0;
    padding: 0;
}

.mod_profile .info table th {
    padding-right: rem(20);
    text-align: left;
}

.mod_profile .info p {
    margin-bottom: rem(8);
}

.mod_profile .info .sep {
    border-bottom: rem(1) solid $c-divider-default;
    height: rem(1);
    margin: rem(10) 0;
}

.mod_profile .ilist {
    margin-bottom: rem(22);
}

.mod_profile .ilist li {
    border-bottom: rem(1) solid $c-divider-default;
    padding: rem(8) 0;
}

.mod_profile .txt {
    margin: rem(8) 0 0 0;
}

.mod_profile .username {
    margin-bottom: rem(10);
}

.mod_profile .interests {
    margin-bottom: rem(30);
}

.mod_profile .interests .inner {
    background: transparent;
    padding: rem(5) 0;
}

.mod_profile .interests .inner ul {
    margin: 0;
}

.mod_profile_p {
    margin-bottom: 0;
}

.mod_profile_p .h2 {
    border-bottom: rem(1) solid $c-divider-default;
    margin-bottom: rem(15);
    padding-bottom: rem(3);
    position: relative;
}

.mod_profile_p .h3 {
    margin-bottom: rem(5);
}

.mod_profile_p .profile-info table {
    width: 100%;

    @media (min-width: $break-medium) {
        width: auto;
    }
}

.mod_profile_p .profile-info th {
    color: $c-text-grey;
}

.mod_profile_p .profile-info th,
.mod_profile_p .profile-info td {
    @media (max-width: $break-medium-minus-one) {
        display: block;
        width: 100%;
    }
}

.mod_profile_p .icon {
    margin-bottom: rem(15);

    @media (min-width: $break-medium) {
        float: left;
        margin-right: rem(30);
        width: rem(150);
    }
}

.mod_profile_p .icon form {
    @media (min-width: $break-medium) {
        text-align: center;
    }
}

.mod_profile_p .icon input[type=file] {
    display: none;
}

.mod_profile_p .icon button {
    display: block;
    text-indent: 0;
}

.mod_profile_p .icon .upload {
    margin-bottom: rem(10);
}

.mod_profile_p .uicon {
    height: rem(130);
    margin-bottom: rem(5);
    width: rem(130);

    img {
        max-width: 100%;
        max-height: 100%;
        height: auto;
        width: auto;
    }
}

.mod_profile_p .info {
    background-color: $c-background-grey;
    font-size: inherit;
    padding: $d-box-padding;
    margin-bottom: $d-space-after-element;
}

.mod_profile_p table {
    margin-bottom: rem(20);
}

.mod_profile_p th {
    font-weight: normal;
    padding-right: rem(10);
    text-align: left;
}

.mod_profile_p td {
    padding-bottom: rem(9);
}

.mod_profile_p .ilist {
    margin-bottom: rem(16);
}

.mod_profile_p .ilist li {
    border-bottom: rem(1) solid $c-divider-default;
    padding: rem(8) rem(30) rem(8) 0;
    position: relative;
}

.mod_profile_p .ilist li .delete {
    font-size: rem(16);
    position: absolute;
    right: 0;
    text-indent: 0;
    top: rem(7);

    &:before {
        @include icon-styles();
        content: icon-char("times-square");
        color: $c-text-brand;
    }
}

.mod_profile_p .line {
    margin-bottom: rem(15);
}

.mod_profile_p .actionbar {
    padding: rem(10) rem(10);
}

.mod_profile_p .actionbar a {
    float: left;
}

.mod_profile_p .actionbar a.btnHtmlMode {
    margin-left: rem(10);
}

.mod_profile_p .actionbar a.btnCancel {
    float: right;
    margin-right: rem(20);
}

.mod_profile_p .actionbar .submit {
    float: right;
}

.mod_profile_p #blogPostPreviewFormBtn {
    float: left;
}

.mod_profile_p #community_user_userprofile_about_me .about_me_box {
    margin: 0;
}

.mod_profile_p #community_user_userprofile_about_me .about_me_box .status {
    background: $c-background-grey;
    padding: rem(3);
}

.mod_profile_p #community_user_userprofile_about_me .about_me_box .status .inner {
    padding: rem(7) rem(10);
}

.mod_profile_p #community_user_userprofile_about_me .about_me_box .status .status_entry {
    margin: rem(3) 0;
}

.mod_profile_p #community_user_userprofile_about_me p {
    margin-bottom: rem(10);
}

.mod_profile_p .txt {
    margin-bottom: $d-space-after-element;
}

.mod_profile_p .editSignature {
    background: transparent;
}

.mod_profile_p .editSignature .profile_signature {
    margin: 0;
}

.mod_profile_p .editSignature .smileys a {
    display: inline-block;
    margin: 0;
    padding: 0 rem(6) 0 rem(5);
}

.mod_profile_p .editSignature .actionbar {
    border-bottom: rem(1) solid $c-divider-default;
    padding: rem(10) 0;
}

.mod_profile_p .editSignature #blogPostPreviewContent .post_content {
    border: rem(1) solid $c-divider-default;
    width: auto;
}

.mod_profile_p .interests {
    background: $c-background-grey;
    padding: $d-box-padding;
}

.mod_profile_p .interests fieldset {
    border: none;
    margin-bottom: 0;
}

.mod_profile_p .interests .actionbar {
    margin: 0;
    padding: 0;
}

.mod_profile_p .interests .inner {
    background: #f8f3ef;
    padding: rem(8) rem(14);
}

.mod_profile_p .interests legend {
    margin-bottom: rem(9);
}

.mod_profile_p .interests select {
    margin-bottom: rem(10);

    @media (min-width: $break-medium) {
        margin-bottom: 0;
        width: 48%;
    }
}

.mod_profile_p .interests label {
    margin: 0 rem(10) 0 rem(3);
    vertical-align: middle;
}

.mod_profile_p .interests input[type=checkbox] {
    margin-bottom: rem(10);
    vertical-align: middle;

    @media (min-width: $break-medium) {
        margin-bottom: 0;
    }
}

.mod_profile_p .interests input[type=text] {
    margin-top: 0;
}

.mod_profile_p .interests input[type=submit] {
    margin-top: rem(-3);
}

.mod_profile_p .interests .legend {
    margin-bottom: rem(8);
}

.mod_profile_p .interests .line {
    margin-bottom: rem(13);
}

#upload_image_editform img {
    display: block;
    margin: rem(25) auto;
    max-height: 85vh;
    max-width: 85vw;
    min-width: rem(130);
    min-height: rem(130);
    height: auto;
    width: auto;
}

.mod_profile .signature img {
    width: auto;
}
