.mod_visual {
    border-bottom: rem(3) solid $c-divider-default;
    margin-bottom: rem(25);
    min-height: rem(30);
    padding-bottom: rem(10);
    position: relative;
}

.mod_visual a {
    display: block;
}

.mod_visual .txt {
    bottom: rem(10);
    display: block;
    left: 0;
    padding-left: rem(10);
    padding-top: 0;
    position: absolute;
    text-align: left;
    width: 100%;
}

.mod_visual .longtxt {
    bottom: rem(10);
    display: block;
    left: 0;
    padding-left: rem(10);
    padding-top: 0;
    text-align: left;
    width: 100%;
}

.mod_visual img {
	height: auto;
	max-width: 100%;
}
