.meta_info {
	clear: both;
	color: $c-text-grey;
	font-size: rem(12);
	margin-bottom: rem(11);

	.editorialsource--label {
		cursor: pointer;
		display: block;
	}

	.editorialsource--label:after {
		@include icon-styles();
		content: icon-char("angle-down");
		font-size: rem(7);
		padding-left: rem(8);
	}

	.editorialsource--label.open:after {
		content: icon-char("angle-up");
	}

	.copyright {
		display: none;
		font-size: rem(12);
		margin-left: 0;
		line-height: 1.2;
		padding-left: 1em;
		word-wrap: break-word;
	}

	.copyright li {
		position: relative;
		margin-bottom: rem(6);

		&:last-child {
			margin-bottom: 0;
		}
	}

	.icd-info {
		cursor: help;
		margin: 0 rem(1) 0 rem(3);
	}
}

/* Quality Info */

.quality_info {
	align-items: center;
	color: $c-text-default;
	display: flex;
	font-weight: bold;
	font-size: rem(15);
	margin: 1em 2em 1em 1em;

	&:active, &:visited {
		color: $c-text-default;
	}

	&:hover {
		color: $c-text-brand;
		text-decoration: none;
	}

	.quality_info__icon {
		margin: 0 1em 0 0;
		height: rem(60);
	}

	.quality_info__text {
	}

	.fa-info-circle {
		margin-left: rem(8);
	}
}
