input[type=radio] {
    display: inline-block;
    position: relative;
    border: none;
    padding: 0;
    appearance: none;
    height: rem(19);
    vertical-align: middle;
    width: rem(19);

    &::after {
        content: "";
        height: rem(19);
        background-color: $c-background-white;
        border: rem(1) solid $c-divider-default;
        border-radius: 50%;
        box-sizing: border-box;
        display: block;
        margin: 0;
        position: relative;
        width: rem(19);
        z-index: 1;
    }

    &:checked {
        background: transparent;
    }

    &:checked::after {
        border-color: $c-divider-default;
        top: rem(-9);
    }

    &:checked::before {
        background-color: $c-background-brand;
        border-radius: 50%;
        content: "";
        display: block;
        height: rem(9);
        left: rem(5);
        margin: 0;
        position: relative;
        text-indent: rem(-1);
        top: rem(5);
        width: rem(9);
        z-index: 2;
    }
}

input[type=radio] + label {
    padding-left: rem(6);
}
