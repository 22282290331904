/* Expertise slider */

.mod_expertise {
    border-bottom: 1px solid $c-brand-default;
    border-top: 1px solid $c-brand-default;
    position: relative;
}

.mod_expertise .hd:after {
    display: none;
}

.mod_expertise .mod_expertise__slider {
    margin-bottom: 0;
    padding: 0;
    width: 100%;
}

.mod_expertise .mod_expertise__slide {
    padding: rem(16) rem(32) rem(72);
}

@media screen and (min-width: 48em) {
    .mod_expertise .mod_expertise__slide {
        padding-left: rem(64);
        padding-right: rem(64);
    }
}

.mod_expertise .mod_expertise__slider > li:not(:first-child) {
    display: none;
}

.mod_expertise .mod_expertise__slider.slick-slider .slick-slide {
    display: block;
}

.mod_expertise .intro {
    text-align: center;
}

.mod_expertise .intro .hd {
    border: none;
    font-size: rem(22);
    font-weight: bold;
    margin: rem(30) 0 rem(32);
}

.mod_expertise .intro img {
    border: 1px solid #eee;
    border-radius: 50%;
    height: rem(96);
    margin-bottom: rem(32);
    width: rem(96);
}

.mod_expertise .intro .author__name {
    font-size: rem(18);
    font-weight: bold;
    margin-bottom: rem(8);
}

.mod_expertise .question.question {
    font-size: rem(18);
    font-weight: bold;
    line-height: rem(24);
    margin: rem(16) 0;
}

.mod_expertise .number {
    background-color: $c-brand-default;
    border-radius: 50%;
    color: white;
    display: block;
    font-size: rem(18);
    font-weight: bold;
    height: rem(30);
    line-height: rem(30);
    margin: 0 auto;
    text-align: center;
    width: rem(30);
}

.mod_expertise .outro {
    text-align: center;
}

.mod_expertise .outro-headline {
    font-size: rem(20);
    line-height: 1.3;
    margin: rem(16) 0 rem(32);
}

.mod_expertise .outro-text {
    color: $c-brand-default;
    font-size: rem(24);
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: rem(24);
}

.mod_expertise .next,
.mod_expertise .prev {
    background-color: $c-brand-default;
    bottom: rem(2);
    color: $c-text-white;
    cursor: pointer;
    display: block;
    font-size: rem(30);
    font-style: normal;
    font-weight: normal;
    height: rem(40);
    line-height: rem(40.8);
    position: absolute;
    text-align: center;
    text-decoration: none;
    text-indent: 0;
    vertical-align: top;
    width: rem(40);
    z-index: 1;
}

.mod_expertise .prev {
    left: 0;
}

.mod_expertise .prev:before {
    @include icon-styles();
    content: icon-char("angle-left");
    font-size: rem(23);
    padding-right: rem(5);
}

.mod_expertise .next {
    right: 0;
}

.mod_expertise .next:before {
    @include icon-styles();
    content: icon-char("angle-right");
    font-size: rem(23);
    padding-left: rem(.3125);
}

.mod_expertise .slick-dots {
    bottom: 0;
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0 0 rem(10) 0;
    position: absolute;
    width: 100%;
}

.mod_expertise .slick-dots button {
    display: block;
    height: rem(23);
    opacity: 0;
    width: rem(23);
}

.mod_expertise .slick-dots li {
    position: relative;
}

.mod_expertise .slick-dots li:before {
    content: "";
    background-color: $c-brand-default;
    border-radius: 50%;
    display: block;
    height: rem(10);
    margin: rem(7);
    outline: none;
    overflow: hidden;
    position: absolute;
    width: rem(10);
    z-index: 1;
}

.mod_expertise .slick-dots .slick-active {
    opacity: .5;
}

/* Expertise tip */

.mod_expertise--tip {
    padding: rem(24) rem(16);
}

@media screen and (min-width: 48em) {
    .mod_expertise--tip {
        display: flex;
    }
}

@media screen and (min-width: 48em) {
    .mod_expertise--tip .tip-wrapper {
        padding-right: rem(16);
        width: 60%;
    }
}

.mod_expertise--tip .hd {
    border: none;
    font-size: rem(18);
    font-weight: bold;
    margin-bottom: rem(16);
}

.mod_expertise--tip .txt {
    font-size: rem(18);
    font-style: italic;
}

.mod_expertise--tip .author {
    padding-top: rem(16);
    text-align: center;
}

@media screen and (min-width: 48em) {
    .mod_expertise--tip .author {
        padding-top: 0;
        width: 40%;
    }
}

.mod_expertise--tip .author img {
    border: 1px solid #eee;
    border-radius: 50%;
    height: rem(160);
    width: rem(160);
    margin-bottom: rem(8);
}

.mod_expertise--tip .author__name {
    font-size: rem(16);
    font-weight: bold;
    margin-bottom: rem(5);
}

.mod_expertise--tip .author__job {
    font-size: rem(12);
}
