// Colors --------------------------------------------------------------------------------------------------------------
$c-brand-default: #d9416d;
$c-brand-dark: #a83556;
$c-brand-darker: #811433;

$c-text-white: #fff;
$c-text-visited: #9a9a9a;
$c-text-grey: #777;
$c-text-grey-dark: #545454;
$c-text-brand: $c-brand-default;
$c-text-default: #373737;
$c-text-brand-darker: $c-brand-darker;

$c-background-white: #fff;
$c-background-grey: #f7f5f5;
$c-background-grey-dark: #efebea;
$c-background-brand: $c-brand-default;
$c-background-brand-dark: $c-brand-dark;
$c-background-owner: #FAEBF1;

$c-divider-white: #fff;
$c-divider-default: #ddd;
$c-divider-brand: $c-brand-default;
$c-divider-dark: #707070;
$c-error: #cc0000;
$c-success: #23987D;
$c-placeholder: #aaa;

// Font ----------------------------------------------------------------------------------------------------------------
$f-regular-default: 'Roboto', sans-serif;

// Dimensions  ---------------------------------------------------------------------------------------------------------
$d-content-min-width: 20rem; // rem(320)
$d-content-width: 53.125rem; // rem(850)
$d-box-padding: 1rem; // rem(16)
$d-content-padding: .625rem; // rem(10)
$d-content-padding-negative: -.625rem; // rem(-10)
$d-space-after-element: 1.875rem; // rem(30)
$d-mobile-header-height: 5.1875rem; // rem(83)

// Breakpoints ---------------------------------------------------------------------------------------------------------
$break-large: 62rem; // rem(992)
$break-medium: 48rem; // rem(768)
$break-medium-minus-one: 47.9375rem; // rem(767)
$break-content: $d-content-width;
$break-content-minus-one: $d-content-width - .0625rem;

// Z-index -------------------------------------------------------------------------------------------------------------
$z-header-mobile: 5002000; // GESUND-1267
$z-header-desktop: auto;
$z-header-menu-desktop: 5001999; // GESUND-1267
$z-layer: 5002001; // GESUND-1267
$z-tinymce-layer: 5003000; // GESUND-1832
$z-cookie-banner: 9001;
