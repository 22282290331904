@media print {
    * {
        text-shadow: none;
        filter: none;
        -ms-filter: none;
    }

    /* Black prints faster: sanbeiji.com/archives/953 */
    /* .main *, */
    body {
        background: transparent;
    }

    /* Don't show links for images, or javascript/internal links */
    pre,
    blockquote {
        border: rem(1) solid $c-divider-default;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    /* css-discuss.incutio.com/wiki/Printing_Tables */
    tr, img {
        page-break-inside: avoid;
    }

    @page {
        margin: 0.5cm;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    /* fix top margin of the page */
    .page {
        padding: 0;
    }

    /* Hauptspalte mit voller Breite, da die Marginale beim Druck ausgeblendet wird: */
    .content_main {
        width: auto;
    }

    /* Damit sich Teaser-Boxen nicht überlagern oder ineinander verschieben. */
    .content .mod,
    .aside .mod {
        clear: both;
    }

    /* hide elements */
    .article_bottom,
    .aside,
    .banner_left,
    .banner_right,
    .banner_top,
    .billboard,
    .bottom,
    .breadcrumb,
    .content .paging,
    .footer,
    .header_ad,
    .nm-header .search,
    .hockey_right,
    .hockey_top,
    .page_outside,
    .wallpaper_right,
    .wallpaper_top {
        display: none;
    }

    .mod_ad,
    .mod_ad_left,
    .mod_ad_bottom,
    .mod_ph {
        display: none;
    }

    /* print all article pages at once */
    .article .paging_group {
        display: block;
    }

    /* show default list-style */
    .ilist {
        list-style: disc;
    }

    .mod_gallery .control {
        display: none;
    }

    .slideshow .control {
        display: none;
    }

    .slideshow .list {
        width: auto;
    }

    .slideshow .list li {
        display: none;
    }

    .slideshow .list li:first-child {
        display: block;
    }
}
