.abstract {
    padding: rem(10);
}

.mod_abstract .bd {
    padding: rem(9) 0;
}

.specials .tabs li {
    background: #5b9abd;
    background-image: linear-gradient(to bottom, rgb(91, 153, 189) 0%, rgb(133, 183, 210) 100%);
    border-color: #ffffff;
}

.specials .tabs li a {
    font-weight: normal;
}

.specials .tabs li.active {
    background: #ffffff;
}

.specials {
    margin-bottom: rem(20);
}

.specials .bg {
    border-bottom: rem(1) solid $c-divider-default;
    position: relative;
}

.specials .bg .bg_layer {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.specials .bg .txt {
    top: rem(15);
    display: block;
    left: rem(10);
    letter-spacing: rem(2);
    position: absolute;
    text-transform: uppercase;
}

.specials .bg .tabs {
    border-bottom: none;
    bottom: 0;
    left: 0;
    position: absolute;
}

.specials .hl {
    margin-bottom: rem(18);
    margin-top: rem(12);
}

.specials .buttons {
    border-bottom: rem(1) solid $c-divider-default;
    padding: rem(7) rem(4) rem(12);
}

.specials .buttons li {
    background: $c-background-brand;
    display: block;
    float: left;
    height: rem(70);
    margin: rem(3) rem(4) 0 0;
    width: rem(150);
}

.specials .buttons a {
    display: block;
    height: rem(70);
    position: relative;
    width: rem(150);
}

.specials .buttons a:hover span {
    text-decoration: underline;
}

.specials .buttons .img {
    bottom: rem(32);
    left: 0;
    position: absolute;
    text-align: center;
    width: rem(150);
}

.specials .buttons .txt {
    bottom: rem(14);
    display: block;
    left: 0;
    position: absolute;
    text-align: center;
    width: rem(150);
}

.content_wide .specials .buttons {
    padding: rem(7) rem(10) rem(12);
}
