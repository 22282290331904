.mod_users .users_sort {
    border-bottom: rem(1) solid $c-divider-default;
    height: rem(34);
    text-align: center;
}

.mod_users .users_sort fieldset {
    padding: rem(9) 0 rem(8);
}

.mod_users .users_sort input[type=radio] {
    margin-left: rem(8);
}

.mod_users table {
    border-bottom: rem(1) solid $c-divider-default;
    border-collapse: separate;
    width: 100%;
}

.mod_users th {
    font-weight: normal;
    padding: rem(8) rem(12) rem(7) 0;
    text-align: right;
}

.mod_users th:first-child {
    padding-left: rem(8);
    text-align: left;
}

.mod_users td {
    border-left: rem(1) solid $c-divider-default;
    border-bottom: rem(1) solid $c-divider-default;
    border-top: rem(1) solid $c-divider-default;
    padding: rem(10) rem(12) rem(12) 0;
    text-align: right;
    vertical-align: middle;
}

.mod_users td:first-child {
    border-left: none;
    padding-left: rem(8);
    text-align: left;
}

.mod_users .uicon {
    float: left;
    margin-right: rem(17);
}

.mod_users .reg {
    white-space: nowrap;
}
