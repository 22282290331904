.mod_status .lft {
    text-align: left;
}

.mod_status .div {
    border-left: rem(1) solid $c-divider-default;
    padding: 0 rem(10);
}

.mod_status th:first-child .div,
.mod_status td:first-child .div {
    border: none;
}

.mod_status .txt {
    padding: rem(6) rem(10) rem(10);
}

.mod_status table {
    border-collapse: separate;
    width: 100%;
}

.mod_status td,
.mod_status th {
    border-bottom: rem(1) solid $c-divider-default;
    padding: rem(2) 0;
    text-align: center;
}

.mod_status th {
    font-weight: normal;
}

.mod_status td {
    border-top: rem(1) solid #ffffff;
}
