/* Frage stellen - Experten-Teaser */

.mod_question_ask_expert {
	border-bottom: 1px solid $c-divider-default;

	.bd {
		display: flex;
		margin: rem(15) 0;
	}

	.img {
		width: rem(90);
	}

	.txt {
		flex: 1;
	}

	.avatar {
		border: 1px solid #eee;
		border-radius: 50%;
		display: block;
		float: left;
		height: rem(60);
		margin: 0 rem(15);
		width: rem(60);
	}

	.expertname {
		font-size: 1.1rem;
		font-weight: bold;
		line-height: rem(20);
	}

	.expertdesc {
		margin: rem(10) 0 0 0;
	}

	.button {
		background: $c-brand-default;
		margin: rem(25) 0 0 0;
	}
}
