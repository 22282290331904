.dev {
    display: none;
}

.dev_q {
    display: none;
}

/* Hide only visually, but have it available for screenreaders: by Jon Neal.
  www.webaim.org/techniques/css/invisiblecontent/  &  j.mp/visuallyhidden */
.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: rem(1);
    margin: rem(-1);
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: rem(1);
}

/* Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard: drupal.org/node/897638 */
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

/* Hide visually and from screenreaders, but maintain layout */
.invisible {
    visibility: hidden;
}

/* Show or hide for desktop or mobile view (show and hide with respect to a possible tablet view). */
.mobile-hide,
.desktop-only {
    @media (max-width: $break-medium-minus-one) {
        display: none !important;
    }
}

.desktop-hide,
.mobile-only {
    @media (min-width: $break-medium) {
        display: none !important;
    }
}

.hidden {
    display: none !important;
    visibility: hidden;
}

a.button:hover {
    text-decoration: none;
}

.separator {
    border: none;
    border-top: rem(1) solid $c-divider-default;
    clear: both;
    height: rem(18);
    margin: 0 0 rem(20) 0;
}

.clickable {
    cursor: pointer;
}

.help {
    cursor: help;
}

.badge {
    border: rem(1) solid $c-divider-default;
    border-radius: rem(5);
    height: rem(32);
    text-align: center;
    width: rem(59);
}

.uicon {
    height: rem(42);
    padding: rem(4);
    width: rem(42);

    img {
        height: auto;
        width: auto;
        max-width: 100%;
        max-height: 100%;
    }
}

.table {
    overflow-x: auto;
}

// image replacement - lazy loading
.ir {
    display: block;
    text-indent: rem(-9999);
    overflow: hidden;
    background-repeat: no-repeat;
    text-align: left;
    direction: ltr;
}

.no_ls {
    margin: 0;
    padding: 0;
}

.no_ls li {
    list-style: none;
}

.clearfix {
    @include clearfix();
}

.iframe {
    background: none;
}

.info {
    font-size: rem(11);
}

.error,
.error_message,
.dynamic_error {
    color: $c-error;
    font-weight: bold;
    margin: rem(10) 0 rem(16);
}

.success,
.success_message,
.dynamic_success {
    color: $c-success;
    font-weight: bold;
    margin: rem(10) 0 rem(16);
}

input[type=text].error,
input[type=password].error,
input[type=date].error,
input[type=email].error,
textarea.error,
select.error {
    border-color: $c-error;
    font-weight: normal;
}

.ui-datepicker-trigger {
    position: absolute;
    margin: rem(8) 0 0 rem(-24);
    width: auto;
}

.mod {
    margin-bottom: $d-space-after-element;
}

#fw-debug-toolbar {
	z-index: 2000000000 !important;
}
