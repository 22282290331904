.mod_clip .list li {
    border-bottom: rem(1) solid $c-divider-default;
}

.mod_clip .media {
    padding: rem(15) 0 rem(16);
}

.mod_clip .media .img {
    margin-right: rem(15);
}

.mod_clip .media .hl {
    margin: rem(2) 0;
}

.mod_clip .media .kicker {
    display: block;
    font-weight: normal;
}

.mod_clip .topic {
    padding-top: rem(13);
    text-align: right;
}

.mod_clip .paging {
    margin-top: rem(11);
}

.clip_page {
    .ilist a.ad:before {
        display: none;
    }

    .ilist .kicker.ad:before {
        display: inline-block;
        margin-right: rem(5);
    }
}
