.mod_caroussel {
    margin: 0;
    padding-bottom: rem(20);
}

.mod_caroussel .head {
    position: relative;
}

.mod_caroussel .paging {
    display: none;
    position: absolute;
    right: 0;
    top: rem(3);
}

.mod_caroussel .paging .previous {
    cursor: pointer;
    height: rem(19);
    padding: 0;
    width: rem(19);
}

.mod_caroussel .paging .next {
    cursor: pointer;
    height: rem(19);
    padding: 0;
    width: rem(19);
}

.mod_caroussel .paging .btns a {
    margin: 0;
}

.mod_caroussel .wrapper {
    border-bottom: rem(1) solid $c-divider-default;
    margin: rem(1) 0;
    padding: 0 rem(5);
    overflow: hidden;
    position: relative;
}

.mod_caroussel .kicker {
    margin: rem(12) 0 rem(-7);
}

.mod_caroussel .hl {
    margin: rem(12) 0 rem(7);
}

.mod_caroussel .slider {
    left: 0;
    position: relative;
    width: rem(50000);
}

.mod_caroussel .group {
    float: left;
}

.mod_caroussel .group .slot {
    float: left;
    padding: rem(20) rem(10) rem(13);
}

.mod_caroussel .group .slot.mod_ad {
    background-position: rem(10) rem(6);
}

.mod_caroussel .group .slot img {
    height: auto;
    width: 100%;
}

.mod_caroussel .group_col2 .slot {
    @media (min-width: $break-medium) {
        width: rem(370);
    }
}

.mod_caroussel .group_col3 .slot {
    @media (min-width: $break-medium) {
        width: rem(240);
    }
}

.mod_caroussel .ilist li {
    background-position: rem(-824) rem(-628);
    margin-bottom: rem(5);
}

.mod_caroussel .more {
    float: right;
    margin-top: rem(5);
}

.content_main .mod_caroussel .group .slot {
    padding-left: rem(5);
    padding-right: rem(5);
    width: rem(143);
}

.content_main .mod_caroussel .group .slot.mod_ad {
    background-position: rem(5) rem(6);
}

.content_main .mod_caroussel .group_col2 .slot {
    width: rem(219);
}

.content_main .mod_caroussel .group_col3 .slot {
    width: rem(143);
}
