.mod_news .list .item {
    padding: rem(4) 0 rem(7);
}

.mod_news .list .kicker {
    margin-bottom: rem(1);
}

.mod_news .list .hl {
    margin-bottom: rem(2);
}

.mod_news .list .ad {
    padding-right: rem(50);
    position: relative;

    &:before {
        position: absolute;
        right: 0;
    }
}
