/* Autorenprofil */
.mod_author_profile {
	overflow: hidden;
}

.mod_author_profile .img-wrapper {
	float: left;
	margin: 0 rem(16) rem(20) 0;
	max-width: rem(228)s;
}

.mod_author_profile .img {
	border: 1px solid #cdcdcd;
	display: block;
	margin-bottom: rem(4);
	padding: rem(4);
}

.mod_author_profile .img-wrapper .rights {
	color: #999999;
	font-size: rem(11);
	line-height: rem(17);
}

.mod_author_profile .meta-infos {
	float: left;
	margin-bottom: rem(20);
}

.mod_author_profile .head {
	padding-top: rem(16);
}

.mod_author_profile .head .name {
	font-size: rem(16);
	font-weight: bold;
	margin-bottom: rem(10);
}

.mod_author_profile .head .agentur,
.mod_author_profile .head .job {
	margin-bottom: rem(16);
}

.mod_author_profile .head .ilist li {
	border: none;
}

.mod_author_profile .info,
.mod_author_profile .list {
	margin-bottom: rem(20);
}

.mod_author_profile .info .txt {
	line-height: rem(16);
	margin-top: rem(5);
}
