.mod_comment form {
    border-bottom: rem(1) solid $c-divider-default;
    border-top: rem(1) solid $c-divider-default;
    margin: 0 0 rem(20) 0;
    padding: rem(10);
}

.mod_comment legend {
    padding: rem(3) 0 rem(12);
}

.mod_comment input[type=submit] {
    float: right;
    margin-top: rem(12);
}

.mod_comment form .txt {
    margin: rem(10) 0 0;
}
