.mod_selftest {
    background-color: $c-background-grey;
    padding: $d-box-padding;

    .img {
        float: right;
        margin: 0 0 rem(5) rem(10);
        width: auto;
    }

    .counter {
        color: $c-text-grey;
        font-size: rem(11);
        margin-bottom: rem(3);
    }

    p,
    legend,
    .hl,
    .teaser,
    .text {
        margin-bottom: rem(10);
    }

    .line {
        margin-bottom: rem(5);
    }

    input[type=radio],
    input[type=checkbox] {
        float: left;
    }

    label {
        display: block;
        margin-left: rem(20);
    }

    .answer_desc {
        display: none;
    }

    .buttons {
        clear: both;
    }

    .previous {
        float: left;
    }

    .next {
        float: right;
    }

    .scale {
        height: rem(20);
        margin-bottom: rem(10);
        position: relative;
    }

    .scale img {
        height: rem(10);
        margin: rem(5) 0;
        width: 100%;
    }

    .scalePos {
        background: #000000;
        bottom: 0;
        position: absolute;
        top: 0;
        width: rem(2);
    }
}
