.mod_seo .inner {
    background: $c-background-grey;
    padding: rem(16) $d-box-padding;
}

.mod_seo .hd a:hover {
    text-decoration: none;
}

.mod_seo .img {
	height: auto;
    width: rem(107);
}

.mod_seo .img_left {
    float: left;
    margin: rem(2) rem(10) 0 0;
}

.mod_seo .img_right {
    float: right;
    margin: rem(2) 0 0 rem(10);
}

.mod_seo ul {
    margin-bottom: 0;
}

.mod_seo li {
    border: none;
    padding: 0;
}

.mod_seo p {
    margin-bottom: rem(5);
}

.mod_seo p.link {
    text-align: right;
}
