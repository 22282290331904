.mod_special {
	.ad:before {
		display: block;
	}

	ul {
		border-bottom: rem(1) solid $c-divider-default;
	}

	li {
		padding: rem(28) 0;
	}

	.img {
		@media (min-width: $break-medium) {
			float: left;
			margin-right: rem(20);
			width: rem(140);
		}

		.aside & {
			float: none;
			margin-bottom: rem(10);
			width: 100%;
		}

		img {
			height: auto;
			width: 100%;
		}
	}

	.hl {
		color: $c-text-default;
		margin-bottom: rem(3);
	}

	.ft {
		padding-top: rem(10);
		text-align: right;
	}
}
