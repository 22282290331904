.mod_forum_overview {
    overflow: hidden;
}

.mod_forum_overview .anchors {
    margin-bottom: rem(30);
}

.mod_forum_overview .anchors > li {
    margin-bottom: rem(3);
}

.mod_forum_overview .anchors ul a {
    font-size: rem(12);
}

.mod_forum_overview .anchors li li {
    font-weight: normal;
    margin-left: rem(20);
}

.mod_forum_overview .list {
    display: none;
}

.mod_forum_overview .active .list {
    display: block;
}

.mod_forum_overview .hl {
    background-color: $c-background-grey;
    border-bottom: rem(1) solid $c-divider-default;
    padding: rem(12) rem(15);
}

.mod_forum_overview .hl .inner {
    display: flex;
    position: relative;
    text-decoration: none;
}

.mod_forum_overview .hl .inner img {
    display: none;
    float: left;
    height: rem(42);
    margin-right: rem(10);
    width: rem(42);

    @media (min-width: $break-medium) {
        display: block;
    }
}

.mod_forum_overview .hl .toggleBtn:before {
    @include icon-styles();
    content: icon-char("angle-down");
    color: $c-text-grey;
    font-size: rem(9);
    position: absolute;
    right: 0;
    top: rem(10);
    line-height: rem(23);
}

.mod_forum_overview .active .hl .toggleBtn:before {
    content: icon-char("angle-up");
}

.mod_forum_overview .list-title {
    align-items: center;
    display: flex;
    padding-right: rem(30);
    min-height: rem(42);
}

.mod_forum_overview .list {
    padding: rem(10) 0;
}

.mod_forum_overview .anchors > li > a,
.mod_forum_overview .list a {
    display: block;
    padding-left: rem(20);
    position: relative;

    &:before {
        @include icon-styles();
        content: icon-char("angle-right");
        font-size: rem(10);
        position: absolute;
        left: 0;
        top: 0;
    }
}

.mod_forum_overview .list .subtitle {
    font-size: rem(16);
    line-height: rem(20);
    font-weight: bold;
    margin-bottom: rem(8);
}

.mod_forum_overview .list ul li {
    margin-bottom: rem(5);
}

.mod_forum_overview .archiv {
    margin-top: rem(10);
    text-align: right;
}

.mod_forum_overview .archiv .button {
    margin: 0 0 0 auto;
}

.aside .mod_forum_overview .hl .inner {
    align-items: center;
    display: flex;
}

.aside .mod_forum_overview .hl .inner img {
    height: rem(23);
    width: rem(23);
}

.aside .mod_forum_overview .hl .inner .toggleBtn {
    margin-top: 0;
}

.aside .mod_forum_overview .list {
    padding: rem(7) 0;
}

.aside .mod_forum_overview .list h3 {
    margin-bottom: rem(5);
}

.aside .mod_forum_overview .list ul li {
    margin-bottom: rem(2);
}
