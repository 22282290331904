.mod_doxter {
    border: rem(1) solid $c-divider-default;

    .bd {
        padding: rem(17) rem(30);
    }

    .media {
        padding: 0;
    }

    .txt {
        margin-bottom: rem(8);
    }

    .hl {
        margin-bottom: rem(10);
    }

    .button {
        float: right;

        &:hover {
            text-decoration: none;
        }
    }
}
