.mod_search_filter {
    margin-bottom: rem(20);
    overflow: visible;
}

.mod_search_filter legend {
    border-bottom: rem(3) solid $c-divider-default;
    padding-bottom: rem(5);
    width: 100%;
}

.mod_search_filter .inner {
    padding-bottom: rem(10);
    position: relative;
}

.mod_search_filter .txt {
    border-bottom: rem(1) solid $c-divider-default;
    margin-bottom: rem(10);
    padding-bottom: rem(10);
}

.mod_search_filter .txt .label {
    float: left;
    margin-right: rem(10);
}

.mod_search_filter .txt .name {
    float: left;
    margin-right: rem(5);
}

.mod_search_filter .txt .delete {
    float: left;
    margin-right: rem(5);

    &:before {
        @include icon-styles();
        content: icon-char("times-square");
        color: $c-text-brand;
    }
}

.mod_search_filter .unit {
    float: left;
    margin-bottom: rem(9);
    padding: rem(3) 0 0 rem(3);
    width: 50%;
}

.mod_search_filter .search_options {
    margin: 0 0 rem(8) 0;
    overflow: hidden;
}

.mod_search_filter .search_order {
    margin: 0 0 rem(8) 0;
    overflow: hidden;
    padding: rem(3) 0 0 0;
}

.mod_search_filter .search_order .label {
    margin-right: rem(10);
}

.mod_search_filter .search_order .radio {
    margin-left: rem(10);
}

.mod_search_filter .search_order .radioLabel {
    margin-left: rem(3);
}

.mod_search_filter .search_filter_query label {
    display: block;
    float: left;
}

.mod_search_filter .search_filter_query .search_filter_text {
    float: left;
    margin-left: rem(10);
    position: relative;
}

.mod_search_filter .search_filter_query input {
    width: rem(155);
}

.mod_search_filter input[type=checkbox] {
    vertical-align: text-top;
}

.mod_search_filter input[type=radio] {
    vertical-align: baseline;
}

.mod_search_filter input[type=submit],
.mod_search_filter .submit {
    margin-top: rem(16);

    @media (min-width: $break-medium) {
        bottom: rem(9);
        position: absolute;
        right: 0;
        margin-top: 0;
    }
}

.mod_search_filter .suggest {
    background: $c-background-grey;
    display: none;
    left: 0;
    padding: rem(1) 0;
    position: absolute;
    top: rem(36);
    min-width: rem(157);
    z-index: $z-layer;

    @media (min-width: $break-medium) {
        top: rem(34);
    }
}

.mod_search_filter .suggest a {
    color: $c-text-default;
    display: block;
    font-weight: bold;
    padding: rem(7) rem(15);
}

.mod_search_filter .suggest a:hover {
    text-decoration: none;
}

.mod_search_filter .suggest a span {
    cursor: pointer;
}

.mod_search_filter .suggest a .type {
    display: none;
    float: right;
}
