.mod_pw_forgotten form {
    background: transparent;
}

.mod_pw_forgotten .txt {
    margin: 0 0 rem(20) 0;
}

.mod_pw_forgotten .line {
    margin-bottom: rem(10);
}

.mod_pw_forgotten .line .left {
    @media (min-width: $break-medium) {
        float: left;
        text-align: right;
        width: rem(133);
    }
}

.mod_pw_forgotten .line .right {
    @media (min-width: $break-medium) {
        float: right;
        width: rem(322);
    }
}

.mod_pw_forgotten .line .right input[type=text] {
    margin-bottom: rem(7);
}

.mod_pw_forgotten .line .right label {
    margin-left: rem(3);
    margin-right: rem(6);
    vertical-align: middle;
}
