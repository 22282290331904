.mod_double {
    .line {
        @media (min-width: $break-medium) {
            display: flex;
            padding-bottom: rem(20);
        }
    }

    .media {
        position: relative;
        margin-bottom: rem(20);

        @media (min-width: $break-medium) {
            margin-bottom: 0;
            width: 50%;
        }
    }

    .media .img {
        display: block;
        float: none;
        margin-bottom: rem(7);
        width: auto;
    }

    .left {
        @media (min-width: $break-medium) {
            margin-right: rem(15);
        }
    }

    .right {
        @media (min-width: $break-medium) {
            margin-left: rem(15);
        }
    }

    .hl {
        margin: rem(3) 0;
    }

    .hl .ad {
        padding-right: rem(50);

        &:before {
            position: absolute;
            top: rem(5);
            right: rem(10);
        }
    }
}
