.mod_newsletter {
    background-color: $c-background-grey;
    padding: rem(5) rem(10);

    .error,
    .success {
        margin: rem(10) 0 rem(20) 0;
    }

    .error .hl,
    .success .hl {
        margin: 0 0 rem(10) 0;
    }

    p {
        margin: 0 0 rem(8) 0;
    }

    .line {
        margin: rem(8) 0;
    }

    .optional {
        display: none;
    }

    .line.ssw {
        background-color: $c-background-grey-dark;
        padding: rem(5) rem(10);
        margin-bottom: rem(16);
    }

    .line.ssw .calcDueDay_form {
        margin-bottom: rem(16);
    }

    label[for="cycleLength"] {
        font-size: rem(11);
    }

    .line.ssw .line {
        margin: rem(5) 0 rem(5);
    }

    .line.ssw .line.radio {
        margin-top: rem(10);
    }

    .line.ssw .line.radio label {
        margin-left: rem(5);
    }

    .line-privacy {
        margin: rem(16) 0;
    }

    .line-privacy input[type=checkbox] {
        float: left;
    }

    .line-privacy input[type=checkbox] + label {
        display: block;
        margin-left: rem(38);

        @media (min-width: $break-medium) {
            margin-left: rem(24);
        }
    }
}
