.mod_searchfield {
    background-color: $c-background-grey;
    padding: $d-box-padding;

    .line {
        padding: rem(3) 0;
    }

    .text {
        margin-bottom: rem(10);
        vertical-align: middle;

        @media (min-width: $break-medium) {
            width: 48%;
        }
    }

    .category {
        margin-bottom: rem(10);
        vertical-align: middle;
        @media (min-width: $break-medium) {
            float: right;
            width: 48%;
        }
    }

    .button {
        display: inline;
        vertical-align: middle;
    }
}
