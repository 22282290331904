p.msg {
    min-height: rem(20);
    margin: rem(16) 0 rem(8) 0;
}

.dynamic_success {
    display: none;
}

.dynamic_error {
    display: none;
}

.form form {
    padding: rem(10);
}

.form .txt {
    margin-bottom: rem(29);
}

.form p.error {
    margin: rem(16) 0;
}

.form p.error label {
    display: inline-block;
    width: rem(133);
    text-align: right;
    margin-right: rem(10);
}

.form p.success {
    margin: rem(16) 0;
}

.form legend {
    border-bottom: rem(1) solid $c-divider-default;
    margin-bottom: rem(12);
    padding-bottom: rem(2);
    width: 100%;
}

.form .form_item {
    padding: 0 0 rem(11) 0;
}

.form .form_item.last {
    padding: 0;
}

.form .form_item h4 {
    border-style: solid;
    border-width: 0 0 rem(1);
    padding: rem(10) 0 rem(2) 0;
    width: 100%;
}

.form .form_label {
    float: left;
    text-align: right;
    width: rem(140);
}

.form .form_label label {
    font-weight: normal;
}

.form .form_input {
    float: left;
    margin-left: rem(10);
    text-align: left;
}

.form .form_input input.i, .form_input input.ui-datepicker {
    width: rem(293);
}

.form .form_input input[type="radio"],
.form .form_input input[type="checkbox"],
.form .form_input label {
    vertical-align: middle;
}

.form .form_input textarea {
    height: rem(150);
}

.form .captcha_wrapper {
    padding: 0 0 rem(11) 0;
}

.form .form_agbCheck a {
    text-decoration: underline;
}

.form .formButtons {
    margin-top: rem(5);
}

.form .formButtons .form_label {
    padding-top: rem(17);
}

.form .formButtons .form_input {
    border-top: rem(1) solid $c-divider-default;
    padding-top: rem(10);
}

.form .formButtons .requiredText {
    text-align: left;
}

.form .formButtons #submitFormBtn {
    float: right;
}

.form .formButtons #resetFormBtn {
    float: none;
}

.actionbar .button:not(:last-child) {
    @media (max-width: $break-medium-minus-one) {
        margin-bottom: rem(10);
    }
}
