.slideshow {
    @include stretchToMobileViewport();
    clear: both;
    margin-bottom: rem(18);
    padding: rem(10) 0;
    position: relative;

    img {
        display: block;
    }
}

.slideshow .head {
    padding: 0 $d-content-padding;

    @media (min-width: $break-medium) {
        padding: 0;
    }
}

.slideshow .caption {
    background-color: $c-background-grey;
    padding: $d-box-padding;
}

.slideshow .control {
    display: none;
    float: right;
    height: rem(25);
}

.slideshow .control .prev {
    color: $c-background-brand;
    cursor: pointer;
    display: block;
    float: left;
    text-align: center;
    height: rem(25);
    width: rem(25);

    &:before {
        @include icon-styles();
        content: icon-char("angle-left");
        color: $c-text-white;
        line-height: rem(25);
    }
}

.slideshow .control .next {
    color: $c-background-brand;
    cursor: pointer;
    display: block;
    float: left;
    text-align: center;
    height: rem(25);
    width: rem(25);

    &:before {
        @include icon-styles();
        content: icon-char("angle-right");
        color: $c-text-white;
        line-height: rem(25);
    }
}

.slideshow .control .counter {
    float: left;
    margin: 0 rem(9);
}

.slideshow .control_bottom {
    bottom: rem(10);
    position: absolute;
    right: rem(10);
}

.slideshow .wrapper {
    overflow: hidden;
}

.slideshow .list {
    margin-left: rem(-5);
    width: rem(9999);
}

.slideshow .list li {
    float: left;
    margin: 0 rem(5);

    @media (min-width: $break-medium) {
        width: rem(450);
    }
}

.slideshow .list.ads li {
    padding-top: rem(14);
}

.slideshow .list.ads li.ad {
    position: relative;

    &:before {
        position: absolute;
        right: 0;
    }
}

.slideshow .hl {
    margin: rem(5) 0 rem(5) 0;
}

.slideshow .pflichttext {
    margin: 0;
    font-style: italic;
}

.slideshow .rights {
    margin: 0;
}
