.mod_forum_create .line {
    margin: rem(7) 0 rem(7) 0;
}

.mod_forum_create .line .left {
    margin-right: rem(10);
    width: rem(120);
}

.mod_forum_create .line .right {
    max-width: rem(340);
}

.mod_forum_create .line .right input[type="checkbox"],
.mod_forum_create .line .right input[type="radio"] {
    vertical-align: middle;
}

.mod_forum_create .line .right label {
    margin-left: rem(3);
    margin-right: rem(6);
    vertical-align: middle;
}
