.mod_index .media .img {
    margin-bottom: rem(16);
    width: 100%;
}

.mod_index .hl {
    border-bottom: none;
    margin-bottom: rem(3);
}

.mod_index .left {
    float: left;
    width: 100%;
}

.mod_index .right {
    float: right;
    width: 100%;
}

.mod_index .hide {
    display: none;
}

.mod_index .bd {
    clear: both;
    display: block;
    margin-bottom: rem(23);
}

.mod_index .bd .ilist li {
    border-bottom: rem(1) solid $c-divider-default;
    padding: rem(10) 0;
}

.mod_index .bd .ilist li a {
    display: block;
    font-size: rem(16);
    font-weight: bold;
    line-height: rem(20);
    position: relative;
    padding-right: rem(30);

    &:after {
        @include icon-styles();
        content: icon-char("arrow-right");
        color: $c-text-brand;
        position: absolute;
        right: 0;
        top: 0;
    }
}

.mod_index .bd .ilist li a.ad {
    // Arrow and ad label
    padding-right: rem(80);
    position: relative;

    &:before {
        position: absolute;
        right: rem(24);
    }
}

.mod_index .bd .ilist li li {
    border: none;
}

.mod_index .bd_more {
    margin-bottom: 0;
}

.mod_index .ft {
    margin-top: rem(10);
    text-align: right;
}

.content .mod_index .bd .left {
    @media (min-width: $break-medium) {
        float: left;
        padding-right: rem(10);
        width: 50%;
    }
}

.content .mod_index .bd .right {
    @media (min-width: $break-medium) {
        float: right;
        padding-left: rem(10);
        width: 50%;
    }
}

.aside .mod_index .media img {
    display: none;
}
