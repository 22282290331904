#promillerechner {
    padding: rem(10);
}

#promillerechner form {
    margin: 0 rem(-10);
    padding: 0 rem(10) rem(10) rem(10);
}

#promillerechner .tabelle1 {
    @media (min-width: $break-medium) {
        width: rem(470);
    }
}

#promillerechner .td1 {
    width: 66%;
}

#promillerechner .td2 {
    width: 34%;
}

#promillerechner .progress1 {
    width: rem(125);
}
