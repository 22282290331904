.teaserImageContainer {
    color: $c-text-white;
    display: block;
    position: relative;

    .teaserImageOverlay {
        bottom: 0;
        display: block;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
    }

    .teaserImageOverlay {
        background: rgba(58, 56, 66, 0.5);
        border-radius: rem(5);
        display: flex;
        align-items: center;
        height: rem(44);
        justify-content: center;
        width: rem(200);
    }

    .teaserImageOverlay--small {
        height: rem(36);
        width: rem(36);
    }

    .teaserImageOverlay_block {
        display: inline-block;
    }

    .teaserImageOverlay_block:after {
        display: block;
    }

    .teaserImageOverlay_block--icon:after {
        @include icon-styles();
        font-size: rem(19);
        line-height: rem(36);
    }

    .teaserImageOverlay_block--text {
        padding: 0 rem(12);
    }

    .teaserImageOverlay_block--arrow:after {
        @include icon-styles();
        content: icon-char("angle-right");
    }
}

.teaserImageContainer--gallery .teaserImageOverlay_block--icon:after {
    content: icon-char("camera");
}

.teaserImageContainer--gallery .teaserImageOverlay_block--text:after {
    content: "Bildergalerie";
}

.teaserImageContainer--media .teaserImageOverlay_block--icon:after {
    content: icon-char("play");
}

.teaserImageContainer--media .teaserImageOverlay_block--text:after {
    content: "Video";
}

.teaserImageContainer--selftest .teaserImageOverlay_block--icon:after {
    content: icon-char("selftest");
}

.teaserImageContainer--selftest .teaserImageOverlay_block--text:after {
    content: "Selbsttest";
}
