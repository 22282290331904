a.button,
.button {
    appearance: none;
    background: $c-background-brand none;
    border-radius: rem(5);
    border: none;
    color: $c-text-white;
    display: inline-block;
    font-size: rem(16);
    font-weight: bold;
    letter-spacing: rem(.4);
    line-height: rem(20);
    padding: rem(8) rem(22);
    position: relative;
    transition: background-color .5s ease;
    text-transform: uppercase;
    text-decoration: none;
    vertical-align: top;

    &:hover {
        background-color: $c-background-brand-dark;
        color: $c-text-white;
    }

    &--inverted {
        background-color: $c-background-white;
        color: $c-text-brand;

        &:hover {
            color: $c-background-brand;
            background-color: $c-background-white;
        }
    }

    &--wide {
        float: none;
        text-align: center;
        width: 100%;
    }

    &--small {
        font-size: rem(12);
        padding: rem(4) rem(11);
    }

    &--facebook {
        padding: 0;
        background-color: transparent;

        &:hover {
            background-color: transparent;
        }

        &:before {
            @include icon-styles();
            content: icon-char("facebook-circle");
            font-size: rem(36);
            height: rem(36);
            line-height: rem(36);
            width: rem(36);
        }
    }

    &--facebook-text {
        background-color: #3b5898;
        font-size: rem(19);
        padding-left: rem(6);
        padding-right: 0;
        text-transform: none;
        font-weight: normal;
        width: 100%;

        @media (min-width: $break-medium) {
            text-align: left;
            font-size: rem(14);
        }

        &:hover {
            background-color: #3b5898;
        }

        &:before {
            @include icon-styles();
            content: icon-char("facebook-circle");
            height: rem(24);
            line-height: rem(24);
            padding-left: rem(6);
            padding-right: rem(6);
            vertical-align: middle;
            width: rem(24);

            @media (min-width: $break-medium) {
                font-size: rem(22);
            }
        }
    }

    &--flipboard {
        padding: 0;
        background-color: transparent;

        &:hover {
            background-color: transparent;
        }

        &:before {
            @include icon-styles();
            content: icon-char("flipboard-square");
            font-size: rem(36);
            height: rem(36);
            line-height: rem(36);
            width: rem(36);
        }
    }

    &--instagram {
        padding: 0;
        background-color: transparent;

        &:hover {
            background-color: transparent;
        }

        &:before {
            @include icon-styles();
            content: icon-char("instagram");
            font-size: rem(36);
            height: rem(36);
            line-height: rem(36);
            width: rem(36);
        }
    }

    &--pinterest {
        padding: 0;
        background-color: transparent;

        &:hover {
            background-color: transparent;
        }

        &:before {
            @include icon-styles();
            content: icon-char("pinterest-circle");
            font-size: rem(36);
            height: rem(36);
            line-height: rem(36);
            width: rem(36);
        }
    }

    &--twitter {
        padding: 0;
        background-color: transparent;

        &:hover {
            background-color: transparent;
        }

        &:before {
            @include icon-styles();
            content: icon-char("twitter-circle");
            font-size: rem(36);
            height: rem(36);
            line-height: rem(36);
            width: rem(36);
        }
    }
}

button.moreBtn,
a.moreBtn,
.moreBtn {
    background: none;
    border: none;
    color: $c-text-brand;
    clear: both;
    display: block;
    font-weight: normal;
    font-size: rem(14);
    line-height: rem(18);
    text-align: right;

    &:after {
        @include icon-styles();
        content: icon-char("arrow-right");
        font-size: rem(14);
        padding-left: rem(12);
    }

    &--inverted {
        color: $c-text-white;
    }

    &--left {
        text-align: left;
    }
}
