.rss {
    margin-bottom: $d-space-after-element;

    h2,
    h3 {
        margin-bottom: rem(8);
    }

    p {
        margin-bottom: rem(10);
    }

    .ilist {
        margin-bottom: rem(10);
    }

    .ilist a {
        font-size: rem(16);
        font-weight: bold;
    }

    .ilist li {
        border-bottom: rem(1) solid $c-divider-default;
        padding: rem(10) rem(40) rem(10) 0;
        position: relative;
    }

    .ilist .icon {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='currentColor' d='M128.081 415.959c0 35.369-28.672 64.041-64.041 64.041S0 451.328 0 415.959s28.672-64.041 64.041-64.041 64.04 28.673 64.04 64.041zm175.66 47.25c-8.354-154.6-132.185-278.587-286.95-286.95C7.656 175.765 0 183.105 0 192.253v48.069c0 8.415 6.49 15.472 14.887 16.018 111.832 7.284 201.473 96.702 208.772 208.772.547 8.397 7.604 14.887 16.018 14.887h48.069c9.149.001 16.489-7.655 15.995-16.79zm144.249.288C439.596 229.677 251.465 40.445 16.503 32.01 7.473 31.686 0 38.981 0 48.016v48.068c0 8.625 6.835 15.645 15.453 15.999 191.179 7.839 344.627 161.316 352.465 352.465.353 8.618 7.373 15.453 15.999 15.453h48.068c9.034-.001 16.329-7.474 16.005-16.504z'%3E%3C/path%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: auto rem(16);
        cursor: pointer;
        display: block;
        height: rem(16);
        position: absolute;
        right: rem(9);
        top: rem(11);
        width: rem(16);
    }

    .abstract {
        margin-bottom: rem(10);
        padding: 0;
    }
}
