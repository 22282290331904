input[type=checkbox] {
    appearance: none;
    display: inline-block;
    position: relative;
    border: none;
    padding: 0;
    height: rem(32);
    vertical-align: middle;
    width: rem(32);

    @media (min-width: $break-medium) {
        height: rem(19);
        width: rem(19);
    }

    &::after {
        content: "";
        height: rem(32);
        background-color: $c-background-white;
        border: rem(1) solid $c-divider-default;
        box-sizing: border-box;
        display: block;
        margin: 0;
        position: relative;
        width: rem(32);
        z-index: 1;

        @media (min-width: $break-medium) {
            height: rem(19);
            width: rem(19);
        }
    }

    &:checked {
        background: transparent;
    }

    &:checked::after {
        border-color: $c-divider-default;
        top: rem(-9);
    }

    &:checked::before {
        @include icon-styles();
        content: icon-char("check");
        color: $c-text-brand;
        display: block;
        font-size: rem(16);
        height: rem(9);
        left: rem(8);
        margin: 0;
        position: relative;
        text-indent: rem(-1);
        top: rem(8);
        width: rem(9);
        z-index: 2;

        @media (min-width: $break-medium) {
            font-size: rem(10);
            left: rem(5);
            top: rem(4);
        }
    }
}

input[type=checkbox] + label {
    padding-left: rem(6);
}
