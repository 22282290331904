.mod_usermenu {
    display: none;
    background: $c-background-brand;

    @media (min-width: $break-medium) {
        display: block;
    }

    .content-triple & {
        @media (min-width: $break-content) {
            margin-bottom: 0;
        }
    }

    &-wrapper {
        /*background: $c-background-brand;*/

        .content-triple & {
            min-height: 100%;

            @media (min-width: $break-content) {
                margin-bottom: 0;
            }
        }
    }

    a:not(.button) {
        color: $c-text-white;
    }

    .inner {
        padding: rem(20) $d-box-padding rem(20);
    }

    &.closed .inner {
        min-height: rem(51);

        @media (min-width: $break-content) {
            max-height: rem(140);
        }
    }

    &.open .inner {
        /*min-height: rem(153);*/
    }

    .hd {
        margin-bottom: rem(32);
    }

    .logout {
        float: right;
        text-transform: none;

        @media (min-width: $break-content) {
            font-size: rem(12);
        }

        &:after {
            @include icon-styles();
            content: icon-char("logout");
            padding-left: rem(6);
            vertical-align: middle;
        }
    }

    .bd .hl {
        color: $c-text-white;
        font-weight: normal;
        font-size: rem(14);
        margin-bottom: rem(20);
    }

    .hl a:hover {
        color: $c-text-white;
        text-decoration: none;
    }

    .toggleBtn {
        float: right;
        height: rem(18);
        width: rem(18);

        &:before {
            @include icon-styles();
            content: icon-char("angle-down");
            font-size: rem(10);
        }

        &.close:before {
            content: icon-char("angle-up");
        }
    }

    .left,
    .right {
        float: none;
        padding: rem(3) 0;
        width: auto;
    }

    .login,
    .register {
        margin: rem(10) 0 0 0;
        text-align: left;
    }

    .login .text,
    .register .text {
        border-bottom: rem(1) solid $c-divider-default;
        margin: 0 0 rem(8) 0;
        padding: 0 0 rem(5) 0;
    }

    .login .submit,
    .register .submit {
        margin: 0 auto rem(5) auto;
        text-decoration: none;
    }

    .login .submit {
        height: rem(25);
        width: rem(133);
    }

    .register .submit {
        height: rem(25);
        width: rem(171);
    }

    .button {
        font-size: 0.8rem;
        margin-bottom: rem(10);
        padding: 0.375rem 0.75rem;
    }

    .btnLogin {
        margin-bottom: 0;
    }

    .btnRegister {
        margin-bottom: 0;
        margin-left: rem(10);
    }

    .button--social {
        margin: 0;
        padding: 0;

        &:before {
            display: block;
        }
    }

    &--social {
        margin-top: rem(20);
    }

    &--social .inner {
        display: flex;
        justify-content: space-between;
    }
}

.mod_usermenu--overlay {
    display: none;
    height: 100vh;
    left: 0;
    margin-top: rem(22);
    overflow-y: auto;
    padding-bottom: rem(22);
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: $z-layer;

    &.open {
        display: block;
    }

    .hd {
        display: none;
    }

    .usermenuContent {
        // Overwrite inline style
        display: block !important;
    }

    .toggleBtn {
        // Overwrite inline style
        display: none !important;
    }

    .inner {
        padding: rem(28) $d-content-padding rem(28);
    }

    .left,
    .right {
        border-bottom: rem(1) solid $c-divider-white;
        float: none;
        padding: rem(10) 0;
        position: relative;
        width: auto;

        &:before {
            @include icon-styles();
            content: icon-char("arrow-right");
            color: $c-text-white;
            position: absolute;
            pointer-events: none;
            right: 0;
            top: rem(10);
        }
    }

    .line a {
        display: block;
        font-weight: bold;
    }

    .logout {
        float: right;
        text-transform: none;

        @media (max-width: $break-content-minus-one) and (min-height: rem(500)) {
            bottom: rem(46);
            font-size: rem(16);
            right: rem(24);
            position: absolute;
        }

        &:after {
            @include icon-styles();
            content: icon-char("logout");
            font-size: rem(27);
            padding-left: rem(10);
            vertical-align: middle;
        }
    }
}
