.mod_eyecatcher {
	.bd {
		position: relative;
	}

	.media {
		padding: 0;
	}

	.img {
		display: block;
		margin-bottom: rem(20);
		width: 100%;

		@media (min-width: $break-medium) {
			margin: 0 rem(30) rem(20) 0;
			width: rem(140);
		}

		.aside & {
			float: none;
			width: 100%;
		}
	}

	.txt {
		margin-bottom: rem(7);
	}

	.kicker {
		margin: 0 0 rem(3) 0;
	}

	.hl {
		margin-bottom: rem(10);
	}

	.mainBtn {
		float: right;
		margin: rem(10) 0 0 0;
	}

	.moreBtn {
		.aside & {
			@media (min-width: $break-medium) {
				text-align: left;
			}
		}
	}

	.moreLink {
		background: $c-background-white;
		display: block;
		padding: rem(2) rem(5) rem(4) rem(5);
		position: absolute;
		right: rem(4);
		text-transform: uppercase;
		top: rem(4);
	}

	.mod_teaser & {
		margin-bottom: rem(13);
		margin-top: rem(13);
	}

	.mod_clip & {
		margin-bottom: rem(23);
		margin-top: rem(23);
	}

	.mod_search_result & {
		background: $c-background-grey;
		margin: rem(-11) 0 rem(-15) 0;
		padding: rem(12) rem(12) rem(8) rem(12);
	}
}

.mod_eyecatcher--inverse {
	background-color: $c-brand-default;
	color: $c-text-white;

	a, .media .hl a, .kicker {
		color: $c-text-white;
	}

	.img {
		@media (min-width: $break-medium) {
			margin: rem(20) rem(0) rem(20) rem(20);
		}

		.aside & {
			margin: 0;
		}
	}

	.bd {
		padding: rem(20);
	}

	.moreLink {
		background: transparent;
	}

	.mod_search_result & {
		background: transparent;
	}
}
