$layer-padding: 1.25rem;
$layer-padding-negative: -1.25rem;

.layer {
    background: rgba(0, 0, 0, .5);
    display: none;
    height: 100%;
    left: 0;
    min-width: $d-content-min-width;
    position: fixed;
    top: $d-mobile-header-height;
    width: 100%;
    z-index: $z-layer;

    @media (min-width: $break-medium) {
        top: 0;
        position: absolute;
    }
}

.layer legend {
    font-weight: bold;
}

.layer2 {
    z-index: $z-layer + 1;
}

.layer .layer_inner {
    background-color: $c-background-grey;
    bottom: 0;
    cursor: default;
    margin: 0 auto;
    min-width: 100%;
    max-width: $d-content-width;
    padding: $layer-padding $d-content-padding;
    position: fixed;
    overflow-y: auto;
    left: 0;
    right: 0;
    top: $d-mobile-header-height;
    visibility: hidden;

    @media (max-width: $break-medium-minus-one) {
        // Ignore box position
        margin-top: 0 !important;
    }

    @media (min-width: $break-medium) {
        height: auto;
        left: auto;
        min-width: auto;
        right: auto;
        padding: $layer-padding;
        position: relative;
        top: auto;
    }
}

.layer .close {
    text-indent: 0;

    &:before {
        @include icon-styles();
        content: icon-char("times");
        color: $c-text-brand;
        cursor: pointer;
        font-size: rem(20);
        position: absolute;
        right: $d-content-padding;
        top: rem(16);

        @media (min-width: $break-medium) {
            right: rem(16);
        }
    }
}

.layer .qq-upload-list {
    margin: 0;
}

.layer .qq-upload-drop-area {
    background-color: $c-background-white;
    // overwrite inline style
    display: block !important;
    height: rem(40);
    min-height: auto;
}

.layer .qq-upload-button {
    border-bottom: none;
    color: inherit;
}

.layer .qq-upload-button .sepText {
    font-weight: normal;
    padding: rem(10) 0;
}

.layer .layer_login {
    background-color: $c-background-grey;
    max-width: rem(535);
}

.layer .layer_login fieldset {
    padding: rem(13) 0 0 0;
}

.layer .layer_login legend {
    border-bottom: rem(3) solid $c-divider-default;
    padding-bottom: rem(4);
    width: 100%;
}

.layer .layer_login label {
    display: block;
    margin-bottom: rem(3);
}

.layer .layer_login input[type=text],
.layer .layer_login input[type=password] {
    margin-bottom: rem(9);
}

.layer .layer_login p.error.red {
    padding-bottom: rem(16);
    display: none;
}

.layer .layer_login .left {
    @media (min-width: $break-medium) {
        border-right: rem(1) solid $c-divider-default;
        float: left;
        padding: 0 rem(22) rem(10) 0;
        width: 50%;
    }
}

.layer .layer_login .right {
    @media (min-width: $break-medium) {
        float: right;
        padding: 0 0 rem(10) rem(22);
        width: 50%;
    }
}

.layer .layer_login .right .txt {
    margin-bottom: rem(10);
}

.layer .layer_login .forgot {
    margin: rem(20) 0;

    @media (min-width: $break-medium) {
        margin: rem(13) 0 0;
    }

    a {
        color: $c-text-grey;
        font-style: italic;
        font-size: rem(11);
    }
}

.layer .layer_login .saveLogin {
    margin: 0 0 rem(13);
}

.layer .layer_login .saveLogin input {
    vertical-align: middle;
}

.layer .layer_login .saveLogin label {
    display: inline;
    margin-left: rem(5);
    vertical-align: middle;
}

.layer .layer_login .register_hl {
    margin: rem(15) 0 rem(15) 0;
}

.layer .layer_login .register .button {
    margin-top: rem(10);
}

.layer .layer_login .right .register {
    padding: 0 0 rem(10) 0;
}

.layer .layer_login .right .register a {
    float: none;
    margin-top: rem(10);
}

.layer_login--footer {
    background-color: $c-background-brand;
    padding: $layer-padding;
    margin: $layer-padding $layer-padding-negative $layer-padding-negative $layer-padding-negative;
}

.layer_login--footer .moreBtn:after {
    float: right;

    @media (min-width: $break-medium) {
        float: none;
    }
}

.layer .layer_newsletter {
    max-width: rem(460);
}

.layer .layer_newsletter legend {
    border-bottom: rem(1) solid $c-divider-default;
    margin-bottom: rem(10);
    padding-bottom: rem(5);
    width: 100%;
}

.layer .layer_recommend_article {
    max-width: rem(460);
}

.layer .layer_recommend_article label {
    display: block;
    margin-bottom: rem(3);
}

.layer .layer_recommend_article label {
    margin-top: rem(3);
}

.layer .layer_recommend_article p.error label {
    display: inline-block;
    width: rem(133);
}

.layer .layer_recommend_article input[type=text],
.layer .layer_recommend_article input[type=password] {
    margin-bottom: rem(9);
}

.layer .layer_recommend_article input[type=submit] {
    float: right;
}

.layer .layer_recommend_article textarea {
    margin-bottom: rem(16);
}

.layer .layer_recommend_article .left {
    float: left;
    padding: 0 rem(35) rem(10) 0;
    width: 45%;
}

.layer .layer_recommend_article .right {
    float: right;
    width: 55%;
}

.layer .layer_recommend_article p.mandatories {
    float: left;
}

.layer .layer_recommend_article br.clear {
    clear: both;
}

.layer .layer_profile_image_reset_upload {
    max-width: rem(400);
}

.layer .layer_profile_image_reset_upload p {
    margin: 0 0 rem(32) 0;
}

.layer .layer_profile_image_reset_upload .submit {
    float: left;
    margin-bottom: rem(3);
}

.layer .layer_profile_image_reset_upload fieldset {
    padding: rem(13) 0 rem(22);
}

.layer .layer_profile_image_reset_upload legend {
    border-bottom: rem(1) solid $c-divider-default;
    padding-bottom: rem(4);
    width: 100%;
}

.layer .layer_profile_image_reset_upload input[type=submit] {
    margin-right: rem(118);
}

.layer .layer_profile_image_reset_upload p.error,
.layer .layer_profile_image_upload p.success {
    display: inline-block;
    width: auto;
}

.layer .layer_profile_image_upload {
    max-width: rem(600);
}

.layer .layer_profile_image_upload fieldset {
    padding: rem(13) 0 rem(22);
}

.layer .layer_profile_image_upload legend {
    border-bottom: rem(1) solid $c-divider-default;
    padding-bottom: rem(4);
    width: 100%;
}

.layer .layer_profile_image_upload input[type=submit] {
    float: right;
    margin-bottom: rem(3);
}

.layer .layer_profile_image_upload p.error,
.layer .layer_profile_image_upload p.success {
    width: auto;
}

body.layer_inner.iframe fieldset {
    padding: rem(13) 0 rem(22);
}

body.layer_inner.iframe legend {
    border-bottom: rem(1) solid $c-divider-default;
    padding-bottom: rem(4);
    width: 100%;
}

body.layer_inner.iframe input[type=submit] {
    float: right;
    margin-bottom: rem(3);
}

body.layer_inner.iframe p.error,
body.layer_inner.iframe p.success {
    width: auto;
}

.layer .layer_img {
    padding: rem(16) rem(14) rem(15);
}

.layer .layer_img .hl {
    margin-bottom: rem(12);
}

.layer .layer_img .img {
    margin-bottom: rem(11);
}

.layer .layer_img .txt {
    margin-bottom: rem(5);
}

.layer .layer_gallery_img_edit {
    max-width: rem(720);
}

.layer .layer_gallery_img_edit .img {
    margin: 0 0 rem(20) 0;
    text-align: center;
    width: 100%;
}

.layer .layer_gallery_img_edit .img img {
    max-height: rem(720);
    max-width: 100%;
}

.layer .layer_gallery_img_edit form {
    background: none;
}

.layer .layer_gallery_img_edit .line {
    margin: 0 rem(10) rem(10) rem(10);
    vertical-align: middle;
}

.layer .layer_gallery_img_edit .line .left {
    @media (min-width: $break-medium) {
        padding-right: rem(10);
        width: 33%;
    }
}

.layer .layer_gallery_img_edit .line .right {
    @media (min-width: $break-medium) {
        width: 67%;
    }
}

.layer .layer_gallery_img_edit .line .right .radioLabel {
    margin-left: rem(3);
    margin-right: rem(10);
}

.layer .layer_gallery_img_edit .line .right input.hasDatepicker {
    width: rem(72);
}

.layer .layer_gallery_img_edit .actionbar .submit {
    float: left;
}

.layer .layer_gallery_img_edit .control {
    float: right;
    height: rem(29);
}

.layer .control .prev {
    float: left;

    &:before {
        @include icon-styles();
        content: icon-char("angle-left");
        color: $c-text-brand;
        cursor: pointer;
        display: block;
    }

    &:hover {
        text-decoration: none;
    }
}

.layer .control .next {
    float: right;

    &:before {
        @include icon-styles();
        content: icon-char("angle-right");
        color: $c-text-brand;
        cursor: pointer;
        display: block;
    }

    &:hover {
        text-decoration: none;
    }
}

.layer .layer_gallery_img_edit .control .counter {
    float: left;
    margin: 0 rem(9);
}

.layer .layer_gallery_img_view {
    max-width: rem(720);
}

.layer .layer_gallery_img_view .img {
    margin: 0 0 rem(20) 0;
    text-align: center;
    width: 100%;
}

.layer .layer_gallery_img_view .img img {
    max-height: rem(720);
    max-width: 100%;
}

.layer .layer_gallery_img_view .imgInfo .imgTitle {
    font-weight: bold;
    margin: 0 0 rem(10) 0;
}

.layer .layer_gallery_img_view .imgInfo .imgDescr {
    margin: 0 0 rem(10) 0;
}

.layer .layer_gallery_img_view .imgInfo .userInfo {
    font-style: italic;
    margin: 0 0 rem(10) 0;
}

.layer .layer_gallery_img_view .imgInfo .colLabel1 {
    text-align: left;
    padding: 0 rem(5);
    vertical-align: top;
    width: rem(75);
}

.layer .layer_gallery_img_view .imgInfo .colValue1 {
    text-align: left;
    padding: 0 rem(5);
    vertical-align: top;
    width: rem(250);
}

.layer .layer_gallery_img_view .imgInfo .colSep {
    text-align: center;
    padding: 0;
    vertical-align: top;
    width: rem(5);
}

.layer .layer_gallery_img_view .imgInfo .colLabel2 {
    text-align: left;
    padding: 0 rem(5);
    vertical-align: top;
    width: rem(95);
}

.layer .layer_gallery_img_view .imgInfo .colValue2 {
    text-align: left;
    padding: 0 rem(5);
    vertical-align: top;
    width: rem(60);
}

.layer .layer_gallery_img_view .imgInfo .colSep .sep {
    background: #9a9a9a;
    height: rem(55);
    width: rem(1);
}

.layer .layer_gallery_img_view .control {
    float: right;
    height: rem(29);
}

.layer .layer_gallery_img_view .control .counter {
    float: left;
    margin: 0 rem(9);
}

.layer .layer_gallery_img_select {
    max-width: rem(720);
}

.layer .layer_gallery_img_select h2 {
    margin-bottom: rem(12);
}

.layer .layer_gallery_img_select p {
    margin: 0 0 rem(10) 0;
}

.layer .layer_gallery_img_select .galleries {
    margin: 0 0 rem(15) 0;
}

.layer .layer_gallery_img_select .galleries .galleryList {
    margin-bottom: rem(16);
}

.layer .layer_gallery_img_select .galleries .control {
    float: left;
    height: rem(20);
}

.layer .layer_gallery_img_select .galleries .control .counter {
    float: left;
    margin: 0 rem(6);
}

.layer .layer_gallery_img_select .gallery {
    border-top: rem(1) solid $c-divider-default;
    margin: 0 0 rem(15) 0;
    padding: rem(15) 0 0 0;
}

.layer .layer_gallery_img_select .gallery .images {
    margin: 0;
    overflow: auto;

    @media (min-width: $break-medium) {
        height: rem(426);
    }
}

.layer .layer_gallery_img_select .gallery .images .image {
    float: left;
    margin: 0 0 rem(20) rem(20);
    text-align: center;
    width: rem(122);
}

.layer .layer_gallery_img_select .gallery .images .image.first {
    margin-left: rem(5);
}

.layer .layer_gallery_img_select .gallery .images .image .img {
    border: rem(1) solid $c-divider-default;
    height: rem(120);
    width: rem(120);
}

.layer .layer_gallery_img_select .gallery .images .image .img img {
    max-height: 100%;
    max-width: 100%;
}

.layer .layer_image_upload {
    max-width: rem(600);
}

.layer .layer_image_upload fieldset {
    padding: rem(13) 0 rem(22);
}

.layer .layer_image_upload legend {
    border-bottom: rem(1) solid $c-divider-default;
    padding-bottom: rem(4);
    width: 100%;
}

.layer .layer_image_upload input[type=submit] {
    float: right;
    margin-bottom: rem(3);
}

.layer .layer_image_upload p.error,
.layer .layer_profile_image_upload p.success {
    width: auto;
}

.layer .layer_message_new {
    max-width: rem(470);
}

.layer .layer_message_new form {
    background: none;
}

.layer .layer_message_new .line {
    margin-bottom: rem(10);
}

.layer .layer_message_new .actionbar .submit {
    float: right;
}

.layer .layer_message_new .friendship .uicon {
    float: left;
    height: rem(53);
    margin: 0 0 rem(10) 0;
    width: rem(53);
}

.layer .layer_message_new .friendship .uinfo {
    float: left;
    margin: 0 0 rem(10) rem(10);
    overflow: hidden;
    width: rem(150);
}

.layer .layer_message_new .friendship textarea {
    margin-bottom: rem(10);
}

.layer .layer_message_conversation {
    max-width: rem(470);
}

.layer .layer_message_conversation .messages {
    max-height: rem(400);
    overflow: auto;
}

.layer .layer_message_conversation .message {
    border-bottom: rem(1) solid $c-divider-default;
    padding: rem(10) rem(10);
}

.layer .layer_message_conversation .uinfo {
    float: left;
    margin: 0 rem(15) 0 0;
    overflow: hidden;
    width: rem(60);
}

.layer .layer_message_conversation .uicon {
    height: rem(53);
    margin-bottom: rem(5);
    width: rem(53);
}

.layer .layer_message_conversation .uinfo .line {
    margin: rem(5) 0 0 0;
    text-align: center;
}

.layer .layer_message_conversation .msg_content {
    margin: 0;
    overflow: hidden;
}

.layer .layer_message_conversation .msg_content .meta {
    float: right;
}

.layer .layer_message_conversation .msg_content .h2 {
    margin: 0 0 rem(10) 0;
}

.layer .layer_message_conversation form {
    background: none;
    margin: rem(10) 0 0 0;
}

.layer .layer_message_conversation form .line {
    margin-bottom: rem(10);
    vertical-align: middle;
}

.layer .layer_message_conversation form .actionbar .submit {
    float: right;
}

.layer .layer_basal_view .img {
    margin: 0 0 rem(10) 0;
}

.layer .layer_basal_view h3 {
    margin: rem(15) 0 rem(5) 0;
}

.layer .layer_basal_view .comments {
    margin-bottom: rem(10);
}

.layer .layer_basal_view .comments li {
    margin: rem(3) 0;
}

.layer .layer_basal_edit {
    max-width: rem(470);
}

.layer .layer_basal_edit form {
    background: none;
}

.layer .layer_basal_edit form fieldset {
    border-top: rem(1) solid $c-divider-default;
}

.layer .layer_basal_edit form fieldset.first {
    border-top: 0;
}

.layer .layer_basal_edit .line {
    margin: rem(7) 0 rem(7) 0;
}

.layer .layer_basal_edit .left {
    @media (min-width: $break-medium) {
        padding-right: rem(10);
        width: 33%;
    }
}

.layer .layer_basal_edit .left label {
    display: block;
    font-weight: bold;
    margin: rem(10) 0;

    @media (min-width: $break-medium) {
        margin-top: 0;
    }
}

.layer .layer_basal_edit .required-description {
    display: inline-block;
    margin: 0 rem(10) rem(10) 0;
}

.layer .layer_basal_edit .right {
    @media (min-width: $break-medium) {
        width: 66%;
    }
}

.layer .layer_basal_edit .right input,
.layer .layer_basal_edit .right label {
    display: inline-block;
    margin-bottom: rem(5);
}

.layer .layer_basal_edit small {
    display: block;
    margin-bottom: rem(5);
}

.layer .layer_basal_edit input[type=checkbox] ~ small {
    margin-left: rem(44);

    @media (min-width: $break-medium) {
        margin-left: rem(31);
    }
}

.layer .layer_basal_edit .line .right input[type="text"] {
    width: auto;
}

.layer .layer_basal_edit .line .right label,
.layer .layer_basal_edit .line .right input[type="checkbox"],
.layer .layer_basal_edit .line .right input[type="radio"] {
    vertical-align: middle;
}

.layer .layer_basal_edit .line .right .radioSpan {
    white-space: nowrap;
}

.layer .layer_basal_edit .line .right .radioLabel {
    margin-left: rem(3);
    margin-right: rem(10);
}

.layer .layer_basal_edit .line .right .radioLabel.last {
    margin-right: 0;
}

.layer .layer_basal_edit .line .right .checkboxLine label {
    margin-left: rem(2);
}

.layer .layer_basal_edit .line .right .hint {
    margin-left: rem(5);
}

.layer .layer_basal_edit .line .right #basal_ignoreTemp {
    margin-left: 0;
}

.layer .layer_basal_edit .actionbar {
    border-top: rem(1) solid $c-divider-default;
    padding: rem(12) rem(14) rem(10) 0;
}

.layer .layer_basal_edit .actionbar .submit {
    float: right;
}

.layer .layer_basal_edit textarea {
    height: rem(100);

    @media (min-width: $break-medium) {
        height: rem(200);
    }
}

.layer .layer_hibbel_edit {
    max-width: rem(470);
}

.layer .layer_hibbel_edit form {
    background: none;
}

.layer .layer_hibbel_edit fieldset {
    border-bottom: rem(1) solid $c-divider-default;
    padding: 0 0 rem(10) 0;
    margin: 0 0 rem(10) 0;
}

.layer .layer_hibbel_edit fieldset.last {
    border-bottom: none;
}

.layer .layer_hibbel_edit .line {
    margin: 0 0 rem(7) 0;
}

.layer .layer_hibbel_edit .line .left {
    margin-bottom: rem(10);

    @media (min-width: $break-medium) {
        margin-bottom: 0;
        padding-right: rem(10);
        width: 50%;
    }
}

.layer .layer_hibbel_edit .line .right {
    @media (min-width: $break-medium) {
        padding-left: rem(10);
        width: 50%;
    }
}

.layer .layer_hibbel_edit .line input[type="text"] {
    width: auto;
}

.layer .layer_hibbel_edit .line label,
.layer .layer_hibbel_edit .line input[type="checkbox"],
.layer .layer_hibbel_edit .line input[type="radio"] {
    vertical-align: middle;
}

.layer .layer_hibbel_edit .line input[type="radio"] {
    float: left;
}

.layer .layer_hibbel_edit .line .hint {
    font-size: rem(12);
    margin-left: rem(5);
}

.layer .layer_hibbel_edit .line .radioLine {
    margin: rem(8) 0;
}

.layer .layer_hibbel_edit .line .radioLine label {
    display: block;
    padding-left: rem(28);
}

.layer .layer_hibbel_edit .line .checkboxLine label {
    margin-left: rem(2);
}

.layer .layer_hibbel_edit .actionbar {
    border-top: rem(1) solid $c-divider-default;
    padding: rem(12) rem(14) rem(10) 0;
}

.layer .layer_hibbel_edit .actionbar .submit {
    float: right;
}

.layer .layer_hibbel_edit textarea {
    height: rem(200);
}

.layer_flash_container {
    display: none;
}

.layer .layer_flash {
    padding: rem(50) rem(14) rem(15);
}

.layer .layer_flash .hl {
    margin-bottom: rem(11);
}

.layer .layer_video {
    max-width: rem(473);
}

.layer .layer_video .hl {
    margin-bottom: rem(11);
}

.layer .layer_video .video {
    margin-bottom: rem(11);
    width: 100%;

    @media (min-width: $break-medium) {
        height: rem(370);
    }
}

.layer .layer_link {
    max-width: rem(460);
}

.layer .layer_link .left {
    border-right: rem(1) solid $c-divider-default;
    float: left;
    height: rem(147);
    width: 50%;
}

.layer .layer_link .right {
    float: right;
    height: rem(147);
    width: 50%;
}

.layer .layer_link .link {
    background: $c-background-brand;
    display: block;
    height: rem(50);
    margin-top: rem(29);
    padding: rem(17) rem(30) 0;
    text-align: center;
    width: rem(148);
}

.layer .layer_ask {
    visibility: visible;
    max-width: rem(600);
}

.layer .layer_ask p {
    margin-bottom: rem(20);
}

.layer .layer_ask legend {
    display: none;
}

.layer .layer_ask .line {
    margin-bottom: rem(15);
}

.layer .layer_ask .actionbar {
    border-top: rem(1) solid $c-divider-default;
    padding-top: rem(10);
    margin-bottom: 0;
}

.layer .layer_ask .line p {
    margin-bottom: 0;
}

.layer .layer_ask .line > label {
	display: block;
    margin: 0 0.5em 0.25em 0;
}

.layer .layer_ask .left {
    float: left;
    width: auto;
}

.layer .layer_ask .left label {
    margin-right: 0.5em;
}

.layer .layer_ask .right {
    float: none;
    width: auto;
}

.layer .layer_ask .right label {
    font-weight: normal;
}

.layer .layer_ask select {
    max-width: rem(243);
}

.layer .layer_ask input[type=radio] {
    margin-left: rem(5);
}

.layer .layer_ask .smileys a {
    display: inline-block;
    margin: 0;
    padding: 0 rem(4) 0 rem(3);
}

.layer .layer_ask .actionbar .right {
    border-bottom: rem(1) solid $c-divider-default;
    border-top: rem(1) solid $c-divider-default;
    padding: rem(10) 0;
    position: relative;
}

.layer .layer_ask .actionbar a {
    float: left;
}

.layer .layer_ask .actionbar a.btnHtmlMode {
    margin-left: rem(10);
}

.layer .layer_ask .actionbar a.btnCancel {
    float: right;
    margin-right: rem(20);
}

.layer .layer_ask .actionbar #createbtn {
    float: right;
}

.layer .layer_ask .actionbar #blogPostPreviewFormBtn {
    float: left;
}

.layer .layer_ask #blogPostPreviewContent .post_content {
    margin: 0;
    padding: 0;
}

.layer .layer_ask .mod_most_discussed ul {
    border-bottom: rem(1) solid $c-divider-default;
}

.layer .layer_ask .mod_most_discussed li {
    border-top: rem(1) solid $c-divider-default;
    padding: rem(7) rem(10) rem(7) rem(10);
}

.layer .layer_inactivity_teaser {
    max-width: rem(600);
}

.layer .layer_inactivity_teaser .fb_like {
    height: rem(21);
    position: absolute;
    right: rem(50);
    top: rem(9);
    max-width: rem(150);
}

.layer .layer_inactivity_teaser .fb_like_txt {
    font-size: rem(11);
    margin: 0 0 rem(2) 0;
}

.layer .layer_inactivity_teaser .fb_like_content {
    width: 100%;
}

.layer .layer_inactivity_teaser .info {
    margin-bottom: rem(10);
}

.layer .layer_inactivity_teaser .img {
    display: block;
    margin-bottom: rem(12);
}

.layer .layer_inactivity_teaser .txt {
    margin-bottom: rem(10);
}

.layer .layer_inactivity_teaser .back {
    float: right;
}

.layer .layer_mobile_info {
    padding: rem(15);
    width: auto;
}

.layer .layer_mobile_info .logo {
    display: block;
    float: left;
    margin-right: rem(20);
    width: rem(100);
}

.layer .layer_mobile_info .headline {
    margin-top: rem(24);
}

.layer .layer_mobile_info .info {
    margin-bottom: rem(20);
}

.layer .layer_mobile_info .mediumButton {
    cursor: pointer;
    height: rem(28);
    margin: rem(15) 0;
    position: static;
    width: 100%;
}
