.nm-topnav {
    display: none;
}

.nm-nav {
    background-color: $c-background-brand;
    clear: both;
    display: none;
    height: 100vh;
    left: 0;
    top: $d-mobile-header-height;
    list-style: none;
    margin: 0;
    overflow-y: auto;
    padding: 0 $d-content-padding $d-mobile-header-height;
    position: fixed;
    width: 100%;
    z-index: 1;

    @media (min-width: $break-content) {
        background-color: transparent;
        border-bottom: rem(1) solid $c-divider-dark;
        border-top: rem(1) solid $c-divider-dark;
        display: block;
        left: auto;
        height: rem(48);
        padding: 0;
        position: static;
        top: auto;
        width: auto;
    }

    // Level one

    &__level-one {
        margin-bottom: rem(20);

        @media (min-width: $break-content) {
            margin-bottom: 0;
        }
    }

    &__level-one > li {
        position: relative;

        @media (min-width: $break-content) {
            position: static;
            float: left;
        }
    }

    &__level-one > li > a {
        color: $c-text-white;
        display: block;
        font-size: rem(16);
        font-weight: bold;
        line-height: rem(26);
        padding: rem(10) rem(45) rem(10) 0;

        @media (min-width: $break-content) {
            color: $c-text-default;
            font-weight: normal;
            font-size: rem(11);
            letter-spacing: rem(1.1);
            padding: rem(10) 0 rem(10) rem(10);
            text-transform: uppercase;
        }

        &:hover {
            text-decoration: none;
        }

        &:after {
            @include icon-styles();
            content: icon-char("arrow-right");
            float: right;
            font-size: rem(16);
            line-height: rem(26);

            @media (min-width: $break-content) {
                display: none;
            }
        }
    }

    &__level-one > li:first-child > a {
        padding-left: 0;
    }

    &__level-one > li > .toggle {
        @media (min-width: $break-content) {
            display: none;
        }
    }

    &__level-one > .open-desktop > ul {
        @media (min-width: $break-content) {
            left: 0;
            right: 0;
            width: auto;
        }
    }

    &__level-one > .open-desktop > a {
        position: relative;
    }

    &__level-one > .open-desktop > a:after {
        @media (min-width: $break-content) {
            display: block;
            content: "";
            position: absolute;
            left: 50%;
            bottom: 0;
            border-left: rem(12) solid transparent;
            border-right: rem(12) solid transparent;
            border-bottom: rem(12) solid $c-background-brand;
            margin-left: rem(-12);
            z-index: $z-header-menu-desktop;
        }
    }

    // Level two

    &__level-two {
        background: $c-background-brand;
        display: none;
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;

        @media (min-width: $break-content) {
            // for menu-teasers
            min-height: rem(345);
            padding: rem(55) 0 rem(10);
            position: absolute;
            z-index: $z-header-menu-desktop;
        }

        &:before {
            @media (min-width: $break-content) {
                content: "Unsere Themen";
                color: $c-text-white;
                font-size: rem(11);
                position: absolute;
                top: rem(25);
                left: rem(40);
                text-transform: uppercase;
            }
        }
    }

    &__level-two > li {
        position: relative;

        @media (min-width: $break-content) {
            padding-left: rem(40);
            width: 30%;
        }
    }

    &__level-two > li > a {
        color: $c-text-white;
        display: block;
        padding: rem(10) rem(45) rem(10) 0;
        font-weight: normal;
        font-size: rem(14);
        line-height: rem(26);
        text-decoration: none;

        @media (min-width: $break-content) {
            padding: rem(2) 0 rem(2) 0;
        }
    }

    // Level three

    &__level-three {
        display: none;

        @media (min-width: $break-content) {
            position: relative;
        }
    }

    &__level-three > li {
        font-style: rem(16);
    }

    &__level-three > li > a {
        color: $c-text-white;
        display: block;
        font-size: rem(14);
        line-height: rem(26);
        padding: rem(10) 0;

        @media (min-width: $break-content) {
            padding: rem(2) 0 rem(2) rem(36);
        }
    }

    .open-desktop > ul {
        @media (min-width: $break-content) {
            display: block;
        }
    }

    .open-mobile > ul {
        @media (max-width: $break-content) {
            padding-left: rem(30);
            display: block;
        }
    }

    .toggle {
        border-left: rem(1) solid $c-divider-white;
        cursor: pointer;
        height: rem(40);
        padding-left: rem(15);
        position: absolute;
        right: 0;
        top: rem(3);
        width: rem(44);

        @media (min-width: $break-content) {
            border: none;
            height: rem(30);
            left: 0;
        }

        &:before {
            @include icon-styles();
            content: icon-char("plus");
            color: $c-text-white;
            font-size: rem(22);
            line-height: rem(39);

            @media (min-width: $break-content) {
                @include icon-styles();
                content: icon-char("angle-right");
                font-size: rem(13);
                line-height: rem(24);
            }
        }
    }

    .toggle--open:before {
        content: icon-char("minus");

        @media (min-width: $break-content) {
            content: icon-char("angle-down");
            font-size: rem(8);
        }
    }

    .nm-nav__open-search {
        display: none;
        float: right;
        padding: rem(10) 0 0;

        @media (min-width: $break-content) {
            display: block;
        }

        &:after {
            @include icon-styles();
            content: icon-char("search");
            cursor: pointer;
            line-height: rem(26);
        }
    }

    .nm-nav__social {
        border: 0;
        margin-bottom: rem(24);
        text-align: center;

        @media (min-width: $break-content) {
            display: none;
        }
    }

    .nm-nav__home-item {
        @media (min-width: $break-content) {
            text-indent: rem(-999);
            position: relative;
            width: rem(15);
        }

        &:before {
            @media (min-width: $break-content) {
                @include icon-styles();
                content: icon-char("home");
                position: absolute;
                left: 0;
                text-indent: 0;
            }
        }
    }

    .nm-nav__social a {
        display: inline-block;
        margin: 0 rem(5);
        padding: 0;
    }
}
