.mod_shortcuts {
    @include stretchToMobileViewport();
    background: $c-background-grey;
    overflow: hidden;
    padding: rem(28) $d-box-padding;
}

.mod_shortcuts .ilist {
    display: none;
}

.mod_shortcuts .active .ilist {
    display: block;
}

.mod_shortcuts .hl {
    display: block;
    text-decoration: none;
    padding-top: rem(20);
}

.mod_shortcuts .list {
    margin-bottom: rem(1);
}

.aside .mod_shortcuts .ilist li,
.mod_shortcuts .ilist li {
    border-top: none;
}

.mod_shortcuts .ilist li a.ad {
    padding-right: rem(50);
    position: relative;

    &:before {
        position: absolute;
        right: 0;
    }
}
