.mod_user_galleries form {
    background: none;
    margin: 0 0 rem(30) 0;
}

.mod_user_galleries .line {
    margin-bottom: rem(10);
    vertical-align: middle;
}

.mod_user_galleries .line .btnNewGallery {
    @media (min-width: $break-medium) {
        float: right;
    }
}

.mod_user_galleries .actionbar {
    margin-bottom: rem(10);
    padding: rem(10) 0;
}

.mod_user_galleries .actionbar .submit {
    float: right;
}

.mod_user_galleries .paging {
    border-bottom: rem(1) solid $c-divider-default;
    border-top: rem(1) solid $c-divider-default;
    margin: rem(20) 0;
    padding: rem(10) 0;
}

.mod_user_galleries .galleries {
    margin: 0;
}

.mod_user_galleries .galleries .h2 {
    border-bottom: rem(1) solid $c-divider-default;
    margin: 0 0 rem(10) 0;
    padding: 0 rem(90) rem(3) 0;
    position: relative;
}

.mod_user_galleries .galleries .edit {
    appearance: none;
    background: none;
    border: none;
    color: $c-text-brand;
    font-size: rem(12);
    font-weight: bold;
    bottom: rem(5);
    padding: 0;
    position: absolute;
    right: 0;
    text-indent: 0;

    &:after {
        @include icon-styles();
        content: icon-char("cog");
        padding-left: rem(6);
    }
}

.mod_user_galleries .galleries .meta {
    margin-bottom: rem(10);
}

.mod_user_galleries .galleries .meta td {
    padding-right: rem(10);

    &:last-child {
        font-weight: bold;
    }
}

.mod_user_galleries .galleries h3 {
    margin: 0 0 rem(10) 0;
}

.mod_user_galleries .galleries .description {
    margin: rem(10) 0 rem(15) 0;
}

.mod_user_galleries .galleries .descriptionFormRow {
    background: $c-background-grey;
    margin: 0 0 rem(15) 0;
    padding: rem(10);
}

.mod_user_galleries .galleries .images {
    margin: 0;
}

.mod_user_galleries .galleries .images .image {
    float: left;
    margin: 0 rem(10) rem(10) 0;
    text-align: center;
    width: rem(55);
}

.mod_user_galleries .galleries .images .image .img {
    border: rem(1) solid $c-divider-default;
    height: rem(53);
    width: rem(53);
}

.mod_user_galleries .galleries .images .image .img img {
    max-height: 100%;
    max-width: 100%;
}

.mod_user_galleries .galleries .images .image input {
    margin-top: rem(5);
}

.mod_user_galleries .galleries .images .line {
    border-bottom: rem(1) solid $c-divider-default;
    border-top: rem(1) solid $c-divider-default;
    margin: 0;
}

.mod_user_galleries .galleries .images .line + .line {
    border-bottom: rem(1) solid $c-divider-default;
    border-top: none;
}

.mod_user_galleries .galleries .family .h2 {
    margin-bottom: 0;
}

.mod_user_galleries .galleries .family.editMode .images {
    margin-left: 0;
    margin-right: 0;
}

.mod_user_galleries .galleries .family .images .line {
    margin: 0 0 rem(10) 0;
    overflow-x: auto;
    padding: rem(10) 0;
}

.mod_user_galleries .galleries .family .image {
    float: none;
    margin: 0;
    width: auto;
}

.mod_user_galleries .galleries .family .colImage {
    text-align: left;
    padding: 0 rem(5) 0 0;
    vertical-align: top;
    width: rem(65);
}

.mod_user_galleries .galleries .family .colLabel1 {
    text-align: left;
    padding: 0 rem(5) 0 rem(5);
    vertical-align: top;
    width: rem(75);
}

.mod_user_galleries .galleries .family .colValue1 {
    text-align: left;
    padding: 0 rem(5) 0 rem(5);
    vertical-align: top;
    width: rem(120);
}

.mod_user_galleries .galleries .family .colSep {
    display: none;
    text-align: center;
    padding: 0;
    vertical-align: top;
    width: rem(5);
}

.mod_user_galleries .galleries .family .colLabel2 {
    text-align: left;
    padding: 0 rem(5) 0 rem(5);
    vertical-align: top;
    width: rem(95);
}

.mod_user_galleries .galleries .family .colValue2 {
    text-align: left;
    padding: 0 rem(5) 0 rem(5);
    vertical-align: top;
    width: rem(60);
}

.mod_user_galleries .galleries .family .colImage .img {
    border: rem(1) solid $c-divider-default;
    height: rem(53);
    text-align: center;
    width: rem(53);
}

.mod_user_galleries .galleries .family .colImage .img img {
    max-height: 100%;
    max-width: 100%;
}

.mod_user_galleries .galleries .family.editMode .colImage {
    padding: 0;
    min-width: rem(96);

    @media (min-width: $break-medium) {
        min-width: rem(85);
    }
}

.mod_user_galleries .galleries .family.editMode .colImage input {
    float: left;
    margin: rem(12) rem(10) 0 0;

    @media (min-width: $break-medium) {
        margin: rem(20) rem(10) 0 0;
    }
}

.mod_user_galleries .galleries .family.editMode .colImage .img {
    float: left;
}

.mod_user_galleries .galleries .family .colSep .sep {
    background: #9a9a9a;
    height: rem(55);
    width: rem(1);
}

.mod_user_galleries .galleries .family .actionbar {
    padding: rem(10) 0;
}

.mod_user_galleries .galleries .btnDelGallery,
.mod_user_galleries .galleries .btnAddImage {
    @media (min-width: $break-medium) {
        float: right;
    }
}

.mod_user_galleries .galleries .btnDelImages {
    margin-bottom: rem(10);
}

.mod_user_galleries .selectCount {
    display: block;
    margin-bottom: rem(10);

    @media (min-width: $break-medium) {
        margin-bottom: rem(10);
    }
}

.mod_user_galleries .galleries hr {
    margin-left: rem(10);
    margin-right: rem(10);
}
