.mod_reply {
    margin-top: rem(20);
}

.mod_reply .hl {
    border-bottom: rem(1) solid $c-divider-default;
    margin: 0 0 rem(10) 0;
    padding-bottom: rem(3);
    width: 100%;
}

.mod_reply p {
    margin-bottom: rem(20);
}

.mod_reply form {
    background: transparent;
}

.mod_reply legend {
    display: none;
}

.mod_reply .line {
    margin-bottom: rem(15);
}

.mod_reply .line p {
    margin-bottom: 0;
}

.mod_reply .line .left {
    float: none;
    width: 100%;
}

.mod_reply .line .right {
    float: none;
    width: 100%;
}

.mod_reply .line select {
    margin-top: rem(5);
}

.mod_reply .line input[type=radio] {
    margin-left: rem(5);
}

.mod_reply .smileys a {
    display: inline-block;
    margin: 0;
    padding: 0 rem(4) 0 rem(3);
}

.mod_reply .actionbar .right {
    @include clearfix;
    border-bottom: rem(1) solid $c-divider-default;
    border-top: rem(1) solid $c-divider-default;
    padding: rem(10) 0;
    position: relative;
}

.mod_reply .actionbar a {
    float: left;
}

.mod_reply .actionbar a.btnHtmlMode {
    margin-left: rem(10);
}

.mod_reply .actionbar a.btnCancel {
    float: right;
    margin-right: rem(20);
}

.mod_reply .actionbar #createbtn {
    float: right;
}

.mod_reply .actionbar #blogPostPreviewFormBtn {
    float: left;
}

.mod_reply #blogPostPreviewContent {
    padding: 0;
}

.mod_reply #blogPostPreviewContent .post_content {
    margin: 0;
    padding: 0;
}
