.mod_threads .navbar {
    background: transparent;
    border-bottom: rem(1) solid $c-divider-default;
    margin-bottom: rem(28);
    padding-bottom: rem(28);
}

.mod_threads .navbar .buttons {
    margin: 0;
}

.mod_threads .navbar .btnBack,
.mod_threads .navbar .btnNew {
    float: left;
    margin-bottom: rem(10);
    margin-right: rem(10);

    @media (min-width: $break-medium) {
        margin-bottom: 0;
    }
}

.mod_threads .navbar .btnFirst,
.mod_threads .navbar .btnLast,
.mod_threads .navbar .btnPrev,
.mod_threads .navbar .btnNext {
    float: right;
    margin-left: rem(5);
}

.mod_threads .hl {
    border-bottom: rem(1) solid $c-divider-default;
    border-top: rem(1) solid $c-divider-default;
    display: flex;
    font-size: rem(14);
    margin: rem(15) 0;
    padding: 0;
}

.mod_threads .hl div {
    padding: rem(7) 0;
}

.mod_threads .ft {
    border-bottom: rem(1) solid $c-divider-default;
    height: rem(1);
    margin: 0 0 rem(11) 0;
}

.mod_threads .line {
    border-top: rem(1) solid $c-divider-default;
    display: flex;
    margin: 0 0 rem(15) 0;
    padding: rem(15) 0 0 0;
}

.mod_threads .line.first {
    border-top: none;
    padding-top: 0;
}

.mod_threads .title a {
    color: $c-text-default;
    font-weight: bold;

    &:visited {
        color: $c-text-visited;
    }
}

.mod_threads .owner .user {
    font-weight: bold;
}

.mod_threads .thread {
    overflow: hidden;
    flex-grow: 1;
}

.mod_threads .thread .title {
    margin: 0 0 rem(4) 0;
}

.mod_threads .thread .title a {
    color: $c-text-default;
    font-weight: bold;
    font-size: rem(16);

    &:visited {
        color: $c-text-visited;
    }
}

.mod_threads .thread .meta {
    margin: 0 0 rem(4) 0;
}

.mod_threads .thread .bd .more {
    background-image: none;
    padding: 0;
}

.mod_threads .answers {
    margin: 0;
    overflow: hidden;
    text-align: center;
    min-width: rem(70);
}

.mod_threads .newest {
    overflow: hidden;
    text-align: right;
    min-width: rem(145);
}

.mod_threads .newest .date {
    margin: 0 0 rem(4) 0;
}

.mod_threads .newest .poster {
    margin: 0 0 rem(4) 0;
}

.mod_threads .newest .more {
    padding: 0;

    &:after {
        @include icon-styles();
        content: icon-char("arrow-right");
        margin-left: rem(8);
    }
}

.mod_threads .answers_overview {
    border-color: #424242;
    border-style: solid $c-divider-default;
    border-width: rem(1) 0 0 0;
    padding: rem(12) 0;
}

.mod_threads .answers_overview ul {
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
    margin-left: 0;
}

.mod_threads .answers_overview ul ul {
    margin-left: 0;
}

.mod_threads .answers_overview li {
    background: transparent url(../img/tree-list-icon.gif) no-repeat scroll 0 0;
    padding-top: rem(3);
    padding-left: rem(5);
}

.mod_threads .answers_overview li li {
    background: transparent url(../img/tree-list-subicon.gif) no-repeat scroll 0 0;
    padding-left: rem(18);
}

.mod_threads .answers_overview li .inner {
    margin-left: rem(10);
}

.mod_threads .archiv {
    text-align: left;
}
