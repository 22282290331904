.mod_social li {
    float: left;
    height: rem(20);
    margin: rem(14) rem(15) rem(1) 0;
    width: rem(20);

    .mod_social li img {
        border: rem(1) solid $c-divider-default;
        display: block;
    }
}
