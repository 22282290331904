.mod_related {
    .media {
        border-bottom: rem(1) solid $c-divider-default;
        padding: rem(28) 0;
    }

    .first {
        padding-top: rem(12);
    }

    .img {
        @media (min-width: $break-medium) {
            margin-right: rem(30);
        }
    }

    .kicker {
        display: block;
        font-weight: normal;
    }

    .hl {
        margin-bottom: rem(8);
    }

    .sep {
        display: none;
    }

    .txt {
        margin-bottom: 0;
    }

    .ilist li a {
        display: block;
        font-weight: bold;
        font-size: rem(16);
        padding-right: rem(30);
        position: relative;

        &:after {
            @include icon-styles();
            content: icon-char("arrow-right");
            color: $c-text-brand;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .ilist li .ad {
        // Arrow and ad label
        padding-right: rem(80);

        &:before {
            position: absolute;
            right: rem(24);
        }
    }
}

.article div.mod_ad {
    clear: both;
    margin-top: rem(30);
    margin-bottom: rem(30);
}

// mod_related in main column
.content_main .mod_related {
    .img {
        @media (min-width: $break-medium) {
            width: rem(140);
        }
    }

    .teaserImageOverlay {
        @media (min-width: $break-medium) {
            height: rem(36);
            width: rem(36);
        }
    }

    .teaserImageOverlay_block {
        @media (min-width: $break-medium) {
            display: none;
        }

        &:first-of-type {
            @media (min-width: $break-medium) {
                display: block;
            }
        }
    }

    .mod_related .inner {
        background: $c-background-grey;
        padding: rem(16) $d-box-padding;
    }
}

// mod_related in sidebar
.aside .mod_related {
    .media {
        border: none;
        padding-top: 0;

        &:last-child {
            padding-bottom: 0;
        }
    }

    .ilist li {
        border-bottom: rem(1) solid $c-divider-default;
    }
}
