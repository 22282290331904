.mod_rate .list li {
    border-bottom: rem(1) solid $c-divider-default;
    padding: rem(23) 0 rem(11);
}

.mod_rate .report {
    float: right;
}

.mod_rate .kicker {
    margin-bottom: rem(10);
}

.mod_rate .txt {
    margin-bottom: rem(24);
}

.mod_rate legend {
    float: left;
    font-weight: normal;
}

.mod_rate .line {
    float: right;
}

.mod_rate .line input[type=radio] {
    margin-left: rem(15);
}

.mod_rate .rating {
    margin-bottom: 0;
}
