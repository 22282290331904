.kicker {
    color: $c-text-grey;
    font-size: rem(12);

    a {
        color: $c-text-grey;
        font-size: rem(12);
    }

    &.ad + * .ad:before,
    &.ad + .ad:before {
        display: none;
    }
}
