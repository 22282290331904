.mod_ranking .list li {
    padding: rem(4) rem(10);
}

.mod_ranking .uicon {
    float: left;
}

.mod_ranking .info {
    float: left;
    padding: rem(7) 0 0 rem(10);
    width: rem(210);
}

.mod_ranking .points {
    float: left;
    padding: rem(7) 0;
    text-align: right;
    width: rem(48);
}
