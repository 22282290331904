.mod_nav .hl {
    border-bottom: none;
    margin-top: rem(10);
}

.mod_nav .left {
    float: left;
    width: 50%;
}

.mod_nav .right {
    float: right;
    width: 50%;
}

.mod_nav .hide {
    display: none;
}

.mod_nav .bd {
    border: none;
}

.mod_nav .bd .ilist li {
    border-bottom: rem(1) solid $c-divider-default;
    border-top: none;
    padding: rem(10) 0;
    position: relative;
}

.mod_nav .bd .ilist li a {
    display: block;
    font-size: rem(16);
    font-weight: bold;
    line-height: rem(20);
    position: relative;
    padding-right: rem(30);

    &:after {
        @include icon-styles();
        content: icon-char("arrow-right");
        color: $c-text-brand;
        position: absolute;
        right: 0;
        top: 0;
    }
}

.mod_nav .bd .ilist li a.ad {

}

.mod_nav .ilist.toggle li {
    padding-left: rem(24);
}

.mod_nav .ilist.toggle li a:before {
    padding-left: rem(24);
}

.mod_nav .ilist.toggle li .ad:before {
    right: rem(24);
}

.mod_nav .bd .ilist li li {
    border: none;
    padding: rem(2) 0 rem(2) rem(1);

    a {
        font-size: rem(12);
        font-weight: normal;

        &:after {
            content: none;
        }
    }

    &:first-child {
        padding-top: rem(7);
    }
}

.mod_nav .bd .ilist li.sub:before {
    @include icon-styles();
    content: icon-char("angle-down");
    color: $c-text-brand;
    cursor: pointer;
    font-size: rem(10);
    left: 0;
    position: absolute;
    top: rem(10);
}

.mod_nav .bd .ilist li.open:before {
    content: icon-char("angle-up");
}

.mod_nav .bd .ilist li li.sub {
    padding: 0 rem(10) 0 rem(24);

    &:before {
        top: 0;
    }
}

.mod_nav .bd_more {
    margin-bottom: 0;
}

.mod_nav .bd .toggle .toggle {
    display: none;
}

.mod_nav .bd .toggle li.initopen > .toggle {
    display: block;
}
