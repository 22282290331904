.paging {
    margin-bottom: rem(11);
    position: relative;
    text-align: center;

    a {
        color: $c-text-default;
        margin: 0 rem(2);
    }

    .first:before {
        @include icon-styles();
        content: icon-char("first");
        color: $c-text-brand;
        left: 0;
        position: absolute;
    }

    .last:before {
        @include icon-styles();
        content: icon-char("last");
        color: $c-text-brand;
        right: 0;
        position: absolute;
    }

    .prev:before {
        @include icon-styles();
        content: icon-char("arrow-left");
        color: $c-text-brand;
        left: rem(32);
        position: absolute;
    }

    .next:before {
        @include icon-styles();
        content: icon-char("arrow-right");
        color: $c-text-brand;
        right: rem(32);
        position: absolute;
    }

    .btns a.active {
        color: $c-text-brand;
        font-size: rem(20);
        font-weight: bold;
        margin: 0 rem(3);
    }
}
