.mod_search_result .inner {
    position: relative;
}

.mod_search_result .icon {
    height: rem(14);
    position: absolute;
    right: 0;
    top: 0;
    width: rem(18);
}

.mod_search_result .list {
    margin: 0 0 rem(10) 0;
}

.mod_search_result .list li {
    border-bottom: rem(1) solid $c-divider-default;
    padding: rem(11) 0 rem(15);
}

.mod_search_result .list li.first {
    border-top: rem(1) solid $c-divider-default;
}

.mod_search_result .info {
    display: block;
    float: right;
}

.mod_search_result .type {
    margin-right: rem(10);
}

.mod_search_result .hl {
    margin-bottom: rem(12);
}

.mod_search_result .inner .ad {
    display: block;
    padding-right: rem(50);
    position: relative;

    &:before {
        position: absolute;
        right: 0;
    }
}

.mod_search_result .img,
.mod_search_result .mod_eyecatcher .img {
    float: left;
    margin-right: rem(16);
    width: rem(102);
}

.mod_search_result .original {
    display: none;
}

.mod_search_result .paging_group {
    display: none;
}

// medicine search
.mod_search_result .medicine .mainInformation p.substance,
.mod_search_result .medicine .mainInformation p.manufacturer {
    float: left;
    width: 64%;
}

.mod_search_result .medicine .mainInformation span.prescription {
    float: right;
    text-align: right;
    width: 35%;
}

.mod_search_result .medicine .mainInformation a.more {
    float: right;
}

.mod_search_result .medicine .mainInformation a.more span.wait {
    background: transparent url(fileuploader/images/loading.gif) scroll no-repeat center center;
    display: inline-block;
    height: rem(16);
    width: rem(20);
}

.mod_search_result .list li .ilist li {
    border-bottom: none;
    padding-bottom: 0;
    padding-left: rem(14);
    padding-top: 0;
}

.mod_search_result .medicine .moreInformation {
    clear: both;
    display: none;
}

.mod_search_result .medicine .moreInformation h4 {
    margin: rem(12) 0 rem(3) 0;
}

.mod_search_result .medicine .moreInformation p {
    margin: 0 0 rem(8) 0;
}
