.box-404 {
    background-color: $c-background-grey;
    padding: $d-box-padding;

    @media (min-width: $break-medium) {
        background-image: url("../img/bg_404.jpg");
        background-size: cover;
        padding-right: 50%;
    }
}
