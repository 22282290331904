.mod_triple {
    padding-bottom: rem(20);
    position: relative;

    @media (min-width: $break-medium) {
        padding: rem(54) 0 rem(42);
        @include fullWidthBackground();
    }

    &.no_headline {
        padding-top: rem(15);
    }

    .line {
        margin: 0 0 rem(24) 0;

        @media (min-width: $break-medium) {
            display: flex;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .moreBtn {
        text-align: left;
    }

    .hl {
        font-size: rem(16);
        margin-bottom: rem(12);
    }

    .hd {
        position: relative;
        z-index: 1;
    }

    .is-top-tool .hl {
        padding-right: rem(100);
        word-break: break-word;
    }

    .media {
        position: relative;
        z-index: 1;
        margin-bottom: rem(20);

        @media (min-width: $break-medium) {
            margin-bottom: 0;
            width: 33.333%;
        }
    }

    .media {
        @media (min-width: $break-medium) {
            margin-left: rem(10);
            margin-right: rem(10);
        }
    }

    .media.first {
        @media (min-width: $break-medium) {
            margin-left: 0;
            margin-right: rem(20);
        }
    }

    .media.last {
        @media (min-width: $break-medium) {
            margin-right: 0;
            margin-left: rem(20);
        }
    }

    .img {
        display: block;
        float: none;
        margin: 0 0 rem(10) 0;
        width: auto;
    }

    .media .bd {
        margin: 0 0 rem(5) 0;
    }

    .media.ad:before {
        position: absolute;
        top: rem(5);
        right: rem(10);
        z-index: 1;
    }

    .triple-footer {
        text-align: center;
    }

    .triple-image-wrapper {
        position: relative;
    }

    .toptool {
        background-color: $c-text-brand;
        border-radius: 50%;
        bottom: rem(-30);
        color: $c-text-white;
        height: rem(69);
        padding-top: rem(18);
        position: absolute;
        right: $d-content-padding;
        width: rem(69);

        @media (min-width: $break-medium) {
            right: rem(16);
        }
    }

    .toptool1 {
        display: block;
        font-weight: bold;
        font-size: rem(22);
        text-align: center;
    }

    .toptool2 {
        display: block;
        font-size: rem(16);
        text-align: center;
    }
}
